/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import Toggle from "@swvl/toggle";
import { Column } from "react-table";
import { startCase } from "lodash";
import { format } from "date-fns";
import { convertMinutesAmPmTime } from "utils";
import { ShiftModel } from "resources/useShifts";

type SetPopupData = ({ title, id, value }: { title: string; id: string; value: boolean }) => void;

export const columns: (setPopupData: SetPopupData) => Column<ShiftModel>[] = (setPopupData) => [
  {
    Header: "Name",
    accessor: "name",
    Cell: (row) => (
      <div>
        <span title={row.value}>{row.value ? row.value : "--"}</span>
      </div>
    ),
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
  },
  {
    Header: "End Date",
    accessor: "endDate",
    Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
  },
  {
    Header: "Office Location",
    accessor: "officeLocation",
    Cell: ({ value }) => {
      return value.name;
    },
  },
  {
    Header: "Time",
    accessor: "time",
    Cell: ({ value }) => {
      const { timeString } = convertMinutesAmPmTime(value);
      return timeString;
    },
  },
  {
    Header: "Shift Direction",
    accessor: "direction",
    Cell: ({ value }) => {
      return startCase(value);
    },
  },
  {
    Header: "Employees",
    accessor: "assignedEmployees",
  },
  {
    Header: "Status",
    accessor: "isActive",
    Cell: ({
      cell,
      value,
      row: {
        original: { id, officeLocation, time, completed },
      },
    }) => {
      const { timeString } = convertMinutesAmPmTime(time);
      const title = `${officeLocation.name} - ${timeString}`;
      if (completed) {
        return <p css={{ fontWeight: "bold" }}>Completed</p>;
      }
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Toggle
            id={`${cell.getCellProps().key}`}
            data-testid={cell.getCellProps().key}
            checked={value}
            isControlled={true}
            labelOn={"Active"}
            labelOff={"Inactive"}
            onChange={(e) => {
              setPopupData({
                id,
                value,
                title,
              });
            }}
            onOffLabelPosition={"start"}
          />
        </div>
      );
    },
  },
];

export default columns;
