/** @jsxRuntime classic */
/** @jsx jsx */
import { Flex, jsx } from "theme-ui";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/fp/isNil";
import template from "lodash/fp/template";
import Panel, { Trigger, Details } from "@swvl/details-panel";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import Spinner from "@swvl/spinner";
import NoResults from "components/NoListResults";
import { addToQuery, constants, getFromQuery, removeFromQuery } from "utils";
import RideCard from "./RideCard";
import RideDetails from "./RideDetails";
import { RideModel } from "resources/useRides";
import { FILTERS_HEIGHT, HEADER_HEIGHT, NAV_HEIGHT } from "constants/styles";

type RidesListProps = {
  rides: RideModel[];
  isRidesLoading: boolean;
  handleGetMoreRides: () => void;
  shouldShowLoadMoreButton: boolean;
  headingSchema: string;
};

const RidesList: FC<RidesListProps> = ({ ...props }) => {
  const {
    rides,
    isRidesLoading,
    handleGetMoreRides,
    shouldShowLoadMoreButton,
    headingSchema,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [selectedRideId, setSelectedRideId] = useState(undefined);

  useEffect(() => {
    // Get ride-id from params
    const queryRideId = getFromQuery("ride-id", location.search);
    // Set selectedRideId to the query ride-id
    setSelectedRideId(queryRideId);
  }, [location.search]);

  const handleSelectRide = useCallback((rideId: string) => {
    if (isNil(rideId)) removeFromQuery("ride-id", history);
    // Set query ride-id
    else addToQuery("ride-id", rideId, history);
  }, []);

  const closeDetails = useCallback(() => {
    removeFromQuery("ride-id", history);
  }, []);

  return (
    <Fragment>
      <div
        sx={{
          overflowY: "auto",
          height: `calc(100vh - ${NAV_HEIGHT + HEADER_HEIGHT + FILTERS_HEIGHT}px)`,
        }}
      >
        <Panel
          currentTriggerId={selectedRideId}
          setCurrentTriggerId={handleSelectRide}
          clickOutSideToClose={false}
        >
          {rides.map((ride) => {
            const headingReplacer = template(headingSchema);
            const heading = headingReplacer({
              startDate: format(new Date(ride.startDate), constants.dateFormat),
              startTime: format(new Date(ride.startDate), "p"),
              endTime: format(new Date(ride.predictedEndDate), "p"),
            });
            return (
              <Trigger key={ride.id} id={ride.id}>
                <RideCard {...ride} heading={heading} selected={ride.id === selectedRideId} />
              </Trigger>
            );
          })}
          <Details overrideStyles={{ left: 0, width: "100%" }}>
            <RideDetails id={selectedRideId} closeDetails={closeDetails} />
          </Details>
        </Panel>
        {isRidesLoading ? (
          <Spinner
            color="primary"
            css={{
              display: "block",
              margin: "16px auto",
            }}
          />
        ) : rides.length == 0 ? (
          <Flex sx={{ flexDirection: "column", height: "40vh", justifyContent: "center", mx: 2 }}>
            <NoResults
              title="No Rides"
              body="There are no existing rides that match your filters."
              hasBackground={false}
            />
          </Flex>
        ) : null}

        {shouldShowLoadMoreButton && (
          <Button
            variant="secondary"
            icon={<Icon name="updated" size={20} fill={"currentcolor"} />}
            iconPosition="left"
            onClick={handleGetMoreRides}
            sx={{ margin: "16px auto" }}
          >
            Load More
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default RidesList;
