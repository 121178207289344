/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
import theme from "@swvl/theme";

export const FIELDSET_HEIGHT = 350;

export const FormContainer = styled.div`
  margin: 0 20px;
`;

export const FieldSeparator = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 8px;
  display: block;
`;

export const DownArrow = styled.span`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${theme.colors.opacity["black-54"]};
  margin-right: 10px;
`;

export const FieldList = styled.ul`
  margin: 0;
  position: relative;
  padding: 0;
  list-style: none;
  width: 80%;
  max-width: 550px;
`;

export const FieldElement = styled.li`
  & + li {
    width: 100%;
    border-top: ${theme.borderStyling.thin};
  }
`;

export const FieldLegend = styled.legend`
  display: block;
  border-bottom: ${theme.borderStyling.thin};
  font-weight: bold;
  padding: 18px 0;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  input {
    margin-top: 0;
  }
`;

export const Fieldset = ({ children, fieldsetIndex, ...rest }) => {
  return (
    <fieldset
      sx={{
        padding: 0,
        border: "none",
        position: "relative",
        paddingLeft: "40px",
        marginTop: 2,
        "::before": {
          content: `'${fieldsetIndex}'`,
          position: "absolute",
          display: "flex",
          height: "calc(100% + 40px)",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "13px",
          left: 0,
          width: "20px",
          backgroundColor: fieldsetIndex % 2 === 0 ? "lavender" : "aliceblue",
          marginRight: "20px",
        },
      }}
      {...rest}
    >
      {children}
    </fieldset>
  );
};
