/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from "react";
import { jsx, Flex, Text } from "theme-ui";
import emptySlateBackground from "assets/error.svg";

type NoListResultsProps = {
  title?: string;
  body: string;
  hasBackground?: boolean;
};

const NoListResults: FC<NoListResultsProps> = ({ title, body, hasBackground = true, ...rest }) => (
  <Flex sx={{ flexDirection: "column", alignItems: "center" }} {...rest}>
    {hasBackground ? <img src={emptySlateBackground} css={{ width: "100%" }} /> : null}
    <Text variant="headline" sx={{ fontWeight: "bold" }}>
      {title}
    </Text>
    <Text variant="body-large" css={{ textAlign: "center" }}>
      {body}
    </Text>
  </Flex>
);

export default NoListResults;
