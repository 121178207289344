import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toCamelCase } from "utils";
import { showAlert } from "@swvl/alert";

type OfficeLocationModel = {
  id: string;
  isDefault: boolean;
  isActive: boolean;
  name: string;
  coords: {
    lng: number;
    lat: number;
  };
  updatedAt: string;
  createdAt: string;
};

export type OfficeLocationsModel = {
  count: number;
  officeLocations: OfficeLocationModel[];
};

interface OfficeLocation {
  _id: string;
  is_default: boolean;
  is_active: boolean;
  name: string;
  coords: {
    lng: number;
    lat: number;
  };
  corporate: string;
  updatedAt: string;
  createdAt: string;
}

type OfficeLocationsResponse = {
  type?: string;
  total: number;
  hits: OfficeLocation[];
};

const transformResponse = (response: OfficeLocationsResponse): OfficeLocationsModel => {
  const data = {
    count: response.total,
    officeLocations: response.hits.map((officeLocation) =>
      toCamelCase(officeLocation)
    ) as OfficeLocationModel[],
  };
  return data;
};

const fetchOfficeLocations = async ({
  limit,
}: {
  limit: number;
}): Promise<OfficeLocationsModel> => {
  const response = await apiInstance.get<AxiosResponse, OfficeLocationsResponse>(
    "office-locations",
    {
      params: {
        limit,
        is_active: true,
      },
    }
  );

  return transformResponse(response);
};

const useOfficeLocations = () => {
  const query = useQuery(["office-locations"], () => fetchOfficeLocations({ limit: 1000 }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (response) => {
      showAlert({
        message: (response as AxiosResponse)?.data?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return query;
};

export { useOfficeLocations, fetchOfficeLocations, transformResponse };
