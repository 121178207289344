/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import Button from "@swvl/button";
import { FC, useState } from "react";
import { EmployeeModel } from "resources/useEmployees";
import SelectedEmployeeTag from "./SelectedEmployeeTag";
import { pluralize } from "utils";

type SelectionBannerProps = {
  selectedList: Record<string, EmployeeModel>;
  total: number;
  onDeselectEmployee: (id: string) => void;
  onExpandSelectedEmployee?: () => void;
};

const MAX_DISPLAYED_SELECTIONS = 5;

const SelectionBanner: FC<SelectionBannerProps> = ({
  selectedList,
  total,
  onDeselectEmployee,
  onExpandSelectedEmployee,
}) => {
  const selectedCount = Object.keys(selectedList).length;
  const collapsedSelectionCount = selectedCount - MAX_DISPLAYED_SELECTIONS;
  return (
    <div
      sx={{
        backgroundColor: "ghostwhite",
        color: "black",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        padding: 16,
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        <Text sx={{ fontWeight: "bold" }} variant={"body-small"}>
          {`${pluralize(selectedCount, "employee")} ${
            total ? `out of ${total}` : ""
          } are selected.`}
        </Text>
      </Flex>
      <div sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        {Object.values(selectedList)
          .slice(0, MAX_DISPLAYED_SELECTIONS)
          .map((employee) => (
            <SelectedEmployeeTag
              key={employee.id}
              data={employee}
              onDeselect={onDeselectEmployee}
            />
          ))}
        {collapsedSelectionCount > 0 ? (
          <Button onClick={onExpandSelectedEmployee} variant="default">
            <Text variant={"body-small"} color="secondary">
              +{pluralize(collapsedSelectionCount, "employee")} selected
            </Text>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default SelectionBanner;
