/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useState, useMemo, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import isNil from "lodash/fp/isNil";

import Map, { CustomMapPanelControl, Marker } from "@swvl/map";
import { string, object, mixed, number, date } from "yup";
import { Button } from "@swvl/button";
import { Select } from "@swvl/select";
import Spinner from "@swvl/spinner";

import TimePicker from "@swvl/time-picker";

import DatePicker from "@swvl/date-picker";
import Icon from "@swvl/icon";
import LocationPicker from "@swvl/location-picker";
import Input from "@swvl/input";

import { constants, convertAmPmTimeToMinutes, getIsoDate } from "utils";
import Field from "components/FormField";

import MarkerGrey from "../../assets/marker-grey.svg";
import Header from "./CreateHeader";
import SearchEmployee from "components/SearchEmployee";
import PhoneField from "components/FormPhoneField";
import Page from "components/PageWrapper";
import {
  useCreatePrivateRide,
  useFetchVehicleTypes,
  CreatePrivateRideRequest,
} from "resources/useCreatePrivateRide";
import styled from "@emotion/styled";
import { GOOGLE_MAPS_API_KEY } from "constants/rideMonitoring";
import useAuthState from "utils/useAuthState";
import { format } from "date-fns";
import CreatePrivateRidePopup from "./CreatePrivateRidePopup";

export type FormData = {
  name: string;
  phone: string;
  phoneRegionCode?: string;
  employee_id: string;
  date: Date;
  time: number;
  bus_type?: {
    label: string;
    value: string;
  };
  pickup: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  dropoff: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  additional_details: string;
};
const formInitialData: FormData = {
  name: "",
  employee_id: undefined,
  phone: "",
  phoneRegionCode: "",
  date: undefined,
  time: 0,
  pickup: undefined,
  dropoff: undefined,
  bus_type: undefined,
  additional_details: "",
};
export type VehicleOption = { label: string; value: string };

export type PopupData = { data: CreatePrivateRideRequest; name: string };

const userSchema = object()
  .shape({
    name: string().required("Name is required"),
    employee_id: string().required(),
    date: date().required("You must pick date"),
    bus_type: object({ label: string().required(), value: string().required() }).required(),
    time: number().required("you must pick a time"),
    pickup: object({
      address: string().required(),
      coords: object({
        lat: number(),
        lng: number(),
      }).required(),
    }).required("You must pick a pickup location"),
    dropoff: object({
      address: string().required(),
      coords: object({
        lat: number(),
        lng: number(),
      }).required(),
    }).required("You must pick a dropoff location"),
  })
  .defined();

export const FormContainer = styled.div`
  width: 30%;
  margin: 20px;
`;

const CreatePrivateRide: FC = () => {
  const [date, setDate] = useState<Date>(undefined);
  const [popupData, setPopupData] = useState<PopupData>(null);
  const [isLocationPickupOpen, setLocationPickupOpen] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [isLocationDropoffOpen, setLocationDropoffOpen] = useState<boolean>(false);
  const { mutate: createPrivateRide } = useCreatePrivateRide();

  const { data: vehicleTypes } = useFetchVehicleTypes();
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState<VehicleOption[]>(undefined);
  const [selectedStationId, setSelectedStationId] = useState<string>(undefined);
  const [hoveredStationId, setHoveredStationId] = useState<string>(undefined);
  const [additionalDetails, setAdditionalDetails] = useState<string>("");

  const dateRef = useRef(null);

  useEffect(() => {
    if (vehicleTypes) {
      const vehicleTypesOptions: VehicleOption[] = vehicleTypes.map((vehicle) => {
        return { label: vehicle.name, value: vehicle.id };
      });

      setVehicleTypesOptions(vehicleTypesOptions);
    }
  }, [vehicleTypes]);
  const minDate = new Date();
  const {
    authData: { user },
  } = useAuthState();
  const datePlaceholder = useMemo(() => {
    if (!date) return "Date";
    const formatStartDate = format(date, constants.dateFormat);

    return `${formatStartDate}`;
  }, [date]);

  const formik = useFormik({
    initialValues: formInitialData,
    validationSchema: userSchema,
    isInitialValid: false,
    validateOnChange: true,
    onSubmit: (values) => {
      const submitValues = {
        depart_date: getIsoDate(values.date, values.time).isoDateTime,
        pickup: values.pickup.coords,
        dropoff: values.dropoff.coords,
        bus_type: values.bus_type.value,
        employee_id: values.employee_id,
        additional_details: additionalDetails,
      };
      if (!isNil(submitValues)) {
        setPopupData({
          data: submitValues,
          name: values?.name,
        });
      }
    },
  });

  const cityCenterPoint = useMemo(() => {
    const cityCoordinates = user?.city?.center?.loc?.coordinates;
    if (!cityCoordinates) return { lng: 0, lat: 0 };
    return {
      lng: cityCoordinates[0],
      lat: cityCoordinates[1],
    };
  }, [user]);

  return (
    <Page title="Private Ride | Create" trackName="PrivateRide.Create.Page" header={Header(true)}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <FormContainer style={{ width: "35%" }}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} key={"create"}>
              <div css={{ marginBottom: "20px" }}>
                <Text variant="body-small" sx={{ fontWeight: "bold", my: 1 }}>
                  Employee Name
                </Text>

                <SearchEmployee
                  data-test-id="employeeName"
                  privateRideParam={true}
                  onSelect={(data) => {
                    formik.setFieldValue("employee_id", data.user, true);
                    formik.setFieldValue("name", data.name, true);
                    formik.setFieldValue("phone", data.phone, true);
                    formik.setFieldValue("phoneRegionCode", data.phoneRegionCode), true;
                  }}
                />
              </div>
              {formik.values.employee_id && (
                <PhoneField
                  data-test-id="mobileNumber"
                  disabled={true}
                  defaultPhoneRegionCode={formik.values.phoneRegionCode}
                />
              )}
              <div css={{ marginBottom: "20px" }}>
                <Input
                  data-test-id="pickupStation"
                  name="pickup"
                  label="Pickup Location"
                  onClick={() => setLocationPickupOpen(true)}
                  placeholder={"Select Pickup"}
                  value={formik.values.pickup?.address}
                  startEnhancer={<Icon name="red_bullet" size={20} />}
                  readOnly
                />
              </div>
              <div css={{ marginBottom: "20px" }}>
                <Input
                  data-test-id="dropoffStation"
                  name="dropoff"
                  label="Drop-off Location"
                  onClick={() => setLocationDropoffOpen(true)}
                  placeholder={"Select Drop-off"}
                  value={formik.values.dropoff?.address}
                  startEnhancer={<Icon name="red_bullet" size={20} />}
                  readOnly
                />
              </div>
              <div css={{ marginBottom: "20px" }}>
                <Select
                  name="bus_type"
                  options={vehicleTypesOptions}
                  handleChange={(option) => {
                    formik.setFieldValue("bus_type", option, true);
                  }}
                  placeholder="Select Vehicle"
                  label="Vehicle Type"
                  handleBlur={formik.handleBlur}
                  value={{
                    label: formik.values?.bus_type?.label,
                    value: formik.values?.bus_type?.value,
                  }}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Text variant="body-small" sx={{ fontWeight: "bold", my: 1 }}>
                  Depart By
                </Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "30%",

                      marginRight: "10px",
                    }}
                  >
                    <DatePicker
                      sx={{}}
                      data-test-id="dateSelector"
                      inputVariant="bordered"
                      ref={dateRef}
                      required
                      minDate={minDate}
                      selected={formik.values?.date}
                      startDate={formik.values?.date}
                      endDate={formik.values?.date}
                      placeholder={datePlaceholder}
                      withIconAtEnd
                      onChange={(dates) => {
                        dateRef.current.setOpen(false);
                        formik.setFieldValue("date", dates[0], true);
                        setDate(dates[0]);
                      }}
                    />
                  </div>
                  <div style={{ width: "70%" }}>
                    <TimePicker
                      onInputChange={(value) => {
                        if (!isNil(value)) {
                          formik.setFieldValue("time", convertAmPmTimeToMinutes(value), true);
                        }
                      }}
                      data-test-id="timeSelector"
                      id="time"
                      placeholder="Time"
                      required
                      variant="bordered"
                      height="default"
                    />
                  </div>
                </div>
              </div>
              <div css={{ marginBottom: "20px" }}>
                <Text variant="body-small" sx={{ fontWeight: "bold", my: 1 }}>
                  Additional Details
                </Text>
                <textarea
                  name="additional_details"
                  data-test-id="additionalDetails"
                  rows={3}
                  cols={75}
                  placeholder={"Add additional details"}
                  maxLength={2500}
                  onChange={(e) => {
                    setAdditionalDetails(e.target.value);
                  }}
                  value={additionalDetails}
                  sx={{
                    fontFamily: "unset",
                    resize: "none",
                    width: "100%",
                    height: "120px",
                    padding: "12px 12px",
                    boxSizing: "border-box",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#f8f8f8",
                    fontSize: "15px",
                    "&:focus": {
                      outline: "none",
                      border: "1px solid black",
                    },
                  }}
                />
              </div>
              <Button
                variant="primary"
                type="submit"
                data-test-id="submitRide"
                icon={<Icon name="checked" size={20} fill={"white"} />}
                iconPosition="left"
                disabled={!formik.isValid}
              >
                Submit Ride Request
              </Button>
            </form>
          </FormikProvider>
        </FormContainer>

        <Map
          onMapInit={(map) => {
            setMap(map);
          }}
          routes={{}}
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          mapContainerStyle={{
            height: "86vh",
            width: "65%",
            boxShadow: "0px 16px 40px rgba(124, 134, 156, 0.24)",
          }}
          setSelectedStation={(id) => {
            // Toggle station open/close on click
            if (id !== selectedStationId) setSelectedStationId(id);
            else setSelectedStationId(undefined);
          }}
          setHoveredStation={setHoveredStationId}
          center={
            formik.values?.pickup && formik.values?.dropoff
              ? {
                  lng: formik.values?.pickup?.coords.lng,
                  lat: formik.values?.dropoff?.coords.lat,
                }
              : formik.values?.pickup
              ? {
                  lng: formik.values?.pickup?.coords.lng,
                  lat: formik.values?.pickup?.coords.lat,
                }
              : { lng: cityCenterPoint?.lng, lat: cityCenterPoint?.lat }
          }
        >
          <Marker
            position={{
              lng: formik.values.pickup?.coords ? formik.values?.pickup?.coords.lng : 0,
              lat: formik.values.pickup?.coords ? formik.values?.pickup?.coords.lat : 0,
            }}
          />

          <Marker
            icon={MarkerGrey}
            position={{
              lng: formik.values.dropoff?.coords ? formik.values?.dropoff?.coords.lng : 0,
              lat: formik.values.dropoff?.coords ? formik.values?.dropoff?.coords.lat : 0,
            }}
          />
        </Map>
      </div>

      <LocationPicker
        title="Pickup Address"
        isOpen={isLocationPickupOpen}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        center={{
          lat: formik.values.pickup?.coords?.lat || cityCenterPoint.lat,
          lng: formik.values.pickup?.coords?.lng || cityCenterPoint.lng,
        }}
        isEditable
        onConfirm={({ location, name }) => {
          formik.setFieldValue(
            "pickup",
            {
              address: name,
              coords: {
                lat: location.lat,
                lng: location.lng,
              },
            },
            true
          );
          setLocationPickupOpen(false);
        }}
        onClose={() => {
          setLocationPickupOpen(false);
        }}
        confirmText="Apply"
        closeText="Cancel Changes"
      />
      <LocationPicker
        title="Dropoff Address"
        isOpen={isLocationDropoffOpen}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        center={{
          lat: formik.values.dropoff?.coords?.lat || cityCenterPoint.lat,
          lng: formik.values.dropoff?.coords?.lng || cityCenterPoint.lng,
        }}
        isEditable
        onConfirm={({ location, name }) => {
          formik.setFieldValue("dropoff", {
            address: name,
            coords: {
              lat: location.lat,
              lng: location.lng,
            },
          });

          setLocationDropoffOpen(false);
        }}
        onClose={() => {
          setLocationDropoffOpen(false);
        }}
        confirmText="Apply"
        closeText="Cancel Changes"
      />
      <CreatePrivateRidePopup
        data={popupData}
        testId="confirmationDialogue"
        onApprove={() => {
          const { data } = popupData;
          if (data) {
            createPrivateRide(data);
          }
          setPopupData(null);
        }}
        onDismiss={() => setPopupData(null)}
      />
    </Page>
  );
};

export default CreatePrivateRide;
