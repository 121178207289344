/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState, useEffect, useRef, useMemo } from "react";
import Icon from "@swvl/icon";
import DatePicker from "@swvl/date-picker";
import Button from "@swvl/button";
import { Select } from "@swvl/select";
import { addDays } from "date-fns";
import isNil from "lodash/fp/isNil";
import { useShiftsTimes } from "resources/useShiftsTimes";
import { SHIFT_DIRECTION_OPTIONS, ACTIVE_STATUS_OPTIONS } from "constants/shifts";
import { FilterMap } from "utils/useFilters";
import { getIsoDate } from "utils";

type FiltersProp = {
  onFilterChange: (key: string, filter: { value: string; label?: string }) => void;
  initialFilters: FilterMap;
};
const Filters: FC<FiltersProp> = ({ onFilterChange, initialFilters }) => {
  const dateRef = useRef(null);
  const [appliedDates, setAppliedDates] = useState<[Date, Date]>([null, null]);
  const [dates, setDates] = useState<[Date, Date]>([null, null]);

  useEffect(() => {
    // Make sure to update the dates from initial filters
    const isInitialDateRange = !!initialFilters["date"];
    let initialStartDate = new Date(initialFilters["date"] as string);
    let initialEndDate = null;

    if (!isInitialDateRange) {
      initialStartDate = initialFilters["start_date"]
        ? new Date(initialFilters["start_date"] as string)
        : null;

      initialEndDate = initialFilters["end_date"]
        ? new Date(initialFilters["end_date"] as string)
        : null;
    }

    if (!dates[0] && initialStartDate) {
      setDates([initialStartDate, initialEndDate]);
    }
    if (!appliedDates[0] && initialStartDate) {
      setAppliedDates([initialStartDate, initialEndDate]);
    }
  }, [
    appliedDates,
    dates,
    initialFilters.date,
    initialFilters.start_date,
    initialFilters.end_date,
  ]);

  const { data: shiftsTimesData, isLoading: isShiftsTimesLoading } = useShiftsTimes();
  const shiftsTimes = shiftsTimesData?.shiftsTimes;
  const shiftsTimesOptions = [
    {
      label: "All",
      value: null,
    },
  ].concat(
    shiftsTimes?.map((shiftTime) => ({
      value: shiftTime.value,
      label: shiftTime.format,
    })) || []
  );

  const applyDateFilter = () => {
    dateRef.current.setOpen(false);
    setAppliedDates(dates);
    if (dates.every((date) => date !== null)) {
      onFilterChange("start_date", { value: getIsoDate(dates[0]).isoString });
      onFilterChange("end_date", { value: getIsoDate(dates[1]).isoString });
    } else {
      onFilterChange("date", { value: getIsoDate(dates[0]).isoString });
    }
  };

  const directionInitialFilter = useMemo(() => {
    const selectedOption = SHIFT_DIRECTION_OPTIONS.find(
      (option) => option.value === initialFilters["direction"]
    );
    if (!isNil(selectedOption?.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters]);

  const statusInitialFilter = useMemo(() => {
    const selectedOption = ACTIVE_STATUS_OPTIONS.find(
      (option) => option.value === initialFilters["status"]
    );
    if (!isNil(selectedOption?.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters]);

  const timeInitialFilter = useMemo(() => {
    const selectedOption = shiftsTimesOptions.find((option) => {
      return option.value === initialFilters["time"];
    });
    if (!isNil(selectedOption?.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters, shiftsTimesOptions]);

  return (
    <div
      sx={{
        height: "64px",
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "repeat(4, 1fr)",
        columnGap: "15px",
        px: 3,
        py: 1,
      }}
    >
      <DatePicker
        ref={dateRef}
        selected={dates[0]}
        onChange={(newDates) => {
          const [newStartDate, newEndDate] = newDates as [Date, Date];
          setDates([newStartDate, newEndDate]);
        }}
        startDate={dates[0]}
        endDate={dates[1]}
        placeholder={dates.every((date) => date === null) && "Date"}
        minDate={addDays(new Date(), 1)}
        inputHeight="small"
        withIconAtStart
        onClickOutside={() => {
          const [appliedStartDate, appliedEndDate] = appliedDates;
          setDates([appliedStartDate, appliedEndDate]);
        }}
      >
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={applyDateFilter} variant="primary">
            Apply
          </Button>
        </div>
      </DatePicker>
      <Select
        height="small"
        options={shiftsTimesOptions}
        label={""}
        value={timeInitialFilter}
        placeholder={"Time"}
        name="time"
        isLoading={isShiftsTimesLoading}
        handleChange={(option) => onFilterChange("time", option)}
        startComponent={
          <div
            sx={{
              mr: 2,
              width: "16px",
              height: "16px",
              p: "2px",
              borderRadius: "50%",
              border: "solid 1px rgba(0, 0, 0, 0.38)",
            }}
          >
            <Icon name="clock_filled" fill="rgba(0, 0, 0, 0.38)" />
          </div>
        }
      />

      <Select
        height="small"
        options={SHIFT_DIRECTION_OPTIONS}
        label={""}
        value={directionInitialFilter}
        placeholder={"Shift Direction"}
        name="shift_direction"
        handleChange={(option) => onFilterChange("direction", option)}
        startComponent={
          <div sx={{ mr: 2 }}>
            <Icon name="shift_direction" fill="rgba(0, 0, 0, 0.38)" />
          </div>
        }
      />

      <Select
        height="small"
        options={ACTIVE_STATUS_OPTIONS}
        label={""}
        value={statusInitialFilter}
        placeholder={"Shift status"}
        name="status"
        handleChange={(option) => onFilterChange("status", option)}
        startComponent={
          <div sx={{ mr: 2 }}>
            <Icon name="bus_alt" fill="rgba(0, 0, 0, 0.1)" />
          </div>
        }
      />
    </div>
  );
};

export default Filters;
