import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import PrivateRideList from "./PrivateRideList";
import CreatePrivateRide from "./CreatePrivateRide";
export default function PrivateRide(props: unknown) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route key="List" exact path={`${path}`} component={PrivateRideList} />
      <Route key="Create" exact path={`${path}/create`} component={CreatePrivateRide} />
      <Redirect to={path} />
    </Switch>
  );
}
