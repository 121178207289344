/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import { FC, memo, useState, useMemo, useEffect } from "react";
import { matchPath, useLocation, Link } from "react-router-dom";
import { EXPANDED_SIDEBAR_WIDTH, COLLAPSED_SIDEBAR_WIDTH, NAV_HEIGHT } from "constants/styles";
import LogoImage from "assets/swvl-logo.svg";

export type SidebarState = {
  activeTabKey: string;
};

export type SidebarTabProps = {
  id?: string;
  icon?: JSX.Element;
  link?: string;
  text: string;
  isActive?: boolean;
  isHidden?: boolean;
  onSelect?: () => void;
};

export type SidebarTabs = SidebarTabProps[];

type SidebarProps = {
  tabs: SidebarTabs;
  hiddenTabs: string[];
};

const StyledSidebarTab = (props) => (
  <li
    sx={{
      bg: "deepdarkslategray",
      color: "whitesmoke",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
      pl: "10px",
      height: 56,
      "&.active": {
        bg: "darkslategray",
      },
      span: {
        position: "absolute",
        left: 0,
        height: "inherit",
        width: "3px",
        bg: "primary",
        transition: "transform 0.2s",
        transform: "translate3d(-100%, 0, 0)",
        "&.active": {
          transitionDuration: "0.2s",
          fontWeight: 700,
          transitionDelay: "0.2s",
          transform: "translate3d(0, 0, 0)",
        },
      },
    }}
    {...props}
  />
);

const IconWrapper = (props) => (
  <div
    sx={{
      ml: 1,
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      borderRadius: "50%",
      justifyContent: "center",
    }}
    {...props}
  />
);

const SidebarTab: FC<SidebarTabProps> = (props) => {
  const { id, icon, text, link, isActive, onSelect, isHidden } = props;

  /** Match route */
  const location = useLocation();
  const match = useMemo(
    () =>
      matchPath(location.pathname, {
        path: link,
      }),
    [location.pathname, link]
  );

  /** Navigate to tab on route matched */
  useEffect(() => {
    if (match && match.path) onSelect();
  }, [match]);

  return (
    !isHidden && (
      <Link
        to={link}
        sx={{
          textDecoration: "none",
        }}
      >
        <StyledSidebarTab className={`${isActive ? "active" : ""}`} onClick={() => onSelect()}>
          <span className={`${isActive ? "active" : ""}`} />
          <Flex
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {icon && <IconWrapper isActive={isActive}>{icon}</IconWrapper>}
            <Text
              variant="body-large"
              sx={{
                flex: 1,
                ml: 2,
                fontWeight: isActive ? 700 : "normal",
                opacity: text ? 1 : 0,
                transition: "0.2s opacity ease-in-out 0.2s",
              }}
            >
              {text}
            </Text>
          </Flex>
        </StyledSidebarTab>
      </Link>
    )
  );
};

const Sidebar: FC<SidebarProps> = (props) => {
  const { tabs, hiddenTabs } = props;
  const [activeTabKey, selectTab] = useState<string>(null);
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <div css={{ gridArea: "sidebar" }}>
      <Flex
        sx={{
          pt: 24,
          flexDirection: "column",
          justifyContent: "space-between",
          position: "fixed",
          width: isExpanded ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH,
          height: `calc(100vh - ${NAV_HEIGHT}px)`,
          backgroundColor: "deepdarkslategray",
          zIndex: 9999,
          transition: "0.3s width ease-in-out",
        }}
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        <ul
          sx={{
            margin: 0,
            padding: 0,
            position: "relative",
            listStyle: "none",
          }}
        >
          {tabs.map((tab) => {
            const hiddenTabFound = hiddenTabs.includes(tab.id);

            return (
              <SidebarTab
                id={tab.id}
                key={tab.id}
                link={tab.link.startsWith("/") ? tab.link : `/${tab.link}`}
                isActive={activeTabKey === tab.id}
                isHidden={hiddenTabFound}
                onSelect={() => {
                  selectTab(tab.id);
                  setExpanded(false);
                }}
                text={isExpanded ? tab.text : null}
                icon={tab.icon}
              />
            );
          })}
        </ul>
        <Flex
          sx={{
            margin: "2rem 0",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Text
            variant="subhead"
            sx={{
              marginRight: "10px",
              marginBottom: 0,
              fontWeight: 700,
              color: "white",
              opacity: isExpanded ? 1 : 0,
              transition: isExpanded && "0.2s opacity ease-in 0.2s",
            }}
          >
            Powered by
          </Text>

          <img
            alt="Swvl Logo"
            width={isExpanded ? 70 : 50}
            src={LogoImage}
            sx={{
              borderRadius: "4px",
              transition: "0.3s width ease-in",
            }}
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default memo(Sidebar);
