/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type PopupData = { id: string; value: unknown; name?: string };

type UpdateEmployeeProps = {
  data: PopupData;
  onApprove: () => void;
  onDismiss: () => void;
};

const UpdateEmployeePopup: FC<UpdateEmployeeProps> = (props) => {
  const { data, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={!isNil(data)}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Update Employee"
      message={
        <Text>
          You will proceed to update <span sx={{ fontWeight: "bold" }}>{data?.name}</span>{" "}
          information
        </Text>
      }
      confirmButtonText="Update Employee"
    />
  );
};

export default UpdateEmployeePopup;
