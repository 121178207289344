/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import Icon from "@swvl/icon";
import { minsToHours, formatNumberToFixedFloat } from "utils";
import lineSvg from "assets/line.svg";

type RideMetricsProps = {
  analytics: {
    duration: number;
    distance: number;
  };
  estimatedAnalytics: {
    duration: number;
    distance: number;
  };
};

const RideMetricsCard: FC<RideMetricsProps> = ({ analytics, estimatedAnalytics }) => {
  const { duration, distance } = analytics;
  const { duration: estimatedDuration, distance: estimatedDistance } = estimatedAnalytics;

  if (!duration && !distance && !estimatedDuration && !estimatedDistance) {
    return null;
  }
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#FFFFFF",
        padding: "10px",
        boxShadow: "0px 16px 16px rgba(124, 134, 156, 0.24)",
        borderRadius: "4px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 32px",
          borderRight: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        {distance ? (
          <div sx={{ textAlign: "center" }}>
            <div sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "13px" }}>Actual KM</div>
            <div sx={{ marginTop: "6px", fontSize: "19px", fontWeight: "bold" }}>
              {formatNumberToFixedFloat(distance, 1)} KM
            </div>
          </div>
        ) : null}
        {estimatedDistance ? (
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "darkgray",
              marginTop: "6px",
              fontSize: "13px",
            }}
          >
            Estimated KM{" "}
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 2,
                color: distance && estimatedDistance >= distance ? "primary" : "mediumseagreen",
              }}
            >
              {distance && estimatedDistance < distance ? (
                <Icon name="thick_down_arrow" size={20} fill="currentcolor" />
              ) : null}
              {distance && estimatedDistance > distance ? (
                <span style={{ transform: "rotate(180deg)" }}>
                  <Icon name="thick_down_arrow" size={20} fill="currentcolor" />
                </span>
              ) : null}
              {formatNumberToFixedFloat(estimatedDistance, 1)} KM
            </div>
          </div>
        ) : null}
      </div>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 32px",
        }}
      >
        {duration ? (
          <div sx={{ textAlign: "center" }}>
            <div sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "13px" }}>Actual Ride Time</div>
            <div sx={{ marginTop: "6px", fontSize: "19px", fontWeight: "bold" }}>
              {minsToHours(duration)}
            </div>
          </div>
        ) : null}
        {estimatedDuration ? (
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "darkgray",
              marginTop: "6px",
              fontSize: "13px",
            }}
          >
            Estimated Time{" "}
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 2,
                color: duration && estimatedDuration >= duration ? "primary" : "mediumseagreen",
              }}
            >
              {duration && estimatedDuration < duration ? (
                <Icon name="thick_down_arrow" size={20} fill="currentcolor" />
              ) : null}
              {duration && estimatedDuration > duration ? (
                <span style={{ transform: "rotate(180deg)" }}>
                  <Icon name="thick_down_arrow" size={20} fill="currentcolor" />
                </span>
              ) : null}
              {minsToHours(estimatedDuration)}
            </div>
          </div>
        ) : null}
      </div>
      {/* Actual/Planned Legend */}
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 32px",
        }}
      >
        <div sx={{ textAlign: "center" }}>
          <div sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "13px" }}>Actual Route</div>
          <div sx={{ marginTop: "6px", fontSize: "25px", fontWeight: "bold" }}>----</div>
        </div>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "darkgray",
            fontSize: "13px",
          }}
        >
          <span>Planned Route</span>
          <img sx={{ ml: 2 }} src={lineSvg} />
        </div>
      </div>
    </div>
  );
};

export default RideMetricsCard;
