import { useInfiniteQuery, useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toCamelCase } from "utils";
import { isNil } from "lodash";
import { showAlert } from "@swvl/alert";

export type ShiftModel = {
  id: string;
  businessProfiles: string[];
  corporate: string;
  endDate: string;
  startDate: string;
  isActive: boolean;
  officeLocation: {
    id: string;
    name: string;
  };
  time: number;
  weekdays: number[];
  direction: "to_office" | "from_office";
  completed: boolean;
  assignedEmployees: number;
  name: string;
};

interface ShiftResponse {
  _id: string;
  business_profiles: string[];
  corporate: string;
  end_date: string;
  start_date: string;
  is_active: boolean;
  office_location: {
    id: string;
    name: string;
  };
  time: number;
  weekdays: number[];
  direction: "to_office" | "from_office";
  completed: boolean;
  assigned_employees: number;
  name: string;
}

export type EmployeeModel = {
  id: string;
  employeeId: string;
  name: string;
  email: string;
  gender: "male" | "female" | "other";
  phone: string;
  phoneRegionCode: string;
  pickup: {
    address: string;
    lat: number;
    lng: number;
  };
};

export type EmployeesListModel = {
  count: number;
  employees: EmployeeModel[];
};

interface EmployeeResponseModel {
  _id: string;
  employee_id: string;
  name: string;
  email: string;
  gender: "male" | "female" | "other";
  phone: string;
  phone_region_code: string;
  pickup: {
    address: string;
    lat: number;
    lng: number;
  };
}

interface EmployeeResponse {
  type?: string;
  total: number;
  hits: EmployeeResponseModel[];
}

export type PaginatedAssignedEmployees = {
  data: EmployeesListModel;
  nextPage: number;
};

const ASSIGNED_EMPLOYEES_PAGE_SIZE = 5;
const fetchShift = async ({ id }: { id: string }): Promise<ShiftModel> => {
  const response = await apiInstance.get<AxiosResponse, ShiftResponse>(`shifts/${id}`);
  return toCamelCase(response) as ShiftModel;
};

const fetchShiftEmployees = async ({
  id,
  page,
  limit = ASSIGNED_EMPLOYEES_PAGE_SIZE,
  search_query,
}: {
  id: string;
  page: number;
  limit?: number;
  search_query?: string;
}): Promise<PaginatedAssignedEmployees> => {
  const response = await apiInstance.get<AxiosResponse, EmployeeResponse>(
    `shifts/${id}/employees`,
    {
      params: {
        page,
        limit,
        search_query,
      },
    }
  );

  const transformedData = {
    count: response.total,
    employees: response.hits.map((item) => toCamelCase(item) as EmployeeModel),
  };

  return {
    data: transformedData,
    nextPage: response.total > page * ASSIGNED_EMPLOYEES_PAGE_SIZE ? page + 1 : undefined,
  };
};

const useShiftDetails = ({ id }: { id: string }) => {
  return useQuery(["shift/details", id], () => fetchShift({ id }), {
    enabled: !isNil(id),
    refetchOnWindowFocus: false,
  });
};

const useShiftEmployees = ({
  id,
  searchQuery,
  enabled,
}: {
  id: string;
  searchQuery?: string;
  enabled: boolean;
}) => {
  const query = useInfiniteQuery(
    ["shift/details", "employees", id, searchQuery],
    ({ pageParam = 1 }) => {
      return fetchShiftEmployees({ id, page: pageParam, search_query: searchQuery });
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      retry: 2,
      enabled,
      onError: (response) => {
        showAlert({
          message: (response as AxiosResponse)?.data?.message,
          type: "error",
          id: "alert-error",
        });
      },
    }
  );
  return {
    ...query,
    data: (query.data
      ? {
          count: query.data.pages[0].data.count,
          employees: query.data.pages.flatMap((list) => list && list.data.employees),
        }
      : {
          count: 0,
          employees: [],
        }) as EmployeesListModel,
  };
};

export { useShiftDetails, fetchShift, fetchShiftEmployees, useShiftEmployees };
