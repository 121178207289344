/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import { IconButton } from "@swvl/button";
import Icon from "@swvl/icon";
import Tag from "@swvl/tag";
import { EmployeeModel } from "resources/useEmployees";

export interface SelectedEmployeeTagProps {
  data: Partial<EmployeeModel>;
  onDeselect?: (id: string) => void;
}

const SelectedEmployeeTag: FC<SelectedEmployeeTagProps> = ({
  data: { id, name, active },
  onDeselect,
}) => {
  return (
    <Tag
      key={id}
      size="small"
      sx={{
        pr: 1,
        pl: 0,
        mr: 1,
        ml: 0,
        backgroundColor: "transparent",
        color: active ? "black" : "darkgray",
        display: "flex",
        textAlign: "start",
      }}
    >
      <Text
        variant={"body-small"}
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "120px",
          textOverflow: "ellipsis",
          cursor: "default",
        }}
        title={`${name} ${!active ? `(inactive)` : ""}`}
      >
        {name}
      </Text>
      {onDeselect && (
        <IconButton
          variant="default"
          size="small"
          onClick={() => onDeselect(id)}
          icon={
            <span
              sx={{
                float: "right",
                position: "relative",
                top: "1px",
                transform: "rotate(45deg)",
              }}
            >
              <Icon name="add" size={12} fill={"rgba(0,0,0,0.54)"} />
            </span>
          }
        />
      )}
    </Tag>
  );
};

export default SelectedEmployeeTag;
