import { useQuery, useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { AxiosResponse } from "axios";

export type BulkUploadRequest = FormData;
export interface BulkUploadTemplateResponse {
  fields: string[];
  mandatory_fields: string[];
}

export type BulkUploadTemplateColumns = {
  name: string;
  mandatory: boolean;
  example: string;
}[];

export type BulkUploadModel = {
  columns: BulkUploadTemplateColumns;
};

export const transformResponse = ({
  fields,
  mandatory_fields: mandatoryFields,
}: BulkUploadTemplateResponse): BulkUploadTemplateColumns => {
  const examples = {
    phone: "201111316070",
    pickup: "31.2556,45.65465645",
    dropoff: "31.2556,45.65465645",
    bus_type: "635fcc0f8dd988001dc5e92a",
    depart_date: "02-11-2022",
    depart_time: "10:00 PM",
    timezone: "+2, -3, …etc",
    additional_details: "Multiple stations request",
  };
  return fields.map((field) => ({
    name: `"${field}"`,
    mandatory: !!mandatoryFields?.find((mandatoryField) => field === mandatoryField) || false,
    example: examples[field] || "",
  }));
};

const bulkUploadPrivateRides = async (data: BulkUploadRequest): Promise<string> => {
  const response = await apiInstance.post<BulkUploadRequest, string>(
    "bulk_actions/?action=create_business_private_ride_request",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

const useBulkUploadPrivateRides = () => {
  const queryClient = useQueryClient();
  const errorMessage = "Please upload file with the correct .csv template.";
  const successMessage = "Please check your email for a successful update.";
  const mutation = useMutation(bulkUploadPrivateRides, {
    mutationKey: "privateRides/bulkUpload",
    onError: (error) => {
      showAlert({
        message: errorMessage ? errorMessage : error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries("PrivateRides");
      }, 3000);
      showAlert({
        message: successMessage,
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

const fetchbulkUploadPrivateRidesTemplate = async (): Promise<BulkUploadTemplateColumns> => {
  const response = await apiInstance.get<AxiosResponse, BulkUploadTemplateResponse>(
    "bulk_actions/template?action=create_business_private_ride_request"
  );

  return transformResponse(response);
};

const useBulkUploadPrivateRidesTemplate = () => {
  return useQuery("privateRides/bulkupload/template", () => fetchbulkUploadPrivateRidesTemplate(), {
    refetchOnWindowFocus: false,
  });
};

export { useBulkUploadPrivateRidesTemplate, useBulkUploadPrivateRides };
