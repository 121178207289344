/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, memo, useEffect } from "react";
import forEach from "lodash/fp/forEach";
import { useFormik, FormikProvider } from "formik";
import { string, ref, object, InferType } from "yup";
import Spinner from "@swvl/spinner";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import swvlBusinessLogo from "assets/swvl-business-logo.svg";
import Field from "components/FormField";
import { useResetPassword } from "resources/useResetPassword";
import { Form, ErrorBanner } from "./styled";

type ResetPasswordFormProps = {
  token: string;
};

const resetPasswordSchema = object()
  .shape({
    password: string()
      .required("New password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&])[A-Za-z\d.@$!%*#?&]{8,}$/,
        `Passwords must be at least 8 characters and contain at least one uppercase letter,
        one lowercase letter, one number and one symbol from [ @.$!%*#?& ]`
      )
      .defined(),
    confirmedPassword: string()
      .oneOf([ref("password"), null], "Both passwords do not match, please re-enter.")
      .required("Password confirmation is required"),
  })
  .defined();
type ResetPasswordSchema = InferType<typeof resetPasswordSchema>;

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ token }) => {
  const { isError, status, validationErrorMessage, mutate: resetPassword } = useResetPassword();

  const isLoading = status === "loading";
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmedPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      const password = values.password.trim();
      const confirmedPassword = values.confirmedPassword.trim();
      resetPassword({
        token,
        password,
        confirm: confirmedPassword,
      });
    },
  });

  useEffect(() => {
    if (isError) {
      forEach<string>((key) => {
        formik.setFieldError(key, " ");
      })(Object.keys(formik.values));
    }
  }, [isError]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <img src={swvlBusinessLogo} />
        <div>
          <Text
            variant="title-large"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Reset Your Password
          </Text>
          <Text
            variant="body-small"
            sx={{
              color: "opacity.black-54",
            }}
          >
            Please enter and confirm your new password to access you account.
          </Text>
        </div>
        {isError ? (
          <ErrorBanner>
            <div>
              <Icon name="error" size={24} />
            </div>
            <Text
              variant="body-small"
              sx={{ color: "crimson", marginLeft: "1rem", lineHeight: "15px" }}
            >
              {validationErrorMessage}
            </Text>
          </ErrorBanner>
        ) : null}
        <div id="fields">
          <Field
            label="New Password"
            id="password"
            name="password"
            placeholder="Add Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            required
          />
          <Field
            label="Confirm New Password"
            id="confirmation-password"
            name="confirmedPassword"
            placeholder="Confirm Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmedPassword}
            required
          />
        </div>
        {isLoading ? (
          <Spinner color="primary" />
        ) : (
          <Button
            sx={{
              width: "150px",
              height: "38px",
            }}
            variant="primary"
            icon={<Icon name="right_arrow" size={15} fill="currentcolor" />}
            iconPosition="right"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
          >
            <span sx={{ fontWeight: "bold", fontSize: "15px" }}>Reset Password</span>
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
};

export default memo(ResetPasswordForm);
