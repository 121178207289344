import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toAllCamelCase } from "utils";
import { isNil } from "lodash";

export type Gender = "male" | "female" | "other";

export type EmployeeProfileResponse = {
  _id: string;
  name: string;
  email: string;
  corporate: string;
  employee_id: string;
  gender: Gender;
  pickup: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt: Date;
    updated_by: {
      _id: string;
      name: string;
      corpRole?: string;
    };
  };
  active: boolean;
  createdAt: string;
  deleted: boolean;
  phone: string;
  phone_region_code: string;
  is_app_registered: boolean;
  user: string;
  updatedAt: string;
};

export type EmployeeProfile = {
  id: string;
  name: string;
  phone: string;
  phoneRegionCode?: string;
  email?: string;
  active?: boolean;
  employeeId?: string;
  gender?: Gender;
  pickup: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt: Date;
    updatedBy: {
      id: string;
      name: string;
      corpRole?: string;
    };
  };
  isAppRegistered: boolean;
  user: string;
};

const transformResponse = (response: EmployeeProfileResponse): EmployeeProfile => {
  return toAllCamelCase(response);
};

const fetchEmployeeProfile = async ({ id }: { id: string }): Promise<EmployeeProfile> => {
  const response = await apiInstance.get<AxiosResponse, EmployeeProfileResponse>(`profiles/${id}`);

  return transformResponse(response);
};

const useEmployeeProfile = ({ id }: { id: string }) => {
  return useQuery(["employee/profile", id], () => fetchEmployeeProfile({ id }), {
    enabled: !isNil(id),
    refetchOnWindowFocus: false,
  });
};
export { useEmployeeProfile, fetchEmployeeProfile, transformResponse };
