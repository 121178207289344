/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui";
import { NameAvatar } from "components/Avatar";
import { format } from "date-fns";
import { Column } from "react-table";

import { IconButton } from "@swvl/button";
import Icon from "@swvl/icon";
import { PrivateRidesModel } from "resources/usePrivateRides";
import Tag, { VARIANT } from "@swvl/tag";

import { SyntheticEvent } from "react";
interface ColumnArgs {
  isDisabled: boolean;
  handleApproveRequest: (e: SyntheticEvent, id: string, employeeName: string) => void;
  handleRejectRequest: (e: SyntheticEvent, id: string, employeeName: string) => void;
}

export const STATUS_TAG_COLOR = {
  Approved: VARIANT.mediumseagreen,
  Rejected: VARIANT.crimson,
  ["Pending For Approval"]: VARIANT.slategray,
};

export const STATUS_TAG_NAME = {
  booked: "Booked",
  checked_in: "Checked-In",
  missed: "Missed",
  completed: "Completed",
  cancelled: "Cancelled",
  transferred: "Transferred",
};

export const columns = ({
  isDisabled,
  handleApproveRequest,
  handleRejectRequest,
}: ColumnArgs): Column<PrivateRidesModel>[] => [
  {
    Header: "Employee Name",
    accessor: "userName",
    Cell: ({ cell }) => {
      const name = cell.value;
      return (
        <NameAvatar name={name} nameIndex={parseInt(cell.row.id)}>
          <Text>{name}</Text>
        </NameAvatar>
      );
    },
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Pickup Location",
    accessor: "pickupAddress",
  },
  {
    Header: "Drop-off Location",
    accessor: "dropoffAddress",
  },
  {
    Header: "Corporate Status",
    accessor: "corporateStatus",
    Cell: ({ cell }) => {
      const value = cell.value;
      return (
        <Tag size="small" variant={STATUS_TAG_COLOR[value]}>
          <span sx={{ fontWeight: "bold" }}>{value}</span>
        </Tag>
      );
    },
  },
  {
    Header: "Operator Status",
    accessor: "operatorStatus",
    Cell: ({ cell }) => {
      const value = cell.value;
      return (
        <Tag size="small" variant={STATUS_TAG_COLOR[value]}>
          <span sx={{ fontWeight: "bold" }}>{value}</span>
        </Tag>
      );
    },
  },
  {
    Header: "Actions",
    Cell: ({ cell, value, row }) => (
      <Flex>
        <IconButton
          data-test-id="accept-request"
          icon={<Icon name="checked" fill="currentcolor" size={15} />}
          variant={"ghost"}
          onClick={(e) => {
            handleApproveRequest(e, row.original.id, row.original.userName);
          }}
          disabled={
            row.original.corporateStatus === "Approved" ||
            row.original.operatorStatus === "Rejected" ||
            row.original.operatorStatus === "Approved"
          }
        />
        <IconButton
          sx={{ marginLeft: 3 }}
          data-test-id="reject-request"
          icon={<Icon name="cancel" fill="currentcolor" size={15} />}
          variant={"ghost"}
          onClick={(e) => handleRejectRequest(e, row.original.id, row.original.userName)}
          disabled={
            row.original.corporateStatus === "Rejected" ||
            row.original.operatorStatus === "Approved" ||
            row.original.operatorStatus === "Rejected"
          }
        />
      </Flex>
    ),
  },
];

export default columns;
