/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import coverPhotoFallback from "assets/profile-cover.png";
import { Avatar } from "./Avatar";
import { NAV_HEIGHT } from "constants/styles";

type ProfileLayoutProps = {
  coverPhoto?: string;
  name: string;
  hasAvatar?: boolean;
};

const ProfileLayout: FC<ProfileLayoutProps> = ({
  name,
  coverPhoto = coverPhotoFallback,
  hasAvatar = true,
  children,
}) => {
  return (
    <div
      sx={{
        pt: 180,
        zIndex: 1,
        height: "100%",
        overflowY: "auto",
      }}
    >
      <div
        css={{
          position: "fixed",
          top: NAV_HEIGHT,
          left: 0,
          width: "100%",
          zIndex: 2000,
          paddingBottom: hasAvatar ? 46 : 0,
          backgroundColor: "whitesmoke",
        }}
      >
        <div
          sx={{
            height: "120px",
            zIndex: 1,
            backgroundImage: `url(${coverPhoto})`,
            backgroundSize: "cover",
          }}
        >
          {hasAvatar ? (
            <div
              sx={{
                position: "absolute",
                width: 96,
                height: 96,
                bottom: 0,
                left: 24,
                fontSize: 7,
              }}
            >
              <Avatar name={name} />
            </div>
          ) : null}
        </div>
      </div>
      <div sx={{ px: "24px", display: "grid", rowGap: 16 }}>{children}</div>
    </div>
  );
};

export default ProfileLayout;
