/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type PopupData = { id: string; value: unknown; name?: string };

type UnassignFromShiftPopupProps = {
  data: PopupData;
  onApprove: () => void;
  onDismiss: () => void;
};

const UnassignFromShiftPopup: FC<UnassignFromShiftPopupProps> = (props) => {
  const { data, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={!isNil(data)}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Unassign Employee"
      message={
        <div data-testid="unassign-from-shift">
          <Text variant="body-small" sx={{ color: "opacity.black-54" }}>
            You will proceed to unassign
            <Text variant="body-small" sx={{ fontWeight: "bold", color: "opacity.black-54" }}>
              {data?.name}
            </Text>
            from this shift.
          </Text>
        </div>
      }
      confirmButtonText="Unassign Employee"
    />
  );
};

export default UnassignFromShiftPopup;
