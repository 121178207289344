/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Divider } from "theme-ui";
import { FC, Fragment, useEffect, useCallback, useState, memo } from "react";
import { useQueryClient } from "react-query";
import startCase from "lodash/fp/startCase";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import isNil from "lodash/fp/isNil";
import Drawer from "@swvl/drawer";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import { Button, IconButton } from "@swvl/button";
import { WeekdaysList } from "@swvl/weekdays";
import Toggle from "@swvl/toggle";
import Input from "@swvl/input";
import { showAlert } from "@swvl/alert";
import { Avatar } from "components/Avatar";
import ProfileLayout from "components/ProfileLayout";
import { SEARCH_DEBOUNCE_TIME } from "constants/shifts";
import {
  removeFromQuery,
  convertMinutesAmPmTime,
  getWeekDaysList,
  getWeekDaysSelected,
} from "utils";
import { useShiftDetails, useShiftEmployees } from "resources/useShiftDetails";
import { useDeleteShift } from "resources/useDeleteShift";
import { useEditShiftActivationStatus } from "resources/useEditShiftActivationStatus";
import { useUnassignEmployeesFromShift } from "resources/useUnassignEmployeesFromShift";
import { FilterMap } from "utils/useFilters";
import { useDebounce } from "utils/useDebounce";
import DeleteShiftPopup from "./DeleteShiftPopup";
import ActivationPopup from "./ActivateShiftPopup";
import UnassignFromShiftPopup from "./UnassignFromShiftPopup";

type DetailsProp = {
  id: string;
  currentShiftsPage?: number;
  currentFilters?: FilterMap;
  closeDrawer: () => void;
};

export type PopupData = { id: string; value: unknown; name?: string };

const Details: FC<DetailsProp> = ({ id, currentFilters, currentShiftsPage, closeDrawer }) => {
  const [changeShiftStatusPopupData, setChangeShiftStatusPopupData] = useState<PopupData>(null);
  const [unassignEmployeePopupData, setUnassignEmployeePopupData] = useState<PopupData>(null);
  const [searchText, setSearchText] = useState("");
  const [isDeleteShiftPopupVisible, setDeleteShiftPopupVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const { mutate: editShiftStatus } = useEditShiftActivationStatus({
    page: currentShiftsPage,
    filters: currentFilters,
  });

  const {
    mutateAsync: unassignFromShift,
    isLoading: isUnassigning,
  } = useUnassignEmployeesFromShift();

  const { mutateAsync: deleteShift, isLoading: isDeleteShiftLoading } = useDeleteShift();
  const { data: shift, status: shiftStatus, isFetching: isFetchingShift } = useShiftDetails({
    id,
  });

  // For syncing employees total count. this query is disabled but called when needed
  const {
    data: { count: totalAssignedEmployees },
    refetch: syncTotalAssignedEmployees,
  } = useShiftEmployees({ id, enabled: false });

  // For listing and search employees. it's enabled by default if there is a defined id
  const {
    data: { employees, count: employeesCount },
    status: employeesStatus,
    isFetching: isFetchingEmployees,
    hasNextPage,
    fetchNextPage: handlegetMoreEmployees,
  } = useShiftEmployees({ id, searchQuery, enabled: !isNil(id) });

  const {
    officeLocation,
    isActive,
    startDate = null,
    time,
    weekdays = [],
    direction,
    endDate = null,
    name,
  } = shift || {};

  const isEmployeesLoading = employeesStatus === "loading";
  const shouldShowLoadMoreButton = !isFetchingEmployees && hasNextPage;
  const handleDebouncedChange = () => {
    const text = searchText.length >= 3 ? searchText : undefined;
    if (text) {
      setSearchQuery(text);
    } else {
      setSearchQuery(null);
    }
  };

  useDebounce(handleDebouncedChange, SEARCH_DEBOUNCE_TIME, [searchText]);

  const queryClient = useQueryClient();

  const { timeString } = convertMinutesAmPmTime(time);
  const shiftTitle = `${officeLocation?.name} - ${timeString}`;

  const updateShiftStatus = useCallback(() => {
    const { id, value } = changeShiftStatusPopupData;
    editShiftStatus({ id, isActive: !value });
    setChangeShiftStatusPopupData(null);
  }, [changeShiftStatusPopupData, editShiftStatus]);

  const handleDismissChangeShiftStatus = () => {
    setChangeShiftStatusPopupData(null);
  };

  const handleDismissUnassignEmployee = () => {
    setUnassignEmployeePopupData(null);
  };

  const handleChangeShiftStatus = () => {
    setChangeShiftStatusPopupData({
      id,
      value: isActive,
      name: shiftTitle,
    });
  };

  const handleUnassignEmployee = (employeeName: string, employeeId: string) => {
    setUnassignEmployeePopupData({
      id,
      value: employeeId,
      name: employeeName,
    });
  };

  const unassignEmployee = useCallback(async () => {
    const { id, value } = unassignEmployeePopupData;
    await unassignFromShift({
      shift_ids: [id],
      business_profile_ids: [value as string],
    });
    setUnassignEmployeePopupData(null);
    queryClient.invalidateQueries(["shift/details", "employees", id]);
    queryClient.invalidateQueries(["shifts", currentShiftsPage, currentFilters]);
    syncTotalAssignedEmployees();
  }, [unassignEmployeePopupData]);

  const openRemoveShift = (e) => {
    e.stopPropagation();
    setDeleteShiftPopupVisible(true);
  };

  const handleRemoveShift = useCallback(async () => {
    try {
      await deleteShift({ id });
      queryClient.invalidateQueries(["shifts", currentShiftsPage, currentFilters]);
      setDeleteShiftPopupVisible(false);
      closeDrawer();

      showAlert({
        message: "You have deleted the shift successfully",
        type: "success",
        id: "alert-success",
      });
    } catch (error) {
      const message = error["data"]?.message || "Something went wrong, shift can't be deleted";
      showAlert({
        message,
        type: "error",
        id: "alert-error",
      });
    }
  }, [id]);

  useEffect(() => {
    syncTotalAssignedEmployees();
  }, []);

  return isFetchingShift ? (
    <div sx={{ position: "relative" }}>
      <Spinner
        color="primary"
        sx={{
          width: 50,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  ) : !isNil(shift) ? (
    <ProfileLayout name={shiftTitle} key={id} hasAvatar={false}>
      <ActivationPopup
        data={changeShiftStatusPopupData}
        onApprove={updateShiftStatus}
        onDismiss={handleDismissChangeShiftStatus}
      />
      <UnassignFromShiftPopup
        data={unassignEmployeePopupData}
        onApprove={unassignEmployee}
        onDismiss={handleDismissUnassignEmployee}
      />
      {isDeleteShiftPopupVisible ? (
        <DeleteShiftPopup
          onApprove={() => handleRemoveShift()}
          id={id}
          title={shiftTitle}
          onDismiss={() => setDeleteShiftPopupVisible(false)}
          isOpen={isDeleteShiftPopupVisible}
        />
      ) : null}
      <div
        sx={{
          position: "sticky",
          paddingTop: 20,
          background: "whitesmoke",
          zIndex: 100,
          mx: "-24px",
          top: 0,
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            background: "whitesmoke",
          }}
        >
          <div sx={{ display: "flex", flexDirection: "column", ml: "24px" }}>
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "17px",
                lineHeight: "20px",
              }}
            >
              {shiftTitle}
            </Text>
            <Toggle
              labelOn={"Active"}
              labelOff={"Inactive"}
              checked={isActive}
              id="toggle-active"
              onChange={handleChangeShiftStatus}
              onOffLabelPosition={"start"}
            />
          </div>
          <div sx={{ display: "flex", alignItems: "flex-start" }}>
            {isFetchingShift || isUnassigning || isDeleteShiftLoading ? (
              <Spinner
                color="lightcoral"
                sx={{
                  width: 25,
                  mx: 2,
                }}
              />
            ) : null}
            <Button
              sx={{ mx: 2 }}
              onClick={openRemoveShift}
              icon={<Icon name="delete" size={20} fill="currentcolor" />}
            >
              Remove shift
            </Button>
          </div>
        </div>
      </div>
      <div
        sx={{
          marginTop: 24,
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <span>
          <Icon name="info" size={20} fill="opacity.black-54" />
        </span>
        <span sx={{ marginX: "8px", fontWeight: "bold", whiteSpace: "nowrap" }}>Shift Name:</span>
        {name ? name : "--"}
      </div>
      <div
        sx={{
          marginTop: "5px",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Icon name="scheduling" size={20} fill="opacity.black-54" />
        <span sx={{ marginX: "8px", fontWeight: "bold" }}>Start Date:</span>
        {format(new Date(startDate), "dd/MM/yyyy")}
      </div>
      <div
        sx={{
          marginTop: "5px",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Icon name="scheduling" size={20} fill="opacity.black-54" />
        <span sx={{ marginX: "8px", fontWeight: "bold" }}>End Date:</span>
        {format(new Date(endDate), "dd/MM/yyyy")}
      </div>
      <div
        sx={{
          marginTop: "5px",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Icon name="clock_filled" size={20} fill="opacity.black-54" />
        <span sx={{ marginX: "8px", fontWeight: "bold" }}>Time:</span>
        {timeString}
      </div>
      <div
        sx={{
          marginTop: "5px",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Icon name="scheduling" size={20} fill="opacity.black-54" />{" "}
        <span sx={{ marginX: "8px", fontWeight: "bold" }}>Weekdays:</span>
        <WeekdaysList
          selectedDays={getWeekDaysSelected(weekdays)}
          days={getWeekDaysList()}
          variant="gray"
        />
      </div>
      <div
        sx={{
          marginTop: "5px",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Icon name="shift_direction" size={20} fill="opacity.black-54" />
        <span sx={{ marginX: "8px", fontWeight: "bold" }}>Shift direction:</span>
        {startCase(direction)}
      </div>
      <div
        sx={{
          marginTop: "5px",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Icon name="office_address" size={20} fill="opacity.black-54" />
        <span sx={{ marginX: "8px", fontWeight: "bold" }}>Office Location:</span>
        {officeLocation?.name}
      </div>
      <Divider sx={{ color: "rgba(0,0,0, 0.08)", mx: "-24px" }} />

      {/* ===== employees ===== */}
      <Fragment>
        {totalAssignedEmployees ? (
          <div
            sx={{
              position: "sticky",
              height: "90px",
              top: "80px",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              mx: "-24px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
              pb: 3,
              mt: "-13px",
            }}
          >
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
                lineHeight: "17px",
                mb: 1,
                ml: "24px",
              }}
            >
              {totalAssignedEmployees} Assigned Employees
            </Text>
            {/* TODO: From design uncomment when needed */}
            {/* <div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ fontSize: "12px", marginRight: 1 }}
              onClick={transferAll}
              variant="purple"
              icon={<Icon name="assign_employees" size={15} fill="currentcolor" />}
            >
              Transfer All
            </Button>
            <Button
              sx={{ fontSize: "12px" }}
              onClick={unassignAll}
              icon={<Icon name="unassign" size={15} fill="currentcolor" />}
            >
              Unassign All
            </Button>
          </div> */}
            <div
              sx={{
                mt: 4,
                mb: 3,
                mx: "24px",
              }}
            >
              <Input
                css={{ fontSize: "14px" }}
                role="searchbox"
                startEnhancer={<Icon name="search" size={15} fill={"rgba(0, 0, 0, 0.5)"} />}
                endEnhancer={
                  searchText.length > 0 ? (
                    <IconButton
                      variant="default"
                      onClick={() => {
                        setSearchText("");
                      }}
                      css={{ transform: "rotate(45deg)" }}
                      title="close"
                      icon={<Icon name="add" size={15} fill={"rgba(0, 0, 0, 0.4)"} />}
                    />
                  ) : null
                }
                placeholder="Search employee using name, mobile number"
                variant="plain"
                height="small"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        ) : null}
        {isEmployeesLoading ? (
          <Spinner
            color="lightcoral"
            sx={{
              width: 25,
              mx: 2,
            }}
          />
        ) : null}
        {totalAssignedEmployees ? (
          <div>
            {employees.map((employee, index) => {
              return (
                <div key={employee.id}>
                  {index > 0 ? <Divider sx={{ color: "rgba(0,0,0, 0.08)", mx: "-24px" }} /> : null}
                  <div
                    sx={{
                      pt: 2,
                      pb: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div sx={{ display: "flex", alignItems: "center" }}>
                      <div
                        sx={{
                          width: 32,
                          height: 32,
                          fontSize: 1,
                        }}
                      >
                        <Avatar name={employee.name} />
                      </div>
                      <div sx={{ ml: 18 }}>
                        {employee.name ? (
                          <Text
                            sx={{
                              fontWeight: "bold",
                              fontSize: "13px",
                              maxWidth: "140px",
                              overflow: "hidden",
                            }}
                          >
                            {employee.name}
                          </Text>
                        ) : null}
                        {employee.phone ? (
                          <Text sx={{ fontSize: "11px", pt: 1 }}>{`+${employee.phone}`}</Text>
                        ) : null}
                      </div>
                    </div>

                    <div sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        sx={{ fontSize: "11px" }}
                        onClick={() => handleUnassignEmployee(employee.name, employee.id)}
                        variant="secondary"
                        icon={<Icon name="unassign" size={15} fill="currentcolor" />}
                      >
                        Unassign
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
            {!isEmployeesLoading && employeesCount === 0 ? (
              <Text sx={{ fontWeight: "bold", color: "opacity.black-54", textAlign: "center" }}>
                No results found that match your search
              </Text>
            ) : null}
            {shouldShowLoadMoreButton && (
              <Button
                variant="secondary"
                icon={<Icon name="updated" size={20} fill={"currentcolor"} />}
                iconPosition="left"
                onClick={() => handlegetMoreEmployees()}
                sx={{ margin: "16px auto" }}
              >
                Load More
              </Button>
            )}
          </div>
        ) : (
          <Text sx={{ fontWeight: "bold", color: "opacity.black-54", textAlign: "center" }}>
            No Employees Assigned To Shift
          </Text>
        )}
      </Fragment>
    </ProfileLayout>
  ) : (
    <div sx={{ position: "relative" }}>
      <Text
        variant="title-medium"
        sx={{
          position: "fixed",
          textAlign: "center",
          fontWeight: "bold",
          top: "50%",
          width: "100%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Sorry, We just can't get this shift, please try again.
      </Text>
    </div>
  );
};

type ProfileProps = Omit<DetailsProp, "closeDrawer">;

const Shift: FC<ProfileProps> = ({ id, currentShiftsPage, currentFilters }) => {
  const history = useHistory();
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    if (id) setDetailsOpen(true);
  }, [id]);

  const handlCloseDrawer = useCallback(() => {
    setDetailsOpen(false);
    removeFromQuery("shift-id", history);
  }, [history]);

  return (
    <Fragment>
      <Drawer
        isOpen={isDetailsOpen}
        size={"default"}
        anchor={"right"}
        clickOutSideToClose={true}
        closeDrawer={handlCloseDrawer}
      >
        <Details
          id={id}
          currentShiftsPage={currentShiftsPage}
          currentFilters={currentFilters}
          closeDrawer={handlCloseDrawer}
        />
      </Drawer>
    </Fragment>
  );
};

export default memo(Shift);
