import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

export type AllEmployeesCount = {
  count: number;
};
const fetchAllEmployeesCount = async (): Promise<number> => {
  const response = await apiInstance.get<AxiosResponse, AllEmployeesCount>("user/count");
  return response.count;
};

const useAllEmployeesCount = () => {
  const query = useQuery(["employees/count"], () => fetchAllEmployeesCount(), {
    keepPreviousData: true,
    onError: (response) => {
      showAlert({
        message: (response as AxiosResponse)?.data?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return query;
};

export { useAllEmployeesCount, fetchAllEmployeesCount };
