/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import isNil from "lodash/fp/isNil";
import startCase from "lodash/fp/startCase";
import { string, object, mixed, number } from "yup";
import { Button } from "@swvl/button";
import { Select } from "@swvl/select";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import LocationPicker from "@swvl/location-picker";
import Input from "@swvl/input";
import { getFromQuery } from "utils/";
import Field from "components/FormField";
import PhoneField from "components/FormPhoneField";
import Page from "components/PageWrapper";
import { useCreateEmployee } from "resources/useCreateEmployee";
import { useUpdateEmployee } from "resources/useUpdateEmployee";
import { useEmployeeProfile } from "resources/useEmployeeProfile";
import UpdateEmployeePopup from "pages/employees/UpdateEmployeePopup";
import styled from "@emotion/styled";
import Header from "./CreateUpdateHeader";
import { GOOGLE_MAPS_API_KEY } from "constants/rideMonitoring";
import Marker from "../../assets/marker.svg";
import MarkerGrey from "../../assets/marker-grey.svg";
import useAuthState from "utils/useAuthState";

export type Gender = "male" | "female" | "other";

export type FormData = {
  name: string;
  phone: string;
  phoneRegionCode?: string;
  email?: string;
  employeeId?: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  gender?: Gender;
};

export type PopupData = { id: string; value: FormData; name?: string };

type GenderOption = {
  label: string;
  value: Gender;
};

const genderOptions: GenderOption[] = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

const userSchema = object({
  name: string()
    .required("Name is required")
    .min(4, "Minimum length is 4 characters")
    .max(50, "Maximum length is 50 characters"),
  phone: string().required("Mobile number is required"),
  phoneRegionCode: string().required("Region code is required"),
  email: string().email("Please enter a valid email").nullable(),
  employeeId: string().nullable(),
  gender: mixed().oneOf(["male", "female", "other"]).nullable(),
  pickup: object().when("isAppRegistered", {
    is: (isAppRegistered) => isAppRegistered,
    then: object({
      address: string().required(),
      coords: object({
        lat: number(),
        lng: number(),
      }).required(),
    }).required("Pickup is required as employee completed his profile"),
    otherwise: object({
      address: string(),
      coords: object({
        lat: number(),
        lng: number(),
      }),
    }).nullable(),
  }),
}).defined();

const formInitialData: FormData = {
  name: "",
  email: undefined,
  employeeId: undefined,
  phone: "",
  phoneRegionCode: "",
  gender: undefined,
  pickup: undefined,
};

export const FormContainer = styled.div`
  width: 30%;
  margin: 20px;
`;

const EmployeesCreate: FC<{ isUpdate?: boolean }> = ({ isUpdate: isUpdateMode }) => {
  const history = useHistory();
  const location = useLocation();
  const [selectValue, setSelectValue] = useState(undefined);
  const [popupData, setPopupData] = useState<PopupData>(null);
  const [isLocationPickupOpen, setLocationPickupOpen] = useState<boolean>(false);
  const { mutate: createEmployee, status: createEmployeeStatus } = useCreateEmployee();
  const { mutate: updateEmployee } = useUpdateEmployee();
  const isCreateEmployeeLoading = createEmployeeStatus === "loading";
  const existingEmployeeId = useMemo(() => {
    return isUpdateMode ? getFromQuery("employee-id", location.search) : null;
  }, []);

  const { data: employee, status: profileStatus } = useEmployeeProfile({ id: existingEmployeeId });
  const isProfileLoading = profileStatus === "loading";
  const isProfileError = profileStatus === "error";

  const {
    authData: { user },
  } = useAuthState();

  const formik = useFormik({
    initialValues: formInitialData,
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (isUpdateMode && !isNil(employee)) {
        setPopupData({
          id: employee.id,
          value: {
            ...values,
            pickup: {
              address: values.pickup?.address,
              coords: values.pickup?.coords,
            },
          },
          name: values.name,
        });
      } else {
        createEmployee(values);
      }
    },
  });

  useEffect(() => {
    // On passing undefined employee-id or on error fetching employee, navigate to listing
    if ((isUpdateMode && !existingEmployeeId) || isProfileError) history.replace("/employees");
  }, [existingEmployeeId, isProfileError]);

  useMemo(() => {
    if (!isNil(employee) && isUpdateMode) {
      formik.setValues(employee, true);

      // Select gender update
      const gender = genderOptions.find((option) => option.value === employee.gender);
      if (!isNil(gender)) {
        setSelectValue({
          label: gender.label,
          value: employee.gender,
        });
      }
    }
  }, [employee]);

  const cityCenterPoint = useMemo(() => {
    const cityCoordinates = user?.city?.center?.loc?.coordinates;
    if (!cityCoordinates) return { lng: 0, lat: 0 };
    return {
      lng: cityCoordinates[0],
      lat: cityCoordinates[1],
    };
  }, [user]);

  return (
    <Page
      title="Employees | Create"
      trackName="Employees.Create/Update.Page"
      header={Header(isUpdateMode)}
    >
      {isProfileLoading ? (
        <div sx={{ position: "relative" }}>
          <Spinner
            color="primary"
            sx={{
              width: 50,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      ) : (
        <FormContainer>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} key={isUpdateMode ? employee?.id : "create"}>
              <Field
                label="Name"
                id="name"
                placeholder="Add Full Name"
                name="name"
                helpText="Name must be 4-50 characters"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                required
              />
              <PhoneField
                disabled={isUpdateMode}
                defaultPhoneRegionCode={formik.values.phoneRegionCode}
              />
              <Field
                id="email"
                name="email"
                label="Company Email"
                placeholder="abc@company.com"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />

              <Field
                id="employeeId"
                name="employeeId"
                label="Employee ID"
                placeholder="Add Employee ID"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.employeeId}
              />
              <div css={{ marginBottom: "20px" }}>
                <Input
                  name="pickup"
                  label="Pickup Address"
                  onClick={() => setLocationPickupOpen(true)}
                  required={isUpdateMode && employee?.isAppRegistered}
                  placeholder={"Add Pickup Address"}
                  value={formik.values.pickup?.address}
                  startEnhancer={<Icon name="red_bullet" size={20} />}
                  readOnly
                />
              </div>
              <div css={{ marginBottom: "20px" }}>
                <Select
                  name="gender"
                  options={genderOptions}
                  handleChange={(option: GenderOption) => {
                    setSelectValue(option);
                    formik.setFieldValue("gender", option.value);
                    formik.setFieldTouched("gender", true);
                  }}
                  placeholder="Add Gender"
                  label="Gender"
                  handleBlur={formik.handleBlur}
                  value={selectValue}
                  error={formik.errors.gender}
                  touched={formik.touched.gender}
                />
              </div>

              {isCreateEmployeeLoading ? (
                <Spinner
                  color="primary"
                  sx={{
                    width: "35px",
                  }}
                />
              ) : isUpdateMode ? (
                <Button
                  icon={<Icon name="save" size={15} fill="currentcolor" />}
                  variant="primary"
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  icon={<Icon name="add" size={15} fill="currentcolor" />}
                  variant="primary"
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  Add Employee
                </Button>
              )}
            </form>
          </FormikProvider>
        </FormContainer>
      )}
      <LocationPicker
        title="Pickup Address"
        isOpen={isLocationPickupOpen}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        center={{
          lat: formik.values.pickup?.coords?.lat || cityCenterPoint.lat,
          lng: formik.values.pickup?.coords?.lng || cityCenterPoint.lng,
        }}
        isEditable
        fixedMarker={{
          location: employee?.pickup?.coords,
          name: employee?.pickup?.address,
          lastUpdatedAt: !!employee?.pickup?.updatedAt && new Date(employee.pickup.updatedAt),
          updatedBy:
            !!employee?.pickup?.updatedBy &&
            `${startCase(employee.pickup.updatedBy.name)}  (${startCase(
              employee.pickup.updatedBy.corpRole
            )})`,
          icon: MarkerGrey,
        }}
        draggableMarker={{
          location: formik.values.pickup?.coords,
          name: formik.values.pickup?.address,
          icon: Marker,
        }}
        onConfirm={({ location, name }) => {
          formik.setFieldValue("pickup", {
            address: name,
            coords: {
              lat: location.lat,
              lng: location.lng,
            },
          });
          formik.setFieldTouched("pickup", true);
          setLocationPickupOpen(false);
        }}
        onClose={() => {
          setLocationPickupOpen(false);
          formik.setFieldTouched("pickup", true);
        }}
        confirmText="Apply"
        closeText="Cancel Changes"
      />
      <UpdateEmployeePopup
        data={popupData}
        onApprove={() => {
          const { value, id } = popupData;
          if (value) {
            updateEmployee({ id, ...value });
          }
          setPopupData(null);
        }}
        onDismiss={() => setPopupData(null)}
      />
    </Page>
  );
};

export default EmployeesCreate;
