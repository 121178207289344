import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type AuthenticationModel = {
  token?: string;
  expiryDate?: string;
  refreshToken?: string;
  jwt?: string;
  role?: string;
  user?: User;
  error?: string;
};

export interface User {
  _id: string;
  name: string;
  email: string;
  phone: string;
  corpRole: string;
  corporate: Corporate;
  city: {
    center: {
      loc: {
        coordinates: number[];
      };
    };
  };
}

export interface Corporate {
  _id: string;
  name: string;
  shift_type: "fixed" | "dynamic";
  private_ride_enabled: boolean;
  allow_delete_all_business_profiles: boolean;
}

export const initialAuthData: AuthenticationModel = {
  token: null,
  expiryDate: null,
  refreshToken: null,
  jwt: null,
  role: null,
  error: null,
};

export type AuthStateContextProps = {
  authData: AuthenticationModel;
  setAuthData: Dispatch<SetStateAction<AuthenticationModel>>;
  resetAuthData: () => void;
};
export const AuthStateContext = createContext<AuthStateContextProps>({
  authData: initialAuthData,
  setAuthData: () => undefined,
  resetAuthData: () => undefined,
});

export function useAuthState(): AuthStateContextProps {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    console.error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

export default useAuthState;
