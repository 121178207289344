/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, Dispatch, SetStateAction, useEffect, useCallback, useRef } from "react";
import theme from "@swvl/theme";
import useRoveFocus from "utils/useRoveFocus";

export type Option = Record<string, unknown>;
type Options = Option[];

export type MenuProps = {
  options: Options;
  handleSelect: (option: Option) => void;
  renderOption: (option: Option) => JSX.Element;
  getOptionKey: (option: Option) => string | number | undefined;
};

export type MenuItemProps = {
  children: React.ReactChild;
  isFocused: boolean;
  index: number;
  setFocus: Dispatch<SetStateAction<number>>;
  onSelectItem: () => void;
};

export const MenuWrapper = ({ children, ...rest }) => {
  return (
    <ul
      sx={{
        borderRadius: "8px",
        position: "absolute",
        right: 2,
        left: 0,
        filter: `drop-shadow(0px 16px 16px ${theme.colors.opacity["black-08"]})`,
        backgroundColor: "#fff",
        padding: 0,
        margin: 0,
        maxHeight: 400,
        listStyle: "none",
      }}
      {...rest}
    >
      {children}
    </ul>
  );
};

const MenuItemWrapper = ({ children, ...rest }) => {
  return (
    <button
      sx={{
        width: "100%",
        textAlign: "left",
        padding: 16,
        margin: 0,
        background: "inherit",
        border: "none",
        borderBottom: theme.borderStyling.thin,
        boxShadow: "none",
        "&:hover": {
          cursor: "pointer",
          background: theme.colors.ghostwhite,
        },
        "&:focus": {
          outline: "none",
        },
      }}
      {...rest}
    >
      {children}
    </button>
  );
};

const ListItem: FC<MenuItemProps> = ({ children, isFocused, index, setFocus, onSelectItem }) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const node = ref.current;
    if (isFocused && node) {
      node.focus();
    }
  }, [isFocused]);

  const handleSelect = useCallback(() => {
    onSelectItem();
    setFocus(index);
  }, [index, onSelectItem, setFocus]);

  return (
    <li
      role="option"
      tabIndex={isFocused ? 0 : -1}
      ref={ref}
      onKeyPress={handleSelect}
      aria-selected={isFocused}
      sx={{
        "&:focus": {
          background: "ghostwhite",
          outline: "none",
        },
      }}
    >
      <MenuItemWrapper onClick={handleSelect}>{children}</MenuItemWrapper>
    </li>
  );
};

const List: FC<MenuProps> = ({ options, renderOption, getOptionKey, handleSelect }) => {
  const [focusIndex, setFocusIndex] = useRoveFocus(options.length);
  return (
    <MenuWrapper>
      {options.length > 0
        ? options.map((item: Option, index: number) => {
            const itemKey = getOptionKey(item);
            return (
              <ListItem
                setFocus={setFocusIndex}
                isFocused={focusIndex === index}
                key={itemKey}
                index={index}
                onSelectItem={() => {
                  handleSelect(item);
                }}
              >
                {renderOption(item)}
              </ListItem>
            );
          })
        : null}
    </MenuWrapper>
  );
};

export default List;
