import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { format } from "date-fns";
import apiInstance from "api";
import { toCamelCase } from "utils";
import { showAlert } from "@swvl/alert";
import { FilterMap } from "utils/useFilters";
import { PRIVATE_RIDES_LIST_PAGE_SIZE } from "constants/privateRides";

export type PrivateRidesModel = {
  id: string;
  userName: string;
  pickupAddress: string;
  dropoffAddress: string;
  date: string;
  time: string;
  operatorStatus: string;
  corporateStatus: string;
};

export type PrivateRidesListModel = {
  count: number;
  PrivateRides: PrivateRidesModel[];
};

interface PrivateRidesList {
  _id: string;
  user_name: string;
  pickup_address: string;
  dropoff_address: string;
  date: string;
  operator_status: string;
  corporate_status: string;
}

type PrivateRidesResponse = {
  totalCount: number;
  requests: PrivateRidesList[];
};

const transformResponse = (response: PrivateRidesResponse): PrivateRidesListModel => {
  response.requests.map((item) => (item["time"] = format(new Date(item.date), "hh:mm aa")));
  const data = {
    count: response.totalCount,
    PrivateRides: response.requests.map((item) => toCamelCase(item) as PrivateRidesModel),
  };
  return data;
};

const fetchPrivateRides = async (params: {
  page: number;
  limit: number;
  sort: string;
  filters?: FilterMap;
}): Promise<PrivateRidesListModel> => {
  const response = await apiInstance.get<AxiosResponse, PrivateRidesResponse>("private_rides", {
    params,
  });
  return transformResponse(response);
};

const usePrivateRides = ({ page, filters }: { page: number; filters?: FilterMap }) => {
  const query = useQuery(
    ["PrivateRides", page, filters],
    () =>
      fetchPrivateRides({
        page,
        limit: PRIVATE_RIDES_LIST_PAGE_SIZE,
        ...filters,
        sort: "-createdAt",
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (response) => {
        showAlert({
          message: (response as AxiosResponse)?.data?.message,
          type: "error",
          id: "alert-error",
        });
      },
    }
  );
  return query;
};

export { usePrivateRides, fetchPrivateRides, transformResponse };
