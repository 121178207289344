import { useQuery } from "react-query";
import apiInstance from "api";

// Types
type ValidateResetPasswordTokenRequest = {
  token: string;
};

// Resources
const validateResetPassword = async (params: ValidateResetPasswordTokenRequest) => {
  const response = await apiInstance.get<ValidateResetPasswordTokenRequest, {}>("password/reset", {
    params,
  });

  return response;
};

const useValidateResetPassword = (token: string) => {
  const validationErrorMessage = "Oops, you're trying an invalid token.";
  const query = useQuery("password/reset", () => validateResetPassword({ token }), {
    enabled: false,
    retry: false,
  });

  return {
    ...query,
    validationErrorMessage,
  };
};

export { useValidateResetPassword };
