import isFunction from "lodash/fp/isFunction";
import { useState } from "react";

const useLocalStorage = <V>(
  key: string,
  initialValue?: V,
  onAccessError?: () => void,
  onSetError?: () => void
) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<V>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      onAccessError();
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: V | ((stored: V) => V)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = isFunction(value) ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      onSetError();
    }
  };

  return { storedValue, setValue };
};

export default useLocalStorage;
