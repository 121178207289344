/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import { FC } from "react";
import { format } from "date-fns";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import { WeekdaysList } from "@swvl/weekdays";

import { convertMinutesAmPmTime, getWeekDaysList, getWeekDaysSelected } from "utils";

export type ShiftData = {
  id: string;
  officeLocation: { id: string; name: string };
  startDate: string;
  endDate: string;
  time: number;
  direction: string;
  weekdays: number[];
  handleAssignToShift?: (id: string) => void;
  handleUnAssignToShift?: (id: string) => void;
  canAssign: boolean;
  disableAssign?: boolean;
};

const ShiftAssignmentCard: FC<ShiftData> = ({
  id,
  officeLocation,
  time,
  startDate,
  endDate,
  direction,
  weekdays,
  handleAssignToShift,
  handleUnAssignToShift,
  canAssign,
  disableAssign = false,
}) => {
  const { timeString, isDayTime } = convertMinutesAmPmTime(time);

  return (
    <div
      sx={{
        margin: 2,
        border: "1px solid #EBEBEB",
        borderRadius: "6px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingY: 2,
          paddingX: 4,
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          borderBottom: "1px solid #EBEBEB",
          background: "whitesmoke",
        }}
      >
        <Text
          variant="subhead"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <span sx={{ py: 2 }}>
            {officeLocation.name} - {timeString}
          </span>
        </Text>
        <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
          {canAssign ? (
            <Button
              variant="secondary"
              icon={<Icon name="checked" size={16} fill="currentcolor" />}
              iconPosition="left"
              disabled={disableAssign}
              sx={{ ml: 3, textTransform: "capitalize", background: "white" }}
              onClick={() => handleAssignToShift(id)}
            >
              Assign to shift
            </Button>
          ) : (
            <Button
              variant="secondary"
              icon={<Icon name="unassign" size={16} fill="currentcolor" />}
              iconPosition="left"
              disabled={disableAssign}
              sx={{ ml: 3, textTransform: "capitalize", background: "white" }}
              onClick={() => handleUnAssignToShift(id)}
            >
              Unassign from shift
            </Button>
          )}
        </Flex>
      </div>

      <div
        sx={{
          paddingY: 3,
          paddingX: 4,
        }}
      >
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            alignItems: "center",
            fontSize: "13px",
          }}
        >
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="scheduling" size={20} fill="opacity.black-54" />{" "}
            <span sx={{ marginX: "4px", fontWeight: "bold" }}>Start Date:</span>
            {format(new Date(startDate), "dd/MM/yyyy")}
          </Flex>
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="scheduling" size={20} fill="opacity.black-54" />
            <span sx={{ marginX: "4px", fontWeight: "bold" }}>End Date:</span>
            {format(new Date(endDate), "dd/MM/yyyy")}
          </Flex>
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="clock_filled" size={20} fill="opacity.black-54" />
            <span sx={{ marginX: "4px", fontWeight: "bold" }}>Time:</span>
            {timeString}
          </Flex>
          <Flex sx={{ alignItems: "center", gridColumn: "span 2/span 2" }}>
            <Icon name="scheduling" size={20} fill="opacity.black-54" />{" "}
            <span sx={{ marginX: "4px", fontWeight: "bold" }}>Weekdays:</span>
            <WeekdaysList
              selectedDays={getWeekDaysSelected(weekdays)}
              days={getWeekDaysList()}
              variant="gray"
            />
          </Flex>
        </div>

        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            alignItems: "center",
            mt: 3,
            fontSize: "13px",
          }}
        >
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="shift_direction" size={20} fill="opacity.black-54" />
            <span sx={{ marginX: "4px", fontWeight: "bold" }}>Shift direction:</span>
            {direction}
          </Flex>

          <Flex sx={{ alignItems: "center", gridColumn: "4 span/4 span" }}>
            <Icon name="office_address" size={20} fill="opacity.black-54" />
            <span sx={{ marginX: "4px", fontWeight: "bold" }}>Office Location:</span>
            {officeLocation.name}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default ShiftAssignmentCard;
