/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from "react";
import { jsx, Text } from "theme-ui";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type DeleteEmployeeProps = {
  isOpen: boolean;
  employeeId: string;
  onApprove: () => void;
  onDismiss: () => void;
};

const DeleteEmployeePopup: FC<DeleteEmployeeProps> = (props) => {
  const { isOpen, employeeId, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={isOpen}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Delete Employee"
      message={<Text>Are you sure you want to delete this user?</Text>}
      confirmButtonText="Delete Employee"
      trackingParams={{
        actionType: "remove employee",
        resourceType: "employee",
        resourceId: employeeId,
      }}
    />
  );
};

export default DeleteEmployeePopup;
