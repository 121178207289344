import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import Employee from "./CreateUpdateEmployee";
import EmployeesList from "./EmployeesList";
import ShiftAssignment from "./ShiftAssignment";

export default function Employees(props: unknown) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route key="Create" exact path={`${path}/create`} component={Employee} />
      <Route key="Edit" exact path={`${path}/edit`} component={() => <Employee isUpdate />} />
      <Route key="List" exact path={`${path}`} component={EmployeesList} />
      <Route
        key="ShiftAssignment"
        exact
        path={`${path}/shift-assignment`}
        component={ShiftAssignment}
      />
      <Redirect to={path} />
    </Switch>
  );
}
