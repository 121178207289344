/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Grid } from "theme-ui";
import { FC, useState, Fragment } from "react";
import Popup from "@swvl/popup";
import Button from "@swvl/button";
import FileUpload from "@swvl/file-upload";
import Icon from "@swvl/icon";
import { isNil } from "lodash";

export type BulkUploadTemplateColumns = {
  name: string;
  mandatory: boolean;
  example: string;
}[];

export type BulkUploadPopupProps = {
  name?: string;
  columns: BulkUploadTemplateColumns;
  isOpen: boolean;
  onDownloadTemplate: () => void;
  onUpload: (files) => void;
  onDismiss: () => void;
  downloadDisabled?: boolean;
  isDownloadAnotherFile: boolean;
  isDownloadAnotherFileDisabled?: boolean;
  onDownloadAnotherFile?: () => void;
  anotherFileName?: string;
};

export const BulkUploadPopup: FC<BulkUploadPopupProps> = (props) => {
  const {
    name,
    columns,
    isOpen,
    onDownloadTemplate,
    onUpload,
    onDismiss,
    downloadDisabled = false,
    isDownloadAnotherFile,
    isDownloadAnotherFileDisabled,
    onDownloadAnotherFile,
    anotherFileName,
  } = props;
  const [files, setFiles] = useState([]);

  const upload = () => {
    onUpload(files);
  };

  if (isNil(columns)) return null;
  return (
    <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={600}>
      <div sx={{ m: 3 }} data-testid="bulk-upload-popup">
        <Text sx={{ mb: 3, fontWeight: "bold" }} variant={"headline"}>
          Bulk Upload
        </Text>
        <Text sx={{ mb: 3 }} variant={"body-small"}>
          Add and merge to existing list. Please upload the correctly filled template
        </Text>
        <div sx={{ borderRadius: 8, bg: "lemonchiffon", mb: 3, px: 3, py: 3 }}>
          <div sx={{ mb: 2 }}>
            <Icon name="info" size={30} fill="currentcolor" />
          </div>
          <Grid columns={[3, "3fr 1fr 3fr"]} gap={2}>
            <Text sx={{ fontWeight: "bold" }}>Field</Text>
            <Text sx={{ fontWeight: "bold" }}>Mandatory</Text>
            <Text sx={{ fontWeight: "bold" }}>Example</Text>
            {columns.map((column) => (
              <Fragment key={column.name}>
                <Text sx={{ whiteSpace: "nowrap" }}>{column.name}</Text>
                <div>{column.mandatory && <Icon name="completed" size={15} />}</div>
                <Text sx={{ whiteSpace: "nowrap" }}>{column.example}</Text>
              </Fragment>
            ))}
          </Grid>
        </div>
        <div sx={{ mb: 3 }}>
          <FileUpload files={files} setFiles={setFiles} />
        </div>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Button
            id={`template-${name}-bulk-upload`}
            onClick={onDownloadTemplate}
            variant={"primary"}
            sx={{
              bg: "secondary",
              ":hover": {
                bg: "purple.400",
              },
            }}
            type="button"
            disabled={downloadDisabled}
          >
            Download Template
          </Button>
          {isDownloadAnotherFile && (
            <Button
              id={`template-${name}-bulk-upload`}
              onClick={onDownloadAnotherFile}
              variant={"primary"}
              sx={{
                bg: "secondary",
                ":hover": {
                  bg: "purple.400",
                },
              }}
              type="button"
              disabled={isDownloadAnotherFileDisabled}
            >
              Download {anotherFileName}
            </Button>
          )}
          <Button
            id={`submit-${name}-bulk-upload`}
            variant={"primary"}
            onClick={upload}
            type="button"
            disabled={files.length === 0}
          >
            Upload
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default BulkUploadPopup;
