import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { User } from "utils/useAuthState";

const fetchAdminDetails = async (): Promise<User> => {
  const response = await apiInstance.get<AxiosResponse, User>(`me`);

  return response;
};

const useAdminDetails = () => {
  return useQuery(["me"], () => fetchAdminDetails(), {
    refetchOnWindowFocus: false,
  });
};
export { useAdminDetails, fetchAdminDetails };
