import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toCamelCase } from "utils";
import { isNil } from "lodash";

export interface SearchEmployeesResponse {
  hits: {
    _id: string;
    name: string;
    email: string;
    corporate: string;
    createdAt: string;
    deleted: boolean;
    phone: string;
    phone_region_code: string;
    updatedAt: string;
  }[];
}

export type SearchEmployeesModel = {
  id: string;
  corporate: string;
  createdAt: string;
  deleted: boolean;
  name: string;
  phone: string;
  email: string;
  user: string;
  phoneRegionCode: string;
  updatedAt: string;
};

type SearchEmployeesListModel = SearchEmployeesModel[];

const transformResponse = (response: SearchEmployeesResponse): SearchEmployeesListModel => {
  return response.hits.map((hit) => ({
    id: hit._id,
    ...toCamelCase(hit),
  })) as SearchEmployeesListModel;
};

const searchEmployees = async (
  input: string,
  can_use_private_ride?: boolean
): Promise<SearchEmployeesListModel> => {
  const response = await apiInstance.get<AxiosResponse, SearchEmployeesResponse>("user", {
    params: { search_query: input, can_use_private_ride },
  });

  return transformResponse(response);
};

const useSearchEmployees = (input: string, can_use_private_ride?: boolean) => {
  return useQuery(
    ["employees/autocomplete", input],
    () => searchEmployees(input, can_use_private_ride),
    {
      enabled: !isNil(input),
      initialData: [] as SearchEmployeesListModel,
      refetchOnWindowFocus: false,
    }
  );
};

export { useSearchEmployees, searchEmployees, transformResponse };
