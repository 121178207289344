/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";
import { EmployeeModel } from "resources/useEmployees";
import { pluralize } from "utils";

type AssignEmployeesProps = {
  activeEmployees: Partial<EmployeeModel>[];
  inActiveEmployees: Partial<EmployeeModel>[];
  onApprove: () => void;
  onDismiss: () => void;
};

const AssignEmployeesPopup: FC<AssignEmployeesProps> = (props) => {
  const { activeEmployees, inActiveEmployees, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={!isNil(inActiveEmployees)}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Assign Selected Employees To Shifts"
      message={
        activeEmployees.length > 0 ? (
          <div>
            <Text>
              You will proceed to assign{" "}
              <span sx={{ fontWeight: "bold" }}>
                {pluralize(activeEmployees.length, "active employee")}
              </span>
            </Text>
            <Text mt={1}>
              <span sx={{ fontWeight: "bold", color: "crimson" }}>
                {pluralize(inActiveEmployees.length, "inactive employee")}
              </span>{" "}
              will be discarded !
            </Text>
          </div>
        ) : (
          <Text>Select at least 1 active employee to proceed</Text>
        )
      }
      confirmButtonText="Assign Active Employees"
      confirmVisible={activeEmployees.length > 0}
    />
  );
};

export default AssignEmployeesPopup;
