/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import Timeline, { TimelineElement } from "@swvl/timeline";
import Icon from "@swvl/icon";
import Tag from "@swvl/tag";
import { RIDE_STATUS_TAG_COLOR, RIDE_STATUS_TAG_NAME } from "constants/rideMonitoring";
import { RideModel } from "resources/useRides";
import { useEffect, useRef } from "react";

type RideCardProp = RideModel & {
  heading: string;
  selected: boolean;
};

const RideCard = ({
  id,
  status,
  routeName,
  heading,
  firstStation,
  lastStation,
  seats,
  selected,
}: RideCardProp) => {
  const ref = useRef();

  useEffect(() => {
    if (selected && ref.current) {
      //@ts-ignore
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selected]);

  return (
    <div
      sx={{
        p: "18px 24px",
        backgroundColor: selected ? "ghostwhite" : "white",
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.08)",
      }}
      ref={ref}
      key={id}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            width: "175px",
            wordWrap: "break-word",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          {routeName}
        </div>
        <Tag size="small" variant={RIDE_STATUS_TAG_COLOR[status]}>
          <span sx={{ fontWeight: "bold" }}>{RIDE_STATUS_TAG_NAME[status]}</span>
        </Tag>
      </div>
      <Text variant={"caption-large"} sx={{ fontWeight: "bold", textAlign: "left", mt: 2 }}>
        {heading}
      </Text>

      <Timeline sx={{ m: "10px 0" }} variant={"hollow"} rowsSpacing="10px">
        <TimelineElement color="primary" sx={{ textAlign: "left" }}>
          {firstStation?.replace(/"/g, "") || ""}
        </TimelineElement>
        <TimelineElement color="secondary" sx={{ textAlign: "left" }}>
          {lastStation?.replace(/"/g, "") || ""}
        </TimelineElement>
      </Timeline>
      <div
        sx={{
          mt: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div sx={{ display: "flex", height: "17px", alignItems: "center", flex: 0.7 }}>
          <Icon size={16} name="bus_alt" />
          <Text sx={{ ml: "5px" }} variant="body-small">
            {seats.maximum}
          </Text>
        </div>
        <div sx={{ display: "flex", height: "17px", alignItems: "center", flex: 1 }}>
          <div sx={{ mt: "5px" }}>
            <Icon size={17} name="employees_duo" />
          </div>
          <Text sx={{ ml: "5px" }} variant="body-small">
            {seats.count}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default RideCard;
