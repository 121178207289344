/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Link } from "theme-ui";
import { FC, Fragment, useEffect, useCallback, useState, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Tag, { VARIANT } from "@swvl/tag";
import isNil from "lodash/fp/isNil";
import startCase from "lodash/fp/startCase";
import Drawer from "@swvl/drawer";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import { Button } from "@swvl/button";
import ProfileLayout from "components/ProfileLayout";
import { removeFromQuery, constants } from "utils";
import { usePrivateRideDetails, PrivateRideDetails } from "resources/usePrivateRideDetails";
import useAuthState from "utils/useAuthState";

import { format } from "date-fns";
export type PopupData = { id: string; values: PrivateRideDetails };
type DetailsProp = {
  requestId: string;
};
export const STATUS_TAG_COLOR = {
  Approved: VARIANT.mediumseagreen,
  Rejected: VARIANT.crimson,
  ["Pending For Approval"]: VARIANT.slategray,
};

const Details: FC<DetailsProp> = ({ requestId }) => {
  const { data: details, status, isFetching: isFetchingRequestDetails } = usePrivateRideDetails({
    id: requestId,
  });
  const {
    authData: { user },
  } = useAuthState();

  const {
    userName,
    date,
    corporateStatus,
    createdBy,
    id,
    operatorStatus,
    pickupAddress,
    dropoffAddress,
    busType,
    rejectionReason,
    additionalDetails,
  } = details || {};

  const formattedDate = details?.date ? format(new Date(date), constants.dateFormat) : "";
  const formattedTime = details?.date ? format(new Date(date), constants.timeFormat) : "";
  const title = `${pickupAddress} - ${formattedTime}`;
  return isFetchingRequestDetails ? (
    <div sx={{ position: "relative" }}>
      <Spinner
        color="primary"
        sx={{
          width: 50,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  ) : (
    <ProfileLayout name={title} key={id} hasAvatar={false}>
      <div
        sx={{
          position: "sticky",
          paddingTop: 20,
          background: "whitesmoke",
          zIndex: 100,
          mx: "-24px",
          top: 0,
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            background: "whitesmoke",
          }}
        >
          <div sx={{ display: "flex", flexDirection: "column", ml: "24px" }}>
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "17px",
                lineHeight: "20px",
              }}
            >
              {title}
            </Text>
          </div>
        </div>
      </div>

      <Text
        sx={{
          fontWeight: "bold",
          fontSize: "15px",
        }}
      >
        Private Ride
      </Text>

      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="gender" size={15} fill="black" />
        <Text data-test-id="employee-name" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Employee Name: </strong>
          {userName}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="scheduling" size={15} fill="black" />
        <Text data-test-id="date" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Start Date: </strong>
          {formattedDate}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="clock_filled" size={15} fill="black" />
        <Text data-test-id="time" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Time: </strong>
          {formattedTime}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="office_address" size={15} fill="black" />
        <Text data-test-id="pickup-location" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Pickup Location: </strong>
          {pickupAddress}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="office_address" size={15} fill="black" />
        <Text data-test-id="dropoff-location" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Drop-off Location: </strong>
          {dropoffAddress}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="bus_alt" size={15} fill="black" />
        <Text data-test-id="vehicle-info" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Vehicle Type: </strong>
          {busType}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="settings_outline" size={15} fill="black" />
        <Text data-test-id="created-by" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Created By: </strong>
          {createdBy}
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="info" size={15} fill="black" />
        <Text data-test-id="corporate-status" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Corporate Status: </strong>
          <Tag size="small" variant={STATUS_TAG_COLOR[corporateStatus]}>
            <span sx={{ fontWeight: "bold" }}>{corporateStatus}</span>
          </Tag>
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="info" size={15} fill="black" />
        <Text data-test-id="operator-status" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Operator Status: </strong>
          <Tag size="small" variant={STATUS_TAG_COLOR[operatorStatus]}>
            <span sx={{ fontWeight: "bold" }}>{operatorStatus}</span>
          </Tag>
        </Text>
      </div>
      <div
        css={{
          margin: "10px 0",
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="know_more" size={15} fill="black" />
        <Text data-test-id="additional-details" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
          <strong>Additional Details: </strong>
          {additionalDetails}
        </Text>
      </div>

      {rejectionReason && (
        <div
          css={{
            margin: "10px 0",
            overflowWrap: "anywhere",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Icon name="error" size={15} fill="black" />
          <Text data-test-id="rejection-reason" sx={{ ml: 2, fontSize: "15px", flexShrink: 10 }}>
            <strong>Reason For Rejection: </strong>
            {rejectionReason.reason}
          </Text>
        </div>
      )}
      {rejectionReason && rejectionReason?.note && (
        <div
          css={{
            margin: "10px 0",
            overflowWrap: "anywhere",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon name="error" size={15} fill="black" />
          <Text data-test-id="rejection-reason" sx={{ ml: 2, fontSize: "15px" }}>
            <strong>Note: </strong>
            {rejectionReason.note}
          </Text>
        </div>
      )}
    </ProfileLayout>
  );
};

type ProfileProps = {
  requestId: string;
};

const PrivateRideRequest: FC<ProfileProps> = ({ requestId }) => {
  const history = useHistory();
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    if (requestId) setDetailsOpen(true);
  }, [requestId]);

  const handlCloseDrawer = useCallback(() => {
    setDetailsOpen(false);
    removeFromQuery("private-id", history);
  }, []);

  return (
    <Fragment>
      <Drawer
        isOpen={isDetailsOpen}
        size={"default"}
        anchor={"right"}
        clickOutSideToClose={true}
        closeDrawer={handlCloseDrawer}
      >
        <Details requestId={requestId} />
      </Drawer>
    </Fragment>
  );
};

export default memo(PrivateRideRequest);
