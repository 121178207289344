/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import ReactTooltip from "react-tooltip";
import Spinner from "@swvl/spinner";
import Button from "@swvl/button";
import { useHistory } from "react-router-dom";
import theme from "@swvl/theme";
import Icon from "@swvl/icon";

type HeaderProps = {
  openBulkUploadPopup: () => void;
  isLoading: boolean;
};

export const Header: FC<HeaderProps> = ({ openBulkUploadPopup, isLoading }) => {
  const history = useHistory();
  return (
    <header
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: "bold" }}>
        Private Ride Dashboard
      </Text>
      <div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Button
          data-test-id="addNewPrivateRide"
          variant="primary"
          icon={<Icon name="add" size={20} fill={"white"} />}
          iconPosition="left"
          onClick={() => {
            history.push("/private-ride/create");
          }}
        >
          Add New Private Ride
        </Button>
        <Button
          id="click-private-ride-bulk-upload"
          variant="secondary"
          icon={<Icon name="upload" size={20} fill={"currentcolor"} />}
          iconPosition="left"
          onClick={openBulkUploadPopup}
          sx={{ ml: 2 }}
        >
          Bulk Upload
        </Button>
        <div
          data-for="bulk-upload-info"
          data-tip="Add and merge to existing list.<br/>Please upload the correctly filled template"
          data-iscapture="true"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <Icon name="info" size={20} fill={theme.colors.opacity["black-38"]} />
        </div>
      </div>
      <ReactTooltip
        id="bulk-upload-info"
        place="bottom"
        effect="solid"
        type="dark"
        multiline
        offset={{
          bottom: 50,
          right: 10,
        }}
        arrowColor={"transparent"}
      />
    </header>
  );
};

export default Header;
