import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { convertMinutesAmPmTime } from "utils";
import { showAlert } from "@swvl/alert";

type ShiftTime = number;
type ShiftTimeModel = {
  value: string;
  format: string;
};

export type ShiftsTimesModel = {
  count: number;
  shiftsTimes: ShiftTimeModel[];
};

type ShiftsTimesResponse = {
  type?: string;
  total: number;
  hits: ShiftTime[];
};

const transformResponse = (response: ShiftsTimesResponse): ShiftsTimesModel => {
  const data = {
    count: response.total,
    shiftsTimes: response.hits.map((shiftTime) => ({
      format: convertMinutesAmPmTime(shiftTime).timeString,
      value: shiftTime.toString(),
    })),
  };
  return data;
};

const fetchShiftsTimes = async (): Promise<ShiftsTimesModel> => {
  const response = await apiInstance.get<AxiosResponse, ShiftsTimesResponse>("shifts/times");

  return transformResponse(response);
};

const useShiftsTimes = () => {
  const query = useQuery(["shifts-times"], () => fetchShiftsTimes(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (response) => {
      showAlert({
        message: (response as AxiosResponse)?.data?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return query;
};

export { useShiftsTimes, fetchShiftsTimes, transformResponse };
