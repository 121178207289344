import { useQuery, useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { AxiosResponse } from "axios";

export type BulkUploadRequest = FormData;
export interface BulkUploadTemplateResponse {
  fields: string[];
  mandatory_fields: string[];
}
export type BulkUploadTemplateColumns = {
  name: string;
  mandatory: boolean;
  example: string;
}[];

export type BulkUploadModel = {
  columns: BulkUploadTemplateColumns;
};

// Transforms
export const transformResponse = ({
  fields,
  mandatory_fields: mandatoryFields,
}: BulkUploadTemplateResponse): BulkUploadTemplateColumns => {
  const examples = {
    name: "Alice Smith",
    phone_region_code: "eg",
    phone: "<country-code><phone>",
    email: "abc@xyz.com",
    employee_id: "abc123",
    "latitude, longitude": "31.2556,45.65465645",
    gender: "male/female/other",
  };
  return fields.map((field) => ({
    name: `"${field}"`,
    mandatory: !!mandatoryFields?.find((mandatoryField) => field === mandatoryField) || false,
    example: examples[field] || "",
  }));
};

const bulkUploadEmployees = async (data: BulkUploadRequest): Promise<string> => {
  const response = await apiInstance.post<BulkUploadRequest, string>(
    "bulk_actions/?action=create_business_profiles",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

const useBulkUploadEmployees = () => {
  const errorMessage = "Please upload file with the correct .csv template.";
  const successMessage = "Please check your email for a successful update.";
  const mutation = useMutation(bulkUploadEmployees, {
    mutationKey: "employees/bulkupload",
    onError: (error) => {
      showAlert({
        message: errorMessage ? errorMessage : error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
    onSuccess: (data) => {
      showAlert({
        message: successMessage,
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

const fetchBulkUploadEmployeesTemplate = async (): Promise<BulkUploadTemplateColumns> => {
  const response = await apiInstance.get<AxiosResponse, BulkUploadTemplateResponse>(
    "bulk_actions/template?action=create_business_profiles"
  );

  return transformResponse(response);
};

const useBulkUploadEmployeesTemplate = () => {
  return useQuery("employees/bulkupload/template", () => fetchBulkUploadEmployeesTemplate(), {
    refetchOnWindowFocus: false,
  });
};

export { useBulkUploadEmployeesTemplate, useBulkUploadEmployees };
