import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { getFromQuery } from "utils";
import { useLocation } from "react-router-dom";

interface ApprovePrivateRideRequest {
  data: {
    id: string;
  };
  userName: string;
}

const approvePrivateRideRequest = async ({ data }: ApprovePrivateRideRequest): Promise<string> => {
  const response = await apiInstance.post<undefined, string>(`private_rides/${data.id}/approve`);

  return response;
};

const useApprovePrivateRideRequest = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const mutation = useMutation(approvePrivateRideRequest, {
    mutationKey: "privateRides/approve",
    onSuccess: (_data, { userName }) => {
      const page = getFromQuery("page", location.search);
      queryClient.invalidateQueries(["PrivateRides", +page]);
      showAlert({
        message: `You have requested a private ride request for ${userName} successfully
        `,
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useApprovePrivateRideRequest };
