/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import Button from "@swvl/button";
import { useAdminDetails } from "resources/useAdminDetails";
import { useOfficeLocations } from "resources/useOfficeLocations";

export const Header = ({ isLoading }) => {
  const history = useHistory();
  const { data: loggedInAdminDetails, isLoading: isAdminDetailsLoading } = useAdminDetails();
  const { data: officeLocationsData, isLoading: isOfficeLocationsLoading } = useOfficeLocations();

  const shouldShowAddNewShiftButton = useMemo<boolean>(
    () =>
      officeLocationsData?.count > 0 && loggedInAdminDetails?.corporate?.shift_type === "dynamic",
    [officeLocationsData, loggedInAdminDetails]
  );
  return (
    <header
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: "bold" }}>
        {" "}
        Shift Dashboard
      </Text>
      <div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {isLoading || isAdminDetailsLoading || isOfficeLocationsLoading ? (
          <Spinner
            sx={{
              mx: 2,
            }}
            size={20}
            color="lightcoral"
          />
        ) : null}
        {shouldShowAddNewShiftButton && (
          <Button
            variant="primary"
            icon={<Icon name="add" size={20} fill={"white"} />}
            iconPosition="left"
            onClick={() => {
              history.push("/shifts/create");
            }}
          >
            Add New Shift
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;
