/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type PopupData = { data: unknown; userName?: string };

type ApproveRequestProps = {
  data: PopupData;
  onApprove: () => void;
  onDismiss: () => void;
  testId: string;
};

const ApproveRequestPopup: FC<ApproveRequestProps> = (props) => {
  const { data, onApprove, onDismiss, testId } = props;
  return (
    <ConfirmationPopup
      isOpen={!isNil(data)}
      onApprove={onApprove}
      testId={testId}
      onDismiss={onDismiss}
      title="Approve Private Ride Request"
      message={
        <Text>
          You will proceed to approve a private ride request for{" "}
          <span sx={{ fontWeight: "bold" }}>{data?.userName}</span>{" "}
        </Text>
      }
      confirmButtonText="Approve Private Ride Request"
    />
  );
};

export default ApproveRequestPopup;
