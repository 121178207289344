/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Link } from "theme-ui";
import { FC, Fragment, useEffect, useCallback, useState, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import isNil from "lodash/fp/isNil";
import startCase from "lodash/fp/startCase";
import Drawer from "@swvl/drawer";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import { Button } from "@swvl/button";
import Toggle from "@swvl/toggle";
import LocationPicker from "@swvl/location-picker";
import ProfileLayout from "components/ProfileLayout";
import ActivationPopup from "pages/employees/ActivateEmployeePopup";
import { removeFromQuery } from "utils";
import { useEmployeeProfile } from "resources/useEmployeeProfile";
import { useActivateEmployee } from "resources/useActivateEmployee";
import { useDeactivateEmployee } from "resources/useDeactivateEmployee";
import { GOOGLE_MAPS_API_KEY } from "constants/rideMonitoring";
import Marker from "../../assets/marker.svg";
import useAuthState from "utils/useAuthState";

type DetailsProp = {
  id: string;
  name: string;
  phone: string;
  phoneRegionCode?: string;
  email?: string;
  active?: boolean;
  employeeId?: string;
  gender?: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt: Date;
    updatedBy: {
      id: string;
      name: string;
      corpRole?: string;
    };
  };
  isRefetching: boolean;
};

export type PopupData = { id: string; value: unknown; name?: string };

const Details: FC<DetailsProp> = ({
  id,
  name,
  active,
  phone,
  email,
  pickup,
  employeeId,
  gender,
  isRefetching,
}) => {
  const history = useHistory();
  const [popupData, setPopupData] = useState<PopupData>(null);
  const [isLocationPickupOpen, setLocationPickupOpen] = useState<boolean>(false);
  const { mutate: activateEmployee } = useActivateEmployee();
  const { mutate: deactivateEmployee } = useDeactivateEmployee();
  const {
    authData: { user },
  } = useAuthState();

  const mapGender = useCallback((gender: string) => {
    switch (gender) {
      case "female":
        return "Female";
      case "male":
        return "Male";
      case "other":
        return "Other";
      default:
        return gender;
    }
  }, []);

  const handleUpdateProfile = useCallback(() => history.push(`/employees/edit?employee-id=${id}`), [
    id,
  ]);

  const handleActivation = useCallback(() => {
    const { id, value } = popupData;
    if (value) {
      deactivateEmployee({ id });
    } else {
      activateEmployee({ id });
    }
    setPopupData(null);
  }, [popupData]);

  const handleDismiss = () => {
    setPopupData(null);
  };

  const handleChangeStatus = () => {
    setPopupData({
      id: id,
      value: active,
      name: name,
    });
  };

  const cityCenterPoint = useMemo(() => {
    const cityCoordinates = user?.city?.center?.loc?.coordinates;
    if (!cityCoordinates) return { lng: 0, lat: 0 };
    return {
      lng: cityCoordinates[0],
      lat: cityCoordinates[1],
    };
  }, [user]);

  return (
    <ProfileLayout name={name} key={id}>
      <ActivationPopup data={popupData} onApprove={handleActivation} onDismiss={handleDismiss} />
      <LocationPicker
        title="Pickup Address"
        isOpen={isLocationPickupOpen}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        center={{
          lat: pickup?.coords?.lat || cityCenterPoint.lat,
          lng: pickup?.coords?.lng || cityCenterPoint.lng,
        }}
        isEditable={false}
        fixedMarker={{
          location: pickup?.coords,
          name: pickup?.address,
          lastUpdatedAt: !!pickup?.updatedAt && new Date(pickup.updatedAt),
          updatedBy:
            !!pickup?.updatedBy &&
            `${startCase(pickup.updatedBy.name)}  (${startCase(pickup.updatedBy.corpRole)})`,
          icon: Marker,
        }}
        onClose={() => {
          setLocationPickupOpen(false);
        }}
        closeText="Done"
      />
      <div
        sx={{
          position: "sticky",
          top: 48,
          paddingTop: 20,
          background: "whitesmoke",
          zIndex: 100,
          mx: "-24px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <div
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "start", mx: "24px" }}
        >
          <div sx={{ display: "flex", flexDirection: "column" }}>
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "17px",
                lineHeight: "20px",
              }}
            >
              {name}
            </Text>
            <Toggle
              labelOn={"Active"}
              labelOff={"Inactive"}
              checked={active}
              id="toggle-active"
              onChange={handleChangeStatus}
              onOffLabelPosition={"start"}
            />
          </div>
          <div sx={{ display: "flex", alignItems: "flex-start" }}>
            {isRefetching ? (
              <Spinner
                color="lightcoral"
                sx={{
                  width: 25,
                  mx: 2,
                }}
              />
            ) : null}
            <Button
              onClick={handleUpdateProfile}
              icon={<Icon name="update_profile" size={20} fill="currentcolor" />}
            >
              Update Profile
            </Button>
          </div>
        </div>
      </div>
      <div
        css={{
          marginTop: 50,
          overflowWrap: "anywhere",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon name="mobile" size={20} fill="transparent" />
        <Text sx={{ ml: 2 }}>{`+${phone}`}</Text>
      </div>
      {email ? (
        <div
          sx={{
            overflowWrap: "anywhere",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Icon name="email" size={20} fill="currentcolor" />
          <Text sx={{ ml: 2 }}>{email}</Text>
        </div>
      ) : null}
      {gender ? (
        <div
          sx={{
            overflowWrap: "anywhere",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Icon name="gender" size={20} fill="currentcolor" />
          <Text sx={{ ml: 2 }}>{mapGender(gender)}</Text>
        </div>
      ) : null}
      {employeeId ? (
        <div
          sx={{
            overflowWrap: "anywhere",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Icon name="employee_id" size={20} fill="transparent" />
          <Text sx={{ ml: 2 }}>{employeeId}</Text>
        </div>
      ) : null}
      {pickup ? (
        <div
          sx={{
            overflowWrap: "anywhere",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <Icon name="address" size={20} />
          </div>
          <div
            sx={{
              display: "flex",
              overflowWrap: "break-word",
              alignItems: "start",
              flexDirection: "column",
              ml: 2,
            }}
          >
            <Text>{pickup.address}</Text>
            <Link
              onClick={() => setLocationPickupOpen(true)}
              sx={{
                color: "secondary",
                fontWeight: "bold",
                ":hover": {
                  color: "primary",
                  cursor: "pointer",
                },
              }}
            >
              View On Map
            </Link>
          </div>
        </div>
      ) : null}
    </ProfileLayout>
  );
};

type ProfileProps = {
  id: string;
};

const Profile: FC<ProfileProps> = ({ id }) => {
  const history = useHistory();
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const { data: profile, status, isFetching } = useEmployeeProfile({ id });
  const isProfileLoading = status === "loading";

  useEffect(() => {
    if (id) setDetailsOpen(true);
  }, [id]);

  const handlCloseDrawer = useCallback(() => {
    setDetailsOpen(false);
    removeFromQuery("employee-id", history);
  }, []);

  return (
    <Fragment>
      <Drawer
        isOpen={isDetailsOpen}
        size={"default"}
        anchor={"right"}
        clickOutSideToClose={true}
        closeDrawer={handlCloseDrawer}
      >
        {isProfileLoading ? (
          <div sx={{ position: "relative" }}>
            <Spinner
              color="primary"
              sx={{
                width: 50,
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        ) : !isNil(profile) ? (
          <Details {...profile} isRefetching={isFetching} />
        ) : (
          <div sx={{ position: "relative" }}>
            <Text
              variant="title-medium"
              sx={{
                position: "fixed",
                textAlign: "center",
                fontWeight: "bold",
                top: "50%",
                width: "100%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Sorry, We just can't get your employee profile, please try again.
            </Text>
          </div>
        )}
      </Drawer>
    </Fragment>
  );
};

export default memo(Profile);
