import { useMutation } from "react-query";
import apiInstance from "api";
import useAuthState, { AuthenticationModel, User } from "utils/useAuthState";

// Types
interface RefreshTokenResponse {
  __v: number;
  updatedAt: string;
  createdAt: string;
  owner: string;
  type: string;
  _id: string;
  expiry_date: string;
  refresh_token: string;
  token: string;
  is_expired: boolean;
  id: string;
  jwt: string;
  role: string;
  user: User;
}

export interface RefreshTokenRequest {
  refresh_token: string;
}

export type RefreshTokenTransform = Omit<AuthenticationModel, "error">;

// Transforms
const transformResponse = (response: RefreshTokenResponse): RefreshTokenTransform => {
  const { token, expiry_date: expiryDate, jwt, refresh_token: refreshToken, role, user } = response;
  return { token, expiryDate, jwt, refreshToken, role, user };
};

// Resources
const refreshToken = async (data: RefreshTokenRequest) => {
  const response = await apiInstance.post<RefreshTokenRequest, RefreshTokenResponse>(
    "token/refresh",
    data
  );
  return transformResponse(response);
};

const useRefreshToken = () => {
  const { authData, setAuthData, resetAuthData } = useAuthState();

  const mutation = useMutation(refreshToken, {
    mutationKey: "user/refresh-token",
    onSuccess: (response) => {
      // TODO: align with backend, the refresh token response doesn't have the same user data in the login request
      setAuthData({ ...response, user: authData.user });
    },
    onError: () => {
      resetAuthData();
    },
  });

  return mutation;
};

export { useRefreshToken, refreshToken };
