import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { useHistory } from "react-router-dom";
import useAuthState, { AuthenticationModel, initialAuthData } from "utils/useAuthState";

// Types
type LogoutRequest = Pick<AuthenticationModel, "token">;

const logoutUser = async (data: LogoutRequest) => {
  const response = await apiInstance.post<LogoutRequest, {}>("sign_out", data);
  return response;
};

const useLogout = () => {
  const { resetAuthData } = useAuthState();
  const history = useHistory();

  const mutation = useMutation(logoutUser, {
    mutationKey: "user/logout",
    onSuccess: () => {
      resetAuthData();
      history.replace("/auth");
      showAlert({
        message: "Logged out successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (response) => {
      if (response["status"] === 404) {
        resetAuthData();
      }
      showAlert({
        message: "Logout failed",
        type: "error",
        id: "alert-error",
      });
    },
  });

  return mutation;
};

export { useLogout };
