import Icon from "@swvl/icon";
import { SidebarTabs } from "components/Sidebar";

export const sidebarTabs: SidebarTabs = [
  {
    id: "employees",
    text: "Employees",
    icon: <Icon name="employees" fill="currentcolor" />,
    link: "/employees",
  },
  {
    id: "shifts",
    text: "Shifts",
    icon: <Icon name="scheduling" fill="currentcolor" />,
    link: "/shifts",
  },
  {
    id: "ride-monitoring",
    text: "Ride Monitoring",
    icon: <Icon name="bus_alt_filled" fill="currentcolor" />,
    link: "/ride-monitoring",
  },
  {
    id: "private-ride",
    text: "Private Ride",
    icon: <Icon name="side_bus" fill="currentcolor" />,
    link: "/private-ride",
  },
];

export default sidebarTabs;
