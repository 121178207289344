/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useRef, useState, useCallback } from "react";
import * as React from "react";
import { useField, useFormikContext } from "formik";
import { PhoneInput } from "@swvl/phone-input";

type PhoneFieldProps = {
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<unknown>) => void;
  onBlur?: (e: React.ChangeEvent<unknown>) => void;
  defaultPhoneRegionCode: string;
};

export const PhoneField: FC<PhoneFieldProps> = ({
  disabled = false,
  onChange,
  onBlur,
  defaultPhoneRegionCode,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleBlur, isSubmitting, values } = useFormikContext<{
    [key: string]: unknown;
    phoneRegionCode: string;
  }>();
  const [field, meta, helpers] = useField({
    name: "phone",
    id: "phone",
  });

  const isPhoneValidated = useRef(false);
  const [isValid, setValid] = useState<boolean>(undefined);
  if (isSubmitting && !isPhoneValidated.current) isPhoneValidated.current = true;

  const handlePhoneFocus = useCallback(() => {
    isPhoneValidated.current = false;
  }, []);

  const handlePhoneBlur = useCallback(
    (e, data) => {
      const phoneRegionValue = data["countryCode"];

      // local ref
      isPhoneValidated.current = true;

      values.phoneRegionCode = phoneRegionValue;

      handleBlur(e);
    },
    [values]
  );

  const handlePhoneChange = useCallback(
    (value, data, e) => {
      const phoneRegionValue = data["countryCode"];

      // formik context
      helpers.setTouched(true);
      helpers.setValue(value);

      values.phoneRegionCode = phoneRegionValue;
    },
    [values]
  );

  const validatePhone = (value, country) => {
    if (isPhoneValidated.current) {
      if (meta.error) {
        setValid(false);
        return meta.error;
      } else if (value.length < 4) {
        helpers.setError("Too short!");
        setValid(false);
        return "Too short!";
      } else if (!country.countryCode) {
        helpers.setError("Start mobile number with country code");
        setValid(false);
      } else if (country.countryCode?.length < 1) {
        helpers.setError("Invalid country code");
        setValid(false);
        return "Invalid country code!";
      } else {
        setValid(true);
        return true;
      }
    }
    return true;
  };

  return (
    <div
      sx={{
        marginBottom: "20px",
      }}
    >
      <PhoneInput
        phoneInputProps={{
          onChange: handlePhoneChange,
          onFocus: handlePhoneFocus,
          onBlur: handlePhoneBlur,
          country: values.phoneRegionCode,
          value: field.value,
          enableAreaCodeStretch: true,
          enableAreaCodes: true,
          enableLongNumbers: true,
          disabled,
          defaultMask: ".. ... .... .... ....",
          alwaysDefaultMask: true,
          isValid: validatePhone,
        }}
        type="tel"
        label="Mobile Number"
        name={field.name}
        error={!isValid && isValid !== undefined && isPhoneValidated.current}
        success={isValid && isPhoneValidated.current}
        required
      />
    </div>
  );
};

export default PhoneField;
