import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

const ENV = window.__env__.NODE_ENV;
const SENTRY_DSN = window.__env__.SENTRY_DSN;

if (ENV === "production" || ENV === "staging") {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
