import { useInfiniteQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toAllCamelCase } from "utils";
import { showAlert } from "@swvl/alert";
import { FilterMap } from "utils/useFilters";
import { useState } from "react";

const SHIFT_LIST_PAGE_SIZE = 5;

type ShiftModel = {
  id: string;
  businessProfiles: string[];
  corporate: string;
  endDate: string;
  startDate: string;
  isActive: boolean;
  officeLocation: {
    id: string;
    name: string;
  };
  time: number;
  weekdays: number[];
  direction: "to_office" | "from_office";
  completed: boolean;
  assignedEmployees: number;
};

export type ShiftsListModel = {
  count: number;
  shifts: ShiftModel[];
};

interface ShiftsList {
  _id: string;
  business_profiles: string[];
  corporate: string;
  end_date: string;
  start_date: string;
  is_active: boolean;
  office_location: {
    id: string;
    name: string;
  };
  time: number;
  weekdays: number[];
  direction: "to_office" | "from_office";
  completed: boolean;
  assigned_employees: number;
}

type ShiftsResponse = {
  type?: string;
  total: number;
  hits: ShiftsList[];
};

export type PaginatedShiftsListModel = {
  data: ShiftsListModel;
  nextPage: number;
};

type Params = {
  page?: number;
  limit?: number;
  enabled: boolean;
  filters?: FilterMap;
};

const transformResponse = (response: ShiftsResponse): ShiftsListModel => {
  const data = {
    count: response.total,
    shifts: response.hits.map((shift) => toAllCamelCase(shift)),
  };
  return data;
};

const fetchShifts = async ({
  page,
  limit = SHIFT_LIST_PAGE_SIZE,
  filters,
}: Params): Promise<PaginatedShiftsListModel> => {
  const response = await apiInstance.get<AxiosResponse, ShiftsResponse>("shifts", {
    params: {
      page,
      limit,
      ...filters,
    },
  });

  const transformedData = transformResponse(response);
  return {
    data: transformedData,
    nextPage: response.total > page * SHIFT_LIST_PAGE_SIZE ? page + 1 : undefined,
  };
};

const usePaginatedShifts = (params?: Params) => {
  const query = useInfiniteQuery(
    ["shifts", params],
    ({ pageParam = 1 }) => {
      return fetchShifts({ ...params, page: pageParam });
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: params.enabled,
      onError: (response) => {
        showAlert({
          message: (response as AxiosResponse)?.data?.message,
          type: "error",
          id: "alert-error",
        });
      },
    }
  );

  return {
    ...query,
    data: (query.data
      ? {
          count: query.data.pages[0].data.count,
          shifts: query.data.pages.flatMap((list) => list && list.data.shifts),
        }
      : {
          count: 0,
          shifts: [],
        }) as ShiftsListModel,
  };
};

export { usePaginatedShifts, fetchShifts, transformResponse };
