import { useMutation } from "react-query";
import apiInstance from "api";

interface UnassignEmployeesFromShiftRequest {
  business_profile_ids: string[];
  shift_ids: string[];
}

const UnassignEmployeesFromShift = async ({
  business_profile_ids,
  shift_ids,
}: UnassignEmployeesFromShiftRequest): Promise<string> => {
  const response = await apiInstance.put<UnassignEmployeesFromShiftRequest, string>(
    "shifts/unassign",
    {
      business_profile_ids,
      shift_ids,
    }
  );
  return response;
};

const useUnassignEmployeesFromShift = () => {
  const mutation = useMutation(UnassignEmployeesFromShift, {
    mutationKey: "employee/shift/unassign",
  });
  return mutation;
};

export { useUnassignEmployeesFromShift };
