/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import { StationModel } from "resources/useRideDetails";
import Timeline, { TimelineElement } from "@swvl/timeline";

type RouteDetailsProp = {
  stations: StationModel[];
};

const RouteDetails: FC<RouteDetailsProp> = ({ stations }) => {
  return (
    <section
      sx={{
        ml: 4,
        maxHeight: "180px",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <div sx={{ position: "sticky", top: 0, py: 3, background: "white", zIndex: 1 }}>
        <Text
          variant="body-large"
          sx={{
            fontWeight: "bold",
          }}
        >
          Route Details
        </Text>
      </div>

      <Timeline variant="hollow" rowsSpacing="15px">
        {stations.map((station, index) => {
          const shouldHighlightStation = index === 0 || index === stations.length - 1;
          return (
            <TimelineElement
              key={station.id}
              time={station.time}
              highlightTime={shouldHighlightStation}
              color={index >= Math.floor(stations.length / 2) ? "secondary" : "primary"}
              style={{
                time: {
                  fontSize: "11px",
                },
              }}
            >
              <Text
                sx={{
                  fontWeight: shouldHighlightStation ? "bold" : "normal",
                  fontSize: "13px",
                  display: "inline-block",
                  width: "150px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {station.name}
              </Text>
            </TimelineElement>
          );
        })}
      </Timeline>
    </section>
  );
};

export default RouteDetails;
