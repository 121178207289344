/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useState, useEffect, useMemo } from "react";
import Input from "@swvl/input";
import { IconButton } from "@swvl/button";
import { useLocation } from "react-router-dom";
import Icon from "@swvl/icon";
import { Select } from "@swvl/select";
import isNil from "lodash/fp/isNil";
import { SEARCH_DEBOUNCE_TIME } from "constants/employees";
import { PRIVATE_REQUEST_STATUS_OPTIONS } from "constants/privateRides";
import { FilterMap } from "utils/useFilters";
import { useDebounce } from "utils/useDebounce";

type FiltersProp = {
  onFilterChange: (key: string) => (filter: { [key: string]: string }) => void;
  initialFilters: FilterMap;
};

const Filters: FC<FiltersProp> = ({ onFilterChange, initialFilters }) => {
  const [searchText, setSearchText] = useState("");

  const handleDebouncedChange = () => {
    const text = searchText.length >= 3 ? searchText : undefined;
    if (text) {
      onFilterChange("query")({ value: text });
    } else {
      onFilterChange("query")({ value: null });
    }
  };

  useDebounce(handleDebouncedChange, SEARCH_DEBOUNCE_TIME, [searchText]);

  const initialCorporateStatusFilter = useMemo(() => {
    const selectedOption = PRIVATE_REQUEST_STATUS_OPTIONS.find(
      (option) => option.value === initialFilters["corporate_status"]
    );
    if (!isNil(selectedOption.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters]);

  const initialOperatorStatusFilter = useMemo(() => {
    const selectedOption = PRIVATE_REQUEST_STATUS_OPTIONS.find(
      (option) => option.value === initialFilters["operator_status"]
    );
    if (!isNil(selectedOption.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters]);

  return (
    <div
      sx={{
        height: "64px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          pl: "20px",
          width: "30%",
        }}
      >
        <Input
          css={{ fontSize: "14px" }}
          role="searchbox"
          startEnhancer={<Icon name="search" size={15} fill={"rgba(0, 0, 0, 0.5)"} />}
          endEnhancer={
            searchText.length > 0 ? (
              <IconButton
                variant="default"
                onClick={() => {
                  setSearchText("");
                  onFilterChange("query")({ value: null });
                }}
                css={{ transform: "rotate(45deg)" }}
                title="close"
                icon={<Icon name="add" size={15} fill={"rgba(0, 0, 0, 0.4)"} />}
              />
            ) : null
          }
          placeholder="Employee name, mobile number, employee ID"
          variant="plain"
          height="small"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 5}%`,
        }}
      >
        <Text sx={{ mr: 20 }} variant={"body-small"} color="rgba(1,1,1,0.6)">
          Corporate Status
        </Text>
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={PRIVATE_REQUEST_STATUS_OPTIONS}
          label={""}
          value={initialCorporateStatusFilter}
          placeholder={"Corporate Status"}
          name="corporate_status"
          handleChange={(value) => {
            onFilterChange("corporate_status")(value);
          }}
        />
      </div>

      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 5}%`,
        }}
      >
        <Text sx={{ mr: 20 }} variant={"body-small"} color="rgba(1,1,1,0.6)">
          Operator Status
        </Text>
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={PRIVATE_REQUEST_STATUS_OPTIONS}
          label={""}
          value={initialOperatorStatusFilter}
          placeholder={"Operator Status"}
          name="operator_status"
          handleChange={(value) => {
            onFilterChange("operator_status")(value);
          }}
        />
      </div>
    </div>
  );
};

export default Filters;
