/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Flex, Divider } from "theme-ui";
import { FC, useMemo } from "react";
import map from "lodash/fp/map";
import isNil from "lodash/fp/isNil";
import Timeline, { TimelineElement } from "@swvl/timeline";
import Tag from "@swvl/tag";
import { Avatar } from "components/Avatar";
import { BOOKING_STATUS_TAG_COLOR, BOOKING_STATUS_TAG_NAME } from "constants/rideMonitoring";
import { BookingsListModel, BookingModel } from "resources/useRideBookings";

type BookingsListProps = {
  bookingsList: BookingsListModel;
};

const BookingCard: FC<BookingModel> = ({ ...props }) => {
  const { user, dropOff, pickUp, status, id } = props;
  return (
    <div sx={{ position: "relative", mt: 1 }} key={id}>
      <div
        sx={{
          position: "absolute",
          top: -1,
          right: "15px",
        }}
      >
        <Tag size="small" variant={BOOKING_STATUS_TAG_COLOR[status]}>
          <span sx={{ fontWeight: "bold" }}>{BOOKING_STATUS_TAG_NAME[status]}</span>
        </Tag>
      </div>
      <div
        sx={{
          position: "absolute",
          width: 32,
          height: 32,
          fontSize: 1,
          top: "-3px",
          left: "15px",
        }}
      >
        <Avatar name={user.name} />
      </div>
      <div sx={{ ml: "60px" }}>
        <Text
          variant="body-small"
          sx={{ width: "150px", wordWrap: "break-word", fontWeight: "bold" }}
        >
          {user.name}
        </Text>
        <Timeline sx={{ m: "10px 0" }} variant={"hollow"} rowsSpacing="7px">
          <TimelineElement color="primary">{pickUp.address}</TimelineElement>
          <TimelineElement color="secondary">{dropOff.address}</TimelineElement>
        </Timeline>
      </div>
      <Divider sx={{ color: "gainsboro", marginTop: 3 }} />
    </div>
  );
};

const BookingsList: FC<BookingsListProps> = ({ bookingsList }) => {
  const renderBookingsList = useMemo<JSX.Element[]>(() => {
    return map<BookingModel, JSX.Element>((booking) => {
      return !isNil(booking.user) ? <BookingCard key={booking.id} {...booking} /> : null;
    })(bookingsList);
  }, [bookingsList]);

  // TODO: this is temporary for just corporates with no real bookings
  const hasBookingsData = useMemo(() => {
    return isNil(bookingsList)
      ? false
      : bookingsList.some(
          (booking) => !isNil(booking.user) || !isNil(booking.pickUp) || !isNil(booking.dropOff)
        );
  }, [bookingsList]);

  return (
    <section
      sx={{
        mt: 3,
        // height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <div sx={{ position: "sticky", top: 0, py: 3, background: "white", zIndex: 1 }}>
        <Text
          variant="body-large"
          sx={{
            fontWeight: "bold",
            ml: 4,
          }}
        >
          Assigned Employees
        </Text>
      </div>
      {hasBookingsData ? (
        renderBookingsList
      ) : (
        <Flex sx={{ flexDirection: "column", rowGap: "8px", textAlign: "center", padding: 3 }}>
          <Text variant="headline" sx={{ fontWeight: "bold" }}>
            No Bookings Data
          </Text>
          <Text variant="body-large">Bookings details are not available at the moment</Text>
        </Flex>
      )}
    </section>
  );
};

export default BookingsList;
