import { ThemeProvider } from "theme-ui";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import theme from "@swvl/theme";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Error from "components/Error";
import Main from "./pages";
import Auth from "./pages/auth";
import { reactPlugin } from "./appInsights";
import AuthProvider from "components/AuthProvider";
import AuthControlWrapper from "components/AuthControlWrapper";

function App() {
  const queryClient = new QueryClient();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ErrorBoundary FallbackComponent={Error}>
        <Sentry.ErrorBoundary fallback={Error}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <ReactQueryDevtools initialIsOpen={false} />
              <Router>
                <AuthProvider>
                  <Switch>
                    <Route key="Auth" path="/auth" component={Auth} />
                    <AuthControlWrapper>
                      <Route key="Main" path="/" component={Main} />
                    </AuthControlWrapper>
                  </Switch>
                </AuthProvider>
              </Router>
            </ThemeProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </ErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default App;
