import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";
import { toCamelCase } from "utils";
import { EMPLOYEES_LIST_PAGE_SIZE } from "constants/employees";
import { FilterMap } from "utils/useFilters";

interface BaseEmployeeModel extends Record<string, unknown> {
  id: string;
  name: string;
  phone: string;
}

type Params = {
  page?: number;
  limit?: number;
  sort?: string;
  filters?: FilterMap;
};
export interface EmployeeModel extends BaseEmployeeModel {
  email: string;
  corporate: string;
  active: boolean;
  employeeId: string;
  pickupAddress: string;
  createdAt: Date;
  shiftAssigned: boolean;
}

export type EmployeesListModel = {
  count: number;
  employees: EmployeeModel[];
};

interface EmployeeResponseModel {
  _id: string;
  name: string;
  email: string;
  corporate: string;
  employee_id: string;
  pickup_address: string;
  active: boolean;
  createdAt: string;
  shift_assigned: boolean;
}

type EmployeesResponse = {
  type?: string;
  total: number;
  hits: EmployeeResponseModel[];
};

const transformResponse = (response: EmployeesResponse): EmployeesListModel => {
  const data = {
    count: response.total,
    employees: response.hits.map((item) => toCamelCase(item) as EmployeeModel),
  };
  return data;
};

const fetchEmployees = async ({
  page,
  limit,
  filters,
  sort,
}: Params): Promise<EmployeesListModel> => {
  const response = await apiInstance.get<AxiosResponse, EmployeesResponse>("user", {
    params: {
      page,
      limit,
      sort,
      ...filters,
    },
  });

  return transformResponse(response);
};

const useEmployees = ({ page, filters }: { page: number; filters?: FilterMap }) => {
  const query = useQuery(
    ["employees", page, filters],
    () =>
      fetchEmployees({
        page,
        limit: EMPLOYEES_LIST_PAGE_SIZE,
        filters,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (response) => {
        showAlert({
          message: (response as AxiosResponse)?.data?.message,
          type: "error",
          id: "alert-error",
        });
      },
    }
  );
  return query;
};

export { useEmployees, fetchEmployees, transformResponse };
