/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import Table from "@swvl/table";
import Page from "components/PageWrapper";
import isNil from "lodash/fp/isNil";
import { FC, memo, useState, useMemo, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { addToQuery, checkQuery, getFromQuery, removeFromQuery } from "utils/";
import NoResults from "components/NoListResults";
import { SHIFTS_LIST_PAGE_SIZE } from "constants/shifts";
import columns from "./ListColumns";
import Header from "./ListHeader";
import Filters from "./ListFilters";
import Shift from "./Shift";
import ActivationPopup from "./ActivateShiftPopup";
import { useShifts } from "resources/useShifts";
import { useEditShiftActivationStatus } from "resources/useEditShiftActivationStatus";
import { useFilters } from "utils/useFilters";

export type PopupData = { id: string; value: boolean; title?: string };
const ShiftsList: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currPage = getFromQuery("page", location.search);
  const [popupData, setPopupData] = useState<PopupData>(null);
  const [page, setPage] = useState<number>(currPage ? +currPage : 1);

  const filters = useFilters(
    ["start_date", "end_date", "date", "time", "status", "direction"],
    true
  );

  const handleFilterChange = useCallback(
    (key: string, filter: { value: string; label?: string }) => {
      if (key === "date" && !isNil(filter.value)) {
        filters.apply({
          start_date: null,
          end_date: null,
        });
      }

      if ((key === "start_date" || key === "end_date") && !isNil(filter.value)) {
        filters.apply({ date: null });
      }

      filters.apply({ [key]: filter.value });
    },
    []
  );

  const { data, status: shiftsStatus, isFetching: isListLoading } = useShifts({
    page,
    filters: filters.current,
  });
  const {
    mutate: editShiftStatus,
    isLoading: isEditShiftStatusLoading,
  } = useEditShiftActivationStatus({
    page,
    filters: filters.current,
  });

  const shiftsList = data ?? { count: 0, shifts: [] };
  const shiftId = useMemo(() => {
    if (checkQuery("shift-id", location.search)) {
      return getFromQuery("shift-id", location.search);
    } else {
      return null;
    }
  }, [location.search]);

  const getTrProps = useCallback((props, original) => {
    const { id, isActive } = original;
    return {
      ...props,
      onClick: (e) => {
        e.preventDefault();
        addToQuery("shift-id", id, history);
      },
      sx: {
        "&[role][class]": {
          cursor: "pointer",
          backgroundColor: !isActive ? "snow" : "",
          transition: "background-color .15s",
          "&:hover": {
            backgroundColor: "gainsboro",
            cursor: "pointer",
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    addToQuery("page", page.toString(), history);
  }, [page]);

  return (
    <Page
      tagName="shifts-page"
      title="Shifts | List"
      trackName="Shifts.List.Page"
      header={<Header isLoading={isListLoading || isEditShiftStatusLoading} />}
    >
      {!isNil(shiftId) ? (
        <Shift id={shiftId} currentShiftsPage={page} currentFilters={filters.current} />
      ) : null}
      <Filters onFilterChange={handleFilterChange} initialFilters={filters.current} />
      <Table
        getTrProps={getTrProps}
        useTablePagination
        useControlledPagination
        pageSize={SHIFTS_LIST_PAGE_SIZE}
        totalCount={shiftsList?.count}
        data={shiftsList?.shifts}
        columns={columns(setPopupData)}
        onPageChange={(pageIndex) => setPage(pageIndex + 1)}
        pageIndex={page - 1}
        isLoading={shiftsStatus === "loading"}
        noDataComponent={
          <NoResults
            css={{ width: 600, margin: "0 auto" }}
            title="No Results Found"
            body="There are no shifts yet."
          />
        }
        useZebraStyles
        useFullWidth
        useSameSizeCells
      />
      <ActivationPopup
        data={popupData}
        onApprove={() => {
          const { id, value } = popupData;
          editShiftStatus({ id, isActive: !value });
          setPopupData(null);
        }}
        onDismiss={() => {
          setPopupData(null);
        }}
      />
    </Page>
  );
};

export default memo(ShiftsList);
