import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import join from "lodash/fp/join";
import { useHistory } from "react-router-dom";
import { getFromQuery, toSnakeCase } from "utils";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { AxiosResponse } from "axios";

type DeleteEmployeesRequestModel = {
  ids?: string[];
  deleteAll?: boolean;
};

type DeleteEmployeesRequest = {
  ids?: string[];
  delete_all?: boolean;
};

type DeleteEmployeesResponse = {
  message: string;
};

type DeleteEmployeesError = {
  data: {
    error: string;
    message: string;
    stack: string;
    statusCode: number;
  };
};

const transformRequest = (data: DeleteEmployeesRequestModel): DeleteEmployeesRequest => {
  return toSnakeCase(data);
};

const deleteEmployees = async (
  data: DeleteEmployeesRequestModel
): Promise<DeleteEmployeesResponse> => {
  const requestData = transformRequest(data);
  const response: DeleteEmployeesResponse = await apiInstance.delete(`profiles`, {
    data: { ids: data.ids },
    params: {
      delete_all: requestData.delete_all ? true : null,
    },
  });
  return response;
};

const useDeleteEmployees = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = history.location;
  const mutation = useMutation<
    DeleteEmployeesResponse,
    DeleteEmployeesError,
    DeleteEmployeesRequestModel,
    unknown
  >(deleteEmployees, {
    mutationKey: "employees/delete",
    onSuccess: (response) => {
      const page = getFromQuery("page", location.search);
      queryClient.invalidateQueries(["employees", +page]);
      showAlert({
        message: response?.message,
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error?.data.message || "Something went wrong, employee/s can't be deleted",
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useDeleteEmployees, deleteEmployees };
