import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { isNil } from "lodash";
import apiInstance from "api";
import { toAllCamelCase, constants } from "utils";

export type SeatsModel = { count: number; maximum: number };

export type StationStatus = "coming" | "past" | "skipped";

export type StationAnalyticsModel = {
  arrivalTime?: string;
  bookingsDropOffCount: number;
  bookingsPickUpCount: number;
  departureTime?: string;
  waitingTime?: number;
};

export type StationModel = {
  id: string;
  name: string;
  lat: number;
  lng: number;
  time: string;
  status: StationStatus;
  analytics: StationAnalyticsModel;
  estimatedAnalytics: StationAnalyticsModel;
};

export type CaptainModel = {
  id: string;
  name: string;
  phone: string;
  phoneRegionCode: string;
  picture: string;
  ratingData: {
    sum: number;
    count: number;
  };
};

export type BusTypeModel = {
  id: string;
  seats: number;
  maxSeats: number;
  make: string;
  model: string;
};

export type RouteModel = {
  polyline: string;
  routeType: string;
  id: string;
};

export type BusModel = {
  id: string;
  color: string;
  plates: string;
};

export type RideStatus =
  | "started"
  | "completed"
  | "scheduled"
  | "cancelled"
  | "stopped"
  | "corporate_revised";

export type RideDetailsModel = {
  id: string;
  status: RideStatus;
  startDate: Date;
  predictedEndDate: Date;
  seats: SeatsModel;
  stations: StationModel[];
  captain: CaptainModel;
  busType: BusTypeModel;
  route: RouteModel;
  bus: BusModel;
  analytics?: {
    distance: number;
    duration: number;
    polyline?: string;
  };
  estimatedAnalytics?: {
    distance: number;
    duration: number;
  };
};

export type StationAnalyticsResponse = {
  arrival_time?: string;
  bookings_drop_off_count: number;
  bookings_pick_up_count: number;
  departure_time?: string;
  waiting_time?: number;
};

export type RideDetailsResponse = {
  _id: string;
  status: RideStatus;
  ride_type: string;
  date: string;
  predicted_end_date: string;
  bookings: { count: number; maximum: number };
  captain: {
    _id: string;
    name: string;
    phone: string;
    phone_region_code: string;
    rating_date: {
      sum: number;
      count: number;
    };
  };
  stations_data: {
    station: { _id: string; name: string; loc: { coordinates: number[] } };
    status?: string;
    analytics?: StationAnalyticsResponse;
    estimated_analytics?: StationAnalyticsResponse;
  }[];
  schedule: string[];
  route: {
    polyline: string;
    route_type: string;
    _id: string;
  };
  analytics?: {
    distance: number;
    duration: number;
    polyline?: string;
  };
  estimated_analytics?: {
    distance: number;
    duration: number;
  };
};

const transformResponse = ({
  stations_data,
  schedule,
  ...ride
}: RideDetailsResponse): RideDetailsModel => {
  const stations = stations_data.map(({ station: { _id, name, loc }, ...rest }, index) => {
    const time =
      !isNil(schedule) && schedule.length > 0
        ? format(new Date(schedule[index]), constants.timeFormat)
        : null;
    return toAllCamelCase({
      _id,
      name,
      time,
      lng: loc?.coordinates[0],
      lat: loc?.coordinates[1],
      ...rest,
    });
  });

  return { ...toAllCamelCase(ride), stations } as RideDetailsModel;
};

const fetchRideDetails = async ({ id }: { id: string }): Promise<RideDetailsModel> => {
  const response = await apiInstance.get<AxiosResponse, RideDetailsResponse>(`rides/${id}`);

  return transformResponse(response);
};

const useRideDetails = ({ id }: { id: string }) => {
  return useQuery(["rides/details", id], () => fetchRideDetails({ id }), {
    enabled: !isNil(id),
    refetchOnWindowFocus: false,
    initialData: {} as RideDetailsModel,
  });
};
export { useRideDetails, fetchRideDetails, transformResponse };
