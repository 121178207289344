import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toAllCamelCase } from "utils";
import { isNil } from "lodash";

export type BookingModel = {
  id: string;
  status: string;
  pickUp: {
    id: string;
    name: string;
    nameAr: string;
    address: string;
    addressAr: string;
  };
  dropOff: {
    id: string;
    name: string;
    nameAr: string;
    address: string;
    addressAr: string;
  };
  user: {
    id: string;
    name: string;
  };
};

export type BookingsListModel = BookingModel[];

export type BookingResponse = {
  _id: string;
  status: string;
  pick_up: {
    _id: string;
    name: string;
    name_ar: string;
    address: string;
    address_ar: string;
  };
  drop_off: {
    _id: string;
    name: string;
    name_ar: string;
    address: string;
    address_ar: string;
  };
  user: {
    _id: string;
    name: string;
  };
};

export type BookingsListResponse = {
  bookings: BookingResponse[];
};

const transformResponse = (response: BookingsListResponse): BookingsListModel => {
  return toAllCamelCase(response.bookings) as BookingsListModel;
};

const fetchRideBookings = async ({ id }: { id: string }): Promise<BookingsListModel> => {
  const response = await apiInstance.get<AxiosResponse, BookingsListResponse>(
    `rides/${id}/bookings`
  );

  return transformResponse(response);
};

const useRideBookings = ({ id }: { id: string }) => {
  return useQuery(["rides/bookings", id], () => fetchRideBookings({ id }), {
    enabled: !isNil(id),
    refetchOnWindowFocus: false,
  });
};
export { useRideBookings, fetchRideBookings, transformResponse };
