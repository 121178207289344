/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, InputProps } from "theme-ui";
import { useState, FC, useRef } from "react";
import { useField, useFormikContext } from "formik";
import Input from "@swvl/input";

interface FieldProps extends InputProps {
  helpText?: string;
  name: string;
  label?: string;
}

export const Field: FC<FieldProps> = ({ label, helpText, ref, ...props }) => {
  const [field, meta] = useField(props);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleBlur, isSubmitting } = useFormikContext();
  const validated = useRef(false);
  const [isFocused, setFocused] = useState(false);
  const showFeedback = (isFocused && field.value?.trim().length > 2) || meta.touched;
  const isValid = showFeedback ? (meta.error ? false : true) : undefined;

  if (isSubmitting && !validated.current) validated.current = true;

  return (
    <div
      sx={{
        marginBottom: "20px",
      }}
    >
      <Input
        {...field}
        {...props}
        label={label}
        variant="bordered"
        height="default"
        error={meta.error && validated.current}
        success={isValid}
        aria-describedby={`${props.id}-feedback ${props.id}-help`}
        onFocus={(e) => {
          setFocused(true);
          validated.current = false;
        }}
        onBlur={(e) => {
          validated.current = true;
          handleBlur(e);
        }}
      />
      <div id={`${props.id}-help`} data-testid={`${props.id}-help`} tab-index="-1">
        {meta.error && validated.current && (
          <Text sx={{ marginTop: "6px", marginLeft: "5px", color: "crimson" }}>{meta.error}</Text>
        )}
        {(!meta.error || isFocused) && (
          <Text sx={{ marginTop: "6px", marginLeft: "5px" }}>{helpText}</Text>
        )}
      </div>
    </div>
  );
};

export default Field;
