import { useCallback, useMemo, useState } from "react";

export type OfficeLocation = {
  name: string;
  id: string;
};

export type Shift = {
  direction: "to_office" | "from_office";
  endDate: string;
  isActive: boolean;
  officeLocation: OfficeLocation;
  startDate: string;
  time: number;
  weekdays: number[];
  id: string;
};
export type ShiftConflicts = {
  employeeName: string;
  employeeId: string;
  existingShifts: Shift[];
}[];

const useShiftConflicts = (shiftConflicts: ShiftConflicts) => {
  const [resolvedEmployees, setResolvedEmployees] = useState<{ [id: string]: boolean }>(() =>
    shiftConflicts.reduce((acc, curr) => ({ ...acc, [curr.employeeId]: false }), {})
  );
  const resolveEmployee = useCallback(
    (employeeId: string, isAssigned: boolean) => {
      setResolvedEmployees({
        ...resolvedEmployees,
        [employeeId]: isAssigned,
      });
    },
    [resolvedEmployees]
  );

  const resolveAllEmployees = useCallback(
    (isAssigned: boolean) => {
      setResolvedEmployees(
        Object.keys(resolvedEmployees).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: isAssigned,
          }),
          {}
        )
      );
    },
    [resolvedEmployees]
  );

  const isAllEmployeesAssigned = useMemo(
    () => Object.values(resolvedEmployees).every((isAssigned) => isAssigned),
    [resolvedEmployees]
  );
  const isAllEmployeesUnAssigned = useMemo(
    () => Object.values(resolvedEmployees).every((isAssigned) => !isAssigned),
    [resolvedEmployees]
  );

  return {
    resolvedEmployees,
    assignEmployee: (id: string) => resolveEmployee(id, true),
    unAssignEmployee: (id: string) => resolveEmployee(id, false),
    assignAllEmployees: () => resolveAllEmployees(true),
    unAssignAllEmployees: () => resolveAllEmployees(false),
    isAllEmployeesAssigned,
    isAllEmployeesUnAssigned,
  };
};

export default useShiftConflicts;
