/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from "react";
import { jsx } from "theme-ui";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type DeleteShiftProps = {
  isOpen: boolean;
  id: string;
  title: string;
  onApprove: () => void;
  onDismiss: () => void;
};

const DeleteShiftPopup: FC<DeleteShiftProps> = (props) => {
  const { isOpen, id, title, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={isOpen}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Remove Shift"
      message={
        <p sx={{ color: "opacity.black-54" }}>
          You will proceed to remove shift
          <p sx={{ mt: 1, fontWeight: "bold" }}>{title}</p>
        </p>
      }
      confirmButtonText="Remove shift"
      trackingParams={{
        actionType: "remove shift",
        resourceType: "shift",
        resourceId: id,
      }}
    />
  );
};

export default DeleteShiftPopup;
