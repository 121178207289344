/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, FC } from "react";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { NAV_HEIGHT, HEADER_HEIGHT } from "constants/styles";

type PageProps = {
  title?: string;
  header?: JSX.Element;
  trackName?: string;
  tagName?: string;
};

export const Page: FC<PageProps> = (props) => {
  const { title, header, tagName = "div", children, trackName } = props;
  const Tag = tagName as keyof JSX.IntrinsicElements;
  const appInsights = useAppInsightsContext();
  const trackPage = useTrackMetric(appInsights, trackName);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    trackPage();
  }, [trackName]);

  return (
    <Tag>
      {header && (
        <header
          sx={{
            position: "sticky",
            top: NAV_HEIGHT,
            backgroundColor: "#fff",
            boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.08)",
            paddingLeft: "15px",
            paddingRight: "10px",
            height: HEADER_HEIGHT,
            zIndex: 1,
          }}
        >
          {header}
        </header>
      )}
      {children}
    </Tag>
  );
};

export default Page;
