import { Route, useRouteMatch, Switch } from "react-router-dom";
import Rides from "./Rides";

export default function RideMonitoring(props: unknown) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route key="Rides" path={path} component={Rides} />
    </Switch>
  );
}
