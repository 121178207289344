import { useMutation } from "react-query";
import { toSnakeCase } from "utils";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

export type Direction = "to_office" | "from_office";

export interface CreateShiftRequest {
  start_date: string;
  end_date: string;
  direction: Direction;
  time: number;
  weekdays: number[];
  office_location: string;
}

export interface CreateShiftResponse {
  _id: string;
  end_date: string;
  start_date: string;
  direction: "to_office" | "from_office";
  time: number;
  weekdays: number[];
  office_location: {
    id: string;
    name: string;
  };
}

export interface CreateShiftFormData {
  startDate: string;
  endDate: string;
  direction: Direction;
  time: number;
  weekdays: number[];
  officeLocation: string;
}

// Transforms
const transformRequest = (form: CreateShiftFormData): CreateShiftRequest =>
  toSnakeCase(form) as CreateShiftRequest;

// Resources
const CreateShift = async (form: CreateShiftFormData) => {
  const data = transformRequest(form);
  const response = await apiInstance.post<CreateShiftRequest, CreateShiftResponse>("shifts", data);
  return response;
};

const useCreateShift = () => {
  const mutation = useMutation(CreateShift, {
    mutationKey: "shift/create",
    onSuccess: () => {
      showAlert({
        message: "You have added a new shift successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useCreateShift, CreateShift };
