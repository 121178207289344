import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";

// Types
interface ResetPasswordRequest {
  token: string;
  password: string;
  confirm: string;
}

// Resources
const resetPassword = async (data: ResetPasswordRequest) => {
  const response = await apiInstance.post<ResetPasswordRequest, {}>("password/reset", data);

  return response;
};

const useResetPassword = () => {
  const history = useHistory();
  const validationErrorMessage = "Oops, we can't proceed with your request.";
  const mutation = useMutation(resetPassword, {
    mutationKey: "user/reset-passwrod",
    onSuccess: () => {
      history.push(`/auth/login`);
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return {
    ...mutation,
    validationErrorMessage,
  };
};

export { useResetPassword };
