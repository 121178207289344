/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import Spinner from "@swvl/spinner";
import Button from "@swvl/button";

import Icon from "@swvl/icon";
export const Header = (submit) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: 700 }}>
        Add New Private Ride
      </Text>
    </div>
  );
};

export default Header;
