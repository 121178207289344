/** @jsxRuntime classic */
/** @jsx jsx */
import { Text, jsx } from "theme-ui";
import React, { FC, useState, useEffect } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";
import { ConfirmationModal } from "components/ConfirmationModal";
import { Select } from "@swvl/select";
import Input from "@swvl/input";
import { debounce } from "lodash";
type PopupData = { data: unknown; userName?: string };

type RejectRequestProps = {
  data: PopupData;
  onConfirm: (note) => void;
  onDismiss: () => void;
  testId: string;
  setReason: (reason) => void;
  onSuccess: boolean;
  onError: boolean;
};

type RejectionReasonOption = {
  label: string;
  value: string;
};

const rejectionReasonsOptions: RejectionReasonOption[] = [
  {
    label: "The request is not needed anymore",
    value: "The request is not needed anymore",
  },
  {
    label: "Suggested ride price rejected",
    value: "Suggested ride price rejected",
  },
  {
    label: "Duplicated user request",
    value: "Duplicated user request",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const NoteInput = (props) => {
  return (
    <Input
      key="note"
      name="note"
      label="Please Specify"
      onChange={props.onChange}
      placeholder="Add Reason"
      value={props.value}
    />
  );
};
const RejectRequestPopup: FC<RejectRequestProps> = (props) => {
  const { data, onConfirm, onDismiss, testId, setReason, onSuccess, onError } = props;
  const [step, setStep] = useState<number>(1);
  const [selectedReason, setSelectedReason] = useState<RejectionReasonOption>(undefined);
  const [note, setScopeNote] = useState<string>(undefined);
  const handleNote = (e) => {
    e.preventDefault();
    setScopeNote(e.target.value);
  };

  useEffect(() => {
    if (data === null) {
      setScopeNote(undefined);

      setSelectedReason(undefined);
      setStep(1);
    }
  }, [data]);

  const handleSetSelectedReason = (reason) => {
    setSelectedReason(reason);
    if (reason !== "Other") {
      setScopeNote(undefined);
    }
    setReason(reason);
  };
  return (
    <React.Fragment>
      {step == 1 ? (
        <ConfirmationPopup
          isOpen={!isNil(data)}
          onApprove={() => {
            setStep(2);
          }}
          testId={testId}
          onDismiss={() => {
            onDismiss();
            setSelectedReason(undefined);
            setScopeNote(undefined);
            setStep(1);
          }}
          title="Reject Private Ride Request"
          message={
            <Text variant={"body-small"}>
              You will proceed to reject a private ride request for{" "}
              <span sx={{ fontWeight: "bold" }}>{data?.userName}</span>{" "}
            </Text>
          }
          confirmButtonText="Reject Private Ride Request"
        />
      ) : (
        <ConfirmationModal
          key="rejection"
          isOpen={!isNil(data)}
          onApprove={() => {
            onConfirm(note);
          }}
          testId={testId}
          confirmDisabled={!selectedReason || (selectedReason.value === "Other" && !note)}
          onDismiss={() => {
            onDismiss();
            setSelectedReason(undefined);
            setStep(1);
          }}
          title="Reasons for Rejecting Private Ride Request"
          confirmButtonText="Submit Reason"
        >
          {" "}
          <div sx={{ textAlign: "left" }}>
            <div css={{ marginBottom: selectedReason?.value === "Other" ? "20px" : "80px" }}>
              <Select
                name="reason"
                options={rejectionReasonsOptions}
                handleChange={(option: RejectionReasonOption) => {
                  handleSetSelectedReason(option);
                }}
                placeholder="Select reason"
                label="Reasons for rejection"
                required
                value={selectedReason}
              />
            </div>
            {selectedReason?.value === "Other" && (
              <div css={{ marginBottom: "20px" }}>
                <NoteInput onChange={(e) => handleNote(e)} value={note} />
              </div>
            )}
          </div>
        </ConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default RejectRequestPopup;
