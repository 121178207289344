/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from "react";
import { jsx, Text } from "theme-ui";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type DeleteEmployeesProps = {
  isOpen: boolean;
  employeesCount: number;
  onApprove: () => void;
  onDismiss: () => void;
};

const DeleteEmployeesPopup: FC<DeleteEmployeesProps> = (props) => {
  const { isOpen, employeesCount, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={isOpen}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Delete Employees"
      message={<Text>We will proceed to delete all {employeesCount} employees</Text>}
      confirmButtonText="Delete Employees"
    />
  );
};

export default DeleteEmployeesPopup;
