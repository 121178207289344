import queryString from "query-string";
import { saveAs } from "file-saver";
import camelCase from "lodash/fp/camelCase";
import pickBy from "lodash/fp/pickBy";
import identity from "lodash/fp/identity";
import snakeCase from "lodash/fp/snakeCase";
import mapKeys from "lodash/fp/mapKeys";
import has from "lodash/fp/has";
import { isPast, format, formatISO, differenceInMinutes, addMinutes } from "date-fns";
import { History } from "history";
import isNil from "lodash/fp/isNil";

export const constants = {
  dateTimeFormat: "dd/MM/yyyy hh:mm a",
  dateTimeFormatIso: "yyyy-MM-dd hh:mm",
  dateFormat: "dd/MM/yyyy",
  timeFormat: "hh:mm a",
  timeFormat24: "HH:mm",
  weekDaysShort: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  weekDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  datePickerPlaceholderFormat: "E, LLL do",
  timeZoneOffset: new Date().getTimezoneOffset() * 60000,
};

export const timeFormatter = Intl.DateTimeFormat("en-us", {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZone: "UTC",
});

export const dayFormatter = Intl.DateTimeFormat("en-us", {
  weekday: "long",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  timeZone: "UTC",
});

export const isExpired = (expiry: string) => {
  const expiryDate = new Date(expiry);
  return isPast(expiryDate);
};

export const toCamelCase = (object) => {
  return mapKeys((key: string) => {
    return camelCase(key);
  })(object);
};

export const toAllCamelCase = (object) => {
  if (Array.isArray(object)) {
    return object.map((property) => toAllCamelCase(property));
  } else if (!isNil(object) && object.constructor === Object) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: toAllCamelCase(object[key]),
      }),
      {}
    );
  }
  return object;
};

export const dropNulls = (object) => {
  return pickBy(identity)(object);
};

export const toSnakeCase = (object) => {
  return mapKeys((key: string) => {
    return snakeCase(key);
  })(object);
};

export const convertMinutesToHours = (minutes: number, token: string = constants.timeFormat) => {
  const date = new Date(0).setMinutes(minutes);
  const timeZoneAwareConvertedTime = date + constants.timeZoneOffset;
  return format(timeZoneAwareConvertedTime, token);
};

export const convertAmPmTimeToMinutes = (time: string, token: string = constants.timeFormat) => {
  if (!time) return 0;
  const timeWithoutSpaces = time.split(" ").join("").toLowerCase();
  let hours = Number(timeWithoutSpaces.match(/^(\d+)/)[1]);
  const minutes = Number(timeWithoutSpaces.match(/:(\d+)/)[1]);
  const amOrPm = timeWithoutSpaces.match(/(am|pm)/)[0];
  if (amOrPm === "pm" && hours < 12) {
    hours = hours + 12;
  }
  if (amOrPm === "am" && hours === 12) {
    hours = hours - 12;
  }
  const timeInMinutes = hours * 60 + minutes;
  return timeInMinutes;
};

export const convertMinutesAmPmTime = (time: number) => {
  const hoursIn24 = Math.floor(time / 60);
  const hoursIn12 = hoursIn24 % 12 || 12;
  const mins = Math.floor(time % 60);

  const minsString = mins >= 10 ? `${mins}` : `0${mins}`;
  const hoursString = hoursIn12 >= 10 ? `${hoursIn12}` : `0${hoursIn12}`;

  const isDayTime = hoursIn24 < 12 || hoursIn24 === 24;
  const amOrPm = isDayTime ? "AM" : "PM";

  return {
    isDayTime,
    timeString: `${hoursString}:${minsString} ${amOrPm}`,
  };
};

export const minsToHours = (mins: number) => {
  if (!mins) {
    return "0 mins";
  }
  const m = Number(Math.floor(mins % 60).toFixed());
  const h = Number(Math.floor(mins / 60).toFixed());

  if (h > 0 && m > 0) {
    return `${h} hrs ${m} mins`;
  }
  if (h > 0) {
    return `${h} hrs`;
  }
  return `${m} mins`;
};

export const formatNumberToFixedFloat = (n: number, fractionDigits = 2) => {
  if (Math.round(n) !== n) {
    return n.toFixed(fractionDigits);
  }
  return n.toFixed(0);
};

export const addToQuery = (key: string, value: string | string[] | null, history: History) => {
  const { location } = history;
  const searchParams = queryString.parse(location.search);
  const pathname = location.pathname;
  searchParams[key] = value;
  const stringifiedParams = queryString.stringify(searchParams);
  history.replace({
    ...history.location,
    pathname,
    search: stringifiedParams,
  });
};

export const removeFromQuery = (key: string, history: History) => {
  const { location } = history;
  const searchParams = queryString.parse(location.search);
  const pathname = location.pathname;
  delete searchParams[key];
  const stringifiedParams = queryString.stringify(searchParams);
  history.replace({
    ...history.location,
    pathname,
    search: stringifiedParams,
  });
};

export const checkQuery = (key: string, search: string) => {
  const queryParams = queryString.parse(search);
  return has(key)(queryParams);
};

export const getFromQuery = (key: string, search: string) => {
  const queryParams = queryString.parse(search);
  return queryParams[key] as string;
};

export const getDayHoursSelectOptions = () => {
  return Array.from({ length: 24 }, (_, index) => {
    const hour = `${index % 12}`.padStart(2, "0");
    return {
      label: `${hour}:00 ${index < 12 ? "AM" : "PM"}`,
      value: index * 60,
    };
  });
};

export const getDaysSelectOptions = (days: number) => {
  return Array.from({ length: days }, (_, index) => ({
    label: `${index + 1} day${index > 0 ? "s" : ""}`,
    value: index + 1,
  }));
};

export const getIsoDate = (date: Date, time?: number) => {
  const timeZoneOffset = new Date(date).getTimezoneOffset();
  const midnightDate = new Date(date).setHours(0, timeZoneOffset * -1, 0, 0);
  const dateTimeIsoDate = formatISO(addMinutes(new Date(date), time));
  return {
    isoString: formatISO(midnightDate),
    isoDate: new Date(midnightDate),
    isoDateTime: dateTimeIsoDate,
  };
};

export const getWeekDaysList = (days: number[] = [7, 1, 2, 3, 4, 5, 6]) => {
  return days.map((day) => ({
    label: constants.weekDaysShort[day - 1],
    value: constants.weekDays[day - 1],
  }));
};

export const getWeekDaysSelected = (days: number[]) => {
  return days.map((day) => constants.weekDays[day - 1]);
};

export const getWeekDaysShort = (days: number[]) => {
  return days.map((day) => constants.weekDaysShort[day - 1]);
};

/**
 * a function to download csv file
 * @param {string} fileName to be saved file name
 * @param {string[]} data the data to be saved
 * @param {boolean} isEmpty boolean to define if downloading prefilled or just an empty template
 *
 * @returns void
 */
/** TODO: Separate data from columns, thank you <3 */
export const downloadCSV = (
  fileName: string,
  data: string[] | { [key: string]: string }[],
  isEmpty: boolean
) => {
  const newRowRegex = "\r\n";

  const columns = !isEmpty
    ? Object.keys(data[0]).join(",") + newRowRegex
    : data.join(",") + newRowRegex;

  const rows = !isEmpty
    ? (data as { [key: string]: string }[]).reduce((rows, row) => {
        return rows.concat(
          Object.values(row)
            .map((cell) => cell)
            .join(",") + newRowRegex
        );
      }, "")
    : "";

  const parsedData = columns + rows;
  const csvFile = new File([parsedData], `${fileName}.csv`);
  saveAs(csvFile);
};

export const pluralize = (count: number, text: string, suffix: string = "s"): string => {
  return `${count} ${text}${count !== 1 ? suffix : ""}`;
};

export const getStationDelayData = (actual: string, estimated: string) => {
  const estimatedDate = estimated ? new Date(estimated) : undefined;
  const actualDate = actual ? new Date(actual) : undefined;
  const estimatedTime = estimatedDate ? format(estimatedDate, constants.timeFormat) : undefined;
  const actualTime = actualDate ? format(actualDate, constants.timeFormat) : undefined;
  const delayInMinutes =
    !isNil(actualDate) && !isNil(estimatedDate)
      ? differenceInMinutes(actualDate, estimatedDate)
      : undefined;
  const formattedDelay = minsToHours(Math.abs(delayInMinutes));

  return {
    estimatedDate,
    actualDate,
    delayInMinutes,
    estimatedTime,
    actualTime,
    formattedDelay,
  };
};
