import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toAllCamelCase } from "utils";
import { isNil } from "lodash";

export type PrivateRideResponse = {
  _id: string;
  created_by: string;
  user_name: string;
  pickup_address: string;
  dropoff_address: string;
  date: Date;
  operator_status: string;
  corporate_status: string;
  bus_type: string;
  rejection_reason: {
    // this key will return only if there is a reason in the object
    reason: string;
    note?: string; // this key will be empty on reasons that doesn't contain note (only 'other' contains note for now)
  };
  additional_details: string;
};

export type PrivateRideDetails = {
  id: string;
  createdBy: string;
  userName: string;
  pickupAddress: string;
  dropoffAddress: string;
  date: Date;
  operatorStatus: string;
  corporateStatus: string;
  busType: string;
  rejectionReason: {
    // this key will return only if there is a reason in the object
    reason: string;
    note?: string; // this key will be empty on reasons that doesn't contain note (only 'other' contains note for now)
  };
  additionalDetails: string;
};

const transformResponse = (response: PrivateRideResponse): PrivateRideDetails => {
  return toAllCamelCase(response);
};

const fetchPrivateRideDetails = async ({ id }: { id: string }): Promise<PrivateRideDetails> => {
  const response = await apiInstance.get<AxiosResponse, PrivateRideResponse>(`private_rides/${id}`);

  return transformResponse(response);
};

const usePrivateRideDetails = ({ id }: { id: string }) => {
  return useQuery(["private-ride/details", id], () => fetchPrivateRideDetails({ id }), {
    enabled: !isNil(id),
    refetchOnWindowFocus: false,
  });
};
export { usePrivateRideDetails, fetchPrivateRideDetails };
