/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui";
import { FC } from "react";
import Popup from "@swvl/popup";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import { EmployeeModel } from "resources/useEmployees";
import SelectedEmployeeTag from "./SelectedEmployeeTag";
import { pluralize } from "utils";

export interface SelectedEmployeesPopupProps {
  withClose?: boolean;
  onDeselectAll: () => void;
  onDeselectEmployee: (id: string) => void;
  onDismiss: () => void;
  selectedEmployees: Record<string, EmployeeModel>;
  isOpen: boolean;
}

export const SelectedEmployeesPopup: FC<SelectedEmployeesPopupProps> = ({ children, ...props }) => {
  const {
    onDeselectAll,
    onDeselectEmployee,
    onDismiss,
    withClose,
    isOpen,
    selectedEmployees,
  } = props;
  const Content = () => (
    <div sx={{ my: 4 }} data-testid="confirmation-popup">
      <div sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
        <Icon name="completed" size={50} fill="#FFC659" />
      </div>
      <Text sx={{ mb: 3, fontWeight: "bold" }} variant={"headline"}>
        All Selected Employees
      </Text>
      <Text sx={{ mb: 3, color: "opacity.black-54" }} variant={"body-small"}>
        {pluralize(Object.keys(selectedEmployees).length, "employee")} are selected
      </Text>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          mx: 5,
          mb: 4,
          px: 4,
          py: 3,
          bg: "ghostwhite",
          flexWrap: "wrap",
          borderRadius: "5px",
          alignItems: "center",
          overflowY: "auto",
          maxHeight: "200px",
        }}
      >
        {Object.values(selectedEmployees).map((employee) => (
          <div
            key={employee.id}
            sx={{
              padding: 1,
            }}
          >
            <SelectedEmployeeTag data={employee} onDeselect={onDeselectEmployee} />
          </div>
        ))}
      </div>
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            "> *": {
              pointerEvents: "none",
            },
          }}
          onClick={onDeselectAll}
          type="button"
        >
          <Flex sx={{ justifyContent: "space-around" }}>
            <Text>Clear All Selected Employees</Text>
          </Flex>
        </Button>
      </div>
    </div>
  );

  return (
    <Popup
      withClose={withClose}
      sx={{ p: 0 }}
      closePopup={onDismiss}
      isOpen={isOpen}
      width={500}
      contentStyle={{
        maxHeight: "500px",
      }}
    >
      <Content />
    </Popup>
  );
};

export default SelectedEmployeesPopup;
