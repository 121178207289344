import { Route, useRouteMatch, Switch } from "react-router-dom";
import CreateShift from "./CreateShift";
import ShiftsList from "./ShiftsList";

export default function Shifts(props: unknown) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route key="List" exact path={`${path}`} component={ShiftsList} />
      <Route key="Create" exact path={`${path}/create`} component={CreateShift} />
    </Switch>
  );
}
