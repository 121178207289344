/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import capitalize from "lodash/fp/capitalize";
import first from "lodash/fp/first";
import last from "lodash/fp/last";

type AvatarProps = {
  name?: string;
  bg?: string;
};

export const Avatar: FC<AvatarProps> = ({ name = "", bg = "primary" }) => {
  const getInitials = (name: string) => {
    const initials = name.split(" ");
    const firstName = first(initials);
    const lastName = last(initials);
    if (initials.length > 1) return `${capitalize(first(firstName))}${capitalize(first(lastName))}`;
    return capitalize(first(firstName));
  };

  return (
    <div
      sx={{
        backgroundColor: bg,
        color: "#fff",
        width: "100%",
        height: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid #fff",
        borderRadius: "50%",
        overflow: "hidden",
      }}
    >
      <span>{getInitials(name)}</span>
    </div>
  );
};

type NameAvatarProps = {
  nameIndex?: number;
  name: string;
};

export const NameAvatar: FC<NameAvatarProps> = ({ children, ...props }) => {
  const { name, nameIndex } = props;
  const avatarColors = ["secondary", "skyblue", "mediumseagreen", "slategray"];
  return (
    <div sx={{ display: "flex", alignItems: "center" }}>
      <div sx={{ width: "32px", height: "32px", flexShrink: 0, mr: 2 }}>
        <Avatar name={name} bg={avatarColors[nameIndex % avatarColors.length]} />
      </div>
      <div sx={{ display: "flex", flexDirection: "column" }}>{children}</div>
    </div>
  );
};
