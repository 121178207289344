import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import first from "lodash/fp/first";
import last from "lodash/fp/last";
import { RIDES_LIST_LIMIT } from "constants/rideMonitoring";
import { startOfDay, endOfDay, formatISO } from "date-fns";
import TagManager from "react-gtm-module";
import { removeFromQuery, toAllCamelCase } from "utils";

export type SeatsModel = { count: number; maximum: number };

export type RideStatus =
  | "started"
  | "completed"
  | "scheduled"
  | "cancelled"
  | "stopped"
  | "corporate_revised";

export type StationStatus = "coming" | "past" | "skipped";

export type StationAnalyticsResponse = {
  arrival_time?: string;
  bookings_drop_off_count: number;
  bookings_pick_up_count: number;
  departure_time?: string;
  waiting_time?: number;
};

export type StationAnalyticsModel = {
  arrivalTime?: string;
  bookingsDropOffCount: number;
  bookingsPickUpCount: number;
  departureTime?: string;
  waitingTime?: number;
};

export type StationModel = {
  id: string;
  name: string;
  status: StationStatus;
  analytics: StationAnalyticsModel;
  estimatedAnalytics: StationAnalyticsModel;
};

export type StationResponse = {
  _id: string;
  name: string;
  status: StationStatus;
  analytics: StationAnalyticsResponse;
  estimated_analytics: StationAnalyticsResponse;
};

export type RideModel = {
  id: string;
  status: RideStatus;
  startDate: Date;
  predictedEndDate: Date;
  firstStation: string;
  stations: StationModel[];
  lastStation: string;
  seats: SeatsModel;
  routeName: string;
  routePolyline: string;
};

export type RidesListModel = {
  count: number;
  rides: RideModel[];
};

export type PaginatedRidesListModel = {
  data: RidesListModel;
  nextPage: number;
};

export type RideResponse = {
  _id: string;
  status: RideStatus;
  ride_type: string;
  date: string;
  predicted_end_date: string;
  bookings: { count: number; maximum: number };
  stations: StationResponse[];
  route_name: string;
  route_polyline: string;
};

export type RidesListResponse = {
  count: number;
  rides: RideResponse[];
};

export type Params = {
  page?: number;
  limit?: number;
  dates?: [Date, Date];
  status: RideStatus[];
  searchQuery?: string;
};

type QueryParams = {
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  search?: string;
  status: RideStatus[];
};

const transformRequestParams = ({
  dates,
  searchQuery,
  page = 1,
  status,
  limit = RIDES_LIST_LIMIT,
}: Params): QueryParams => {
  const startDate = startOfDay(dates[0]);
  const endDate = dates[1] ? endOfDay(dates[1]) : endOfDay(dates[0]);
  return {
    page,
    limit,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    search: searchQuery,
    status,
  };
};

const transformResponse = (response: RidesListResponse): RidesListModel => {
  const data = {
    count: response.count,
    rides: response.rides.map(
      ({
        _id,
        status,
        date,
        predicted_end_date,
        bookings,
        stations,
        route_name,
        route_polyline,
      }) => ({
        id: _id,
        status,
        startDate: new Date(date),
        predictedEndDate: new Date(predicted_end_date),
        firstStation: first(stations)?.name,
        lastStation: last(stations)?.name,
        stations: toAllCamelCase(stations),
        // Sorry but it's actually seats!
        seats: bookings,
        routeName: route_name,
        routePolyline: route_polyline,
      })
    ),
  };
  return data;
};

const fetchRides = async (params: Params): Promise<PaginatedRidesListModel> => {
  const response = await apiInstance.get<AxiosResponse, RidesListResponse>("rides", {
    params: transformRequestParams(params),
  });

  const transformedData = transformResponse(response);

  // Report to google tag manager only if any of the params other than the page changed
  if (params.page === 1) {
    TagManager.dataLayer({
      dataLayer: {
        event: "rides-results",
        ridesResults: transformedData.count > 0 ? "yes" : "no",
      },
    });
  }

  return {
    data: transformedData,
    nextPage: response.count > params.page * RIDES_LIST_LIMIT ? params.page + 1 : undefined,
  };
};

const useRides = (params: Params) => {
  const history = useHistory();

  useEffect(() => {
    return () => removeFromQuery("ride-id", history);
  }, [params.dates, params.searchQuery]);

  const query = useInfiniteQuery(
    ["rides", params],
    ({ pageParam = 1 }) => {
      // @ts-ignore
      return fetchRides({ ...params, page: pageParam, status: params.status });
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...query,
    data: (query.data
      ? {
          count: query.data.pages[0].data.count,
          rides: query.data.pages.flatMap((list) => list && list.data.rides),
        }
      : {
          count: 0,
          rides: [],
        }) as RidesListModel,
  };
};

export { useRides, fetchRides, transformResponse, transformRequestParams };
