/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import Table from "@swvl/table";
import Page from "components/PageWrapper";

import isNil from "lodash/fp/isNil";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { addToQuery, getFromQuery, checkQuery, downloadCSV } from "utils/";
import NoResults from "components/NoListResults";
import { usePrivateRides } from "resources/usePrivateRides";
import { useApprovePrivateRideRequest } from "resources/useApprovePrivateRide";
import { useRejectPrivateRideRequest } from "resources/useRejectPrivateRide";
import { PRIVATE_RIDES_LIST_PAGE_SIZE } from "constants/privateRides";
import { useFilters } from "utils/useFilters";

import Filters from "./ListFilters";
import columns from "./ListColumns";
import Header from "./ListHeader";
import PrivateRideRequest from "./PrivateRideRequest";
import ApproveRequestPopup from "./ApproveRequestPopup";
import RejectRequestPopup from "./RejectRequestPopup";

import BulkUploadPopup from "pages/private-ride/BulkUploadPrivateRidesPopup";
import {
  useBulkUploadPrivateRidesTemplate,
  useBulkUploadPrivateRides,
} from "resources/useBulkUploadPrivateRides";

import { useFetchVehicleTypes } from "resources/useCreatePrivateRide";

type PopupData = {
  data: {
    id: string;
  };
  userName: string;
};
type RejectionReasonOption = {
  label: string;
  value: string;
};

export type VehicleOption = { name: string; id: string };

const PrivateRidesList: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currPage = getFromQuery("page", location.search);

  const [page, setPage] = useState<number>(currPage ? +currPage : 1);
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});
  const [approvePopupData, setApprovePopupData] = useState<PopupData>(null);
  const [rejectPopupData, setRejectPopupData] = useState<PopupData>(null);

  const [selectedReason, setSelectedReason] = useState<RejectionReasonOption>(undefined);

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [isBulkUploadPopupVisible, setBulkUploadPopupVisible] = useState(false);

  const { mutate: approvePrivateRideRequest } = useApprovePrivateRideRequest();
  const { mutate: rejectPrivateRideRequest } = useRejectPrivateRideRequest();

  const { data: bulkUploadTemplateColumns } = useBulkUploadPrivateRidesTemplate();
  const {
    mutate: bulkUploadPrivateRides,
    isLoading: isBulkUploadLoading,
  } = useBulkUploadPrivateRides();

  const filters = useFilters(["operator_status", "corporate_status", "query"], true);
  const handleFilterChange = useCallback(
    (key: string) => (filter) => {
      filters.apply({ [key]: filter.value });

      setPage(1);
    },
    []
  );
  const handleApproveRequest = useCallback((e, id, userName) => {
    const data = { id };
    e.stopPropagation();
    setApprovePopupData({ data, userName });
  }, []);
  const handleRejectRequest = useCallback((e, id, userName) => {
    const data = { id };
    e.stopPropagation();
    setRejectPopupData({ data, userName });
  }, []);
  const handleSelectReason = (reason) => {
    setSelectedReason(reason);
  };

  const {
    data: privateRidesList,
    status: privateRidesStatus,
    isFetching: isListLoading,
  } = usePrivateRides({
    page,
    filters: filters.current,
  });

  const isActionProcessing = isListLoading;

  const getTrProps = useCallback((props, original) => {
    const { id } = original;
    return {
      ...props,
      "data-test-name": "private-ride-row",
      "data-test-id": id,
      onClick: (e) => {
        e.preventDefault();
        addToQuery("private-ride-id", id, history);
      },
      sx: {
        "&[role][class]": {
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "gainsboro",
            cursor: "pointer",
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    addToQuery("page", page.toString(), history);
  }, [page]);

  const { data: vehicleTypes } = useFetchVehicleTypes();
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState<VehicleOption[]>(undefined);

  useEffect(() => {
    if (vehicleTypes) {
      const vehicleTypesOptions: VehicleOption[] = vehicleTypes.map((vehicle) => {
        return { name: vehicle.name, id: vehicle.id };
      });

      setVehicleTypesOptions(vehicleTypesOptions);
    }
  }, [vehicleTypes]);

  const privateRideId = useMemo(() => {
    if (checkQuery("private-ride-id", location.search)) {
      return getFromQuery("private-ride-id", location.search);
    } else {
      return null;
    }
  }, [location.search]);

  // Default filter to active
  useEffect(() => {
    filters.apply({});
  }, []);

  const useControlledState = (state) => {
    return useMemo(
      () => ({
        ...state,
        selectedRowIds: selectedIds,
      }),
      [state, selectedIds]
    );
  };
  const submitBulkUpload = useCallback((file: File) => {
    const formData = new FormData();
    formData.append("document", file);
    bulkUploadPrivateRides(formData);
  }, []);

  const downloadBulkUploadTemplate = useCallback(() => {
    console.log(bulkUploadTemplateColumns);
    if (!isNil(bulkUploadTemplateColumns)) {
      downloadCSV(
        "bulk-upload-private-rides-template",
        bulkUploadTemplateColumns.map((column) => column.name),
        true
      );
    }
  }, [bulkUploadTemplateColumns]);

  const downloadVehicleType = useCallback(() => {
    downloadCSV("vehicle-types", vehicleTypesOptions, false);
  }, [vehicleTypesOptions]);

  return (
    <Page
      tagName="private-rides-page"
      title="Private Rides | List"
      trackName="PrivateRides.List.Page"
      header={
        <Header
          openBulkUploadPopup={() => {
            setBulkUploadPopupVisible(true);
          }}
          isLoading={isActionProcessing}
        />
      }
    >
      <Filters onFilterChange={handleFilterChange} initialFilters={filters.current} />
      {!isNil(privateRideId) ? <PrivateRideRequest requestId={privateRideId} /> : null}
      <Table
        getTrProps={getTrProps}
        pageSize={PRIVATE_RIDES_LIST_PAGE_SIZE}
        totalCount={privateRidesList?.count}
        data={privateRidesList?.PrivateRides || []}
        useTablePagination
        useControlledPagination
        useControlledState={useControlledState}
        onPageChange={(pageIndex) => setPage(pageIndex + 1)}
        pageIndex={page - 1}
        isLoading={privateRidesStatus === "loading"}
        noDataComponent={
          <NoResults
            css={{ width: 600, margin: "0 auto" }}
            title="No Results Found"
            body="There are no private rides yet."
          />
        }
        useZebraStyles
        useFullWidth
        columns={columns({
          isDisabled: isActionProcessing,
          handleApproveRequest,
          handleRejectRequest,
        })}
        getRowId={(row) => row.id.toString()}
      />
      <ApproveRequestPopup
        data={approvePopupData}
        testId="confirmationDialogue"
        onApprove={() => {
          const { data, userName } = approvePopupData;
          if (data) {
            approvePrivateRideRequest(
              { data, userName },
              {
                onSuccess: (userName) => {
                  setApprovePopupData(null);
                },
              }
            );
          }
        }}
        onDismiss={() => setApprovePopupData(null)}
      />
      <RejectRequestPopup
        data={rejectPopupData}
        testId="confirmationDialogue"
        setReason={handleSelectReason}
        onSuccess={success}
        onError={error}
        onConfirm={(note) => {
          const { data, userName } = rejectPopupData;
          if (data) {
            let toBeSubmitted;
            if (note) {
              toBeSubmitted = { data, userName, reason: selectedReason?.value, note };
            } else {
              toBeSubmitted = { data, userName, reason: selectedReason?.value };
            }
            rejectPrivateRideRequest(toBeSubmitted, {
              onSuccess: (userName) => {
                setSuccess(true);
                setRejectPopupData(null);
              },
              onError: () => {
                setError(true);
              },
            });
          }
        }}
        onDismiss={() => setRejectPopupData(null)}
      />
      {isBulkUploadPopupVisible && (
        <BulkUploadPopup
          isOpen={isBulkUploadPopupVisible}
          columns={bulkUploadTemplateColumns}
          onDownloadTemplate={downloadBulkUploadTemplate}
          onUpload={(files) => {
            submitBulkUpload(files[0]);
            setBulkUploadPopupVisible(false);
          }}
          onDismiss={() => {
            setBulkUploadPopupVisible(false);
          }}
          isDownloadAnotherFile={true}
          isDownloadAnotherFileDisabled={Boolean(!vehicleTypesOptions.length)}
          onDownloadAnotherFile={downloadVehicleType}
          anotherFileName="Vehicle Types"
        />
      )}
    </Page>
  );
};

export default memo(PrivateRidesList);
