/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import isNil from "lodash/fp/isNil";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import loginBackground from "assets/login@1x.png";
import { BackgroundImage, FormWrapper } from "./styled";
import Form from "./LoginForm";
import useAuthState from "utils/useAuthState";

const Login: FC = () => {
  const history = useHistory();
  const {
    authData: { token },
  } = useAuthState();

  const appInsights = useAppInsightsContext();
  const trackLoginPage = useTrackMetric(appInsights, "Login.Page");

  useEffect(() => {
    if (!isNil(token)) {
      history.push("/");
    }
    trackLoginPage();
  }, [token]);

  return (
    <BackgroundImage img={loginBackground} gradient>
      <FormWrapper>
        <Form />
      </FormWrapper>
    </BackgroundImage>
  );
};

export default Login;
