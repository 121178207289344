export const SHIFTS_LIST_PAGE_SIZE = 10;

export const SEARCH_DEBOUNCE_TIME = 1000;

export const SHIFT_DIRECTION_OPTIONS = [
  { label: "All", value: null },
  {
    label: "From Office",
    value: "from_office",
  },
  {
    label: "To Office",
    value: "to_office",
  },
];

export const ACTIVE_STATUS_OPTIONS = [
  { label: "All", value: null },
  {
    label: "Active Shifts",
    value: "active",
  },
  {
    label: "Inactive Shifts",
    value: "in-active",
  },
  {
    label: "Completed Shifts",
    value: "completed",
  },
];
