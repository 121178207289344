/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useMemo, useRef, memo, useState, useEffect } from "react";
import isNil from "lodash/fp/isNil";
import { format, isEqual } from "date-fns";
import ReactTooltip from "react-tooltip";
import { WeekdaysCheckbox } from "@swvl/weekdays";
import { FormikProvider, useFormik } from "formik";
import { object, array, string, date, number, mixed } from "yup";
import DatePicker from "@swvl/date-picker";
import Button from "@swvl/button";
import theme from "@swvl/theme";
import Icon from "@swvl/icon";
import styled from "@emotion/styled";
import Spinner from "@swvl/spinner";
import { Select } from "@swvl/select";
import TimePicker from "@swvl/time-picker";
import Checkbox from "@swvl/checkbox";
import { useHistory } from "react-router-dom";
import Page from "components/PageWrapper";
import Field from "components/FormField";
import { constants, convertAmPmTimeToMinutes } from "utils";
import { Label } from "./styled";
import { useOfficeLocations } from "resources/useOfficeLocations";
import { useCreateShift } from "resources/useCreateShift";
import { showAlert } from "@swvl/alert";
import { useAdminDetails } from "resources/useAdminDetails";

const WEEK_DAYS = [
  { label: "Su", value: 7 },
  { label: "Mo", value: 1 },
  { label: "Tu", value: 2 },
  { label: "We", value: 3 },
  { label: "Th", value: 4 },
  { label: "Fr", value: 5 },
  { label: "Sa", value: 6 },
];

type ShiftDirection = "to_office" | "from_office";

const directionOptions: { label: string; value: ShiftDirection }[] = [
  {
    label: "From Office",
    value: "from_office",
  },
  {
    label: "To Office",
    value: "to_office",
  },
];

export const FormContainer = styled.div`
  width: 30%;
  margin: 20px;
`;

export type FormData = {
  name: string;
  startDate: Date;
  endDate: Date;
  direction: ShiftDirection;
  time: number;
  weekdays: number[];
  officeLocation: string;
};

const formInitialData: FormData = {
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  direction: undefined,
  time: 0,
  weekdays: [1, 2, 3, 4, 7],
  officeLocation: undefined,
};

export const formSchema = object().shape({
  startDate: date().required("You must pick date range"),
  endDate: date().required("You must pick date range"),
  time: number()
    .min(0)
    .max(60 * 24),
  direction: mixed()
    .required("You must select a shift direction")
    .oneOf(["to_office", "from_office"]),
  weekdays: array().min(1, "You must pick at least one day"),
  officeLocation: string().required("You have to select an office location"),
});

const FieldWrapper = ({ children, error = null }) => (
  <div sx={{ mb: "20px", position: "relative" }}>
    {children}
    {error ? (
      <Text
        sx={{
          marginTop: "6px",
          color: "crimson",
          fontSize: "12px",
        }}
      >
        {error}
      </Text>
    ) : null}
  </div>
);

const Header = () => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: 700 }}>
        Add New Shift
      </Text>
    </div>
  );
};

const ShiftsCreate: FC = () => {
  const minDate = new Date();
  const calendarRef = useRef(null);
  const history = useHistory();

  const { data: loggedInAdminDetails } = useAdminDetails();
  const { data: officeLocationsData, isLoading: isOfficeLocationsLoading } = useOfficeLocations();

  useEffect(() => {
    // Navigate back to shifts if no office location found
    if (officeLocationsData?.count === 0) {
      history.replace("/shifts");
      showAlert({
        message: "No office locations found",
        type: "error",
        id: "alert-error",
      });
    }
    // Navigate back to shifts if shift_type is not dynamic
    else if (loggedInAdminDetails?.corporate?.shift_type === "fixed") {
      history.replace("/shifts");
      showAlert({
        message: "Not allowed to create shifts",
        type: "error",
        id: "alert-error",
      });
    }
  }, [officeLocationsData, loggedInAdminDetails]);

  const [addAnotherShiftChecked, setAddAnotherShiftChecked] = useState(false);
  const { mutate: createShift, isLoading: isCreateShiftLoading } = useCreateShift();

  const officeLocationsOptions = useMemo(
    () =>
      officeLocationsData?.officeLocations.map((officeLocation) => ({
        value: officeLocation.id,
        label: officeLocation.name,
      })),
    [officeLocationsData]
  );

  const formik = useFormik({
    initialValues: formInitialData,
    validationSchema: formSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => {
      createShift(
        {
          ...data,
          startDate: format(new Date(data.startDate), "yyyy-MM-dd"),
          endDate: format(new Date(data.endDate), "yyyy-MM-dd"),
        },
        {
          onSuccess: (response) => {
            if (addAnotherShiftChecked) {
              // The first replace is there because the component wasn't refreshing otherwise
              history.replace(`/shifts`);
              history.replace(`/shifts/create`);
            } else history.replace(`/shifts?shift-id=${response._id}`);
          },
        }
      );
    },
  });

  const {
    setFieldValue,
    setFieldTouched,
    errors,
    values,
    touched,
    handleBlur,
    isValid,
    dirty,
  } = formik;

  // Set default office location
  useEffect(() => {
    if (officeLocationsData) {
      const defaultOfficeLocation = officeLocationsData.officeLocations.find(
        (location) => location.isDefault
      );
      if (defaultOfficeLocation) setFieldValue("officeLocation", defaultOfficeLocation.id, true);
    }
  }, [officeLocationsData]);

  const [[startDate, endDate], setDates] = useState<[Date, Date]>([
    values.startDate,
    values.endDate,
  ]);

  const datePlaceholder = useMemo(() => {
    if (!startDate && !endDate) return "Select Date Range";
    const formatStartDate = format(startDate, constants.datePickerPlaceholderFormat);
    let formatEndDate = "";
    if (!isNil(endDate) && !isEqual(startDate, endDate)) {
      formatEndDate = format(endDate, constants.datePickerPlaceholderFormat);
    }
    return `${formatStartDate} ${formatEndDate ? `- ${formatEndDate}` : ""}`;
  }, [startDate, endDate]);

  return (
    <Page title="Shifts | Create" trackName="Shifts.Create.Page" header={<Header />}>
      <FormContainer>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} key={"create"}>
            <FieldWrapper>
              <Field
                label="Shift Name"
                id="name"
                placeholder="Add Shift Name"
                name="name"
                helpText="max length 128 characters"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </FieldWrapper>
            <FieldWrapper>
              <DatePicker
                label="Date Range"
                inputVariant="bordered"
                ref={calendarRef}
                required
                minDate={minDate}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                placeholder={datePlaceholder}
                withIconAtEnd
                onChange={(newDates) => {
                  const [newStartDate, newEndDate] = newDates as [Date, Date];
                  setDates([newStartDate, newEndDate]);
                }}
                onClickOutside={() => {
                  /**  Discard changes if not applied and clicked outside */
                  setDates([values.startDate, values.endDate]);
                }}
              >
                <Button
                  variant="primary"
                  iconPosition="left"
                  onClick={() => {
                    calendarRef.current.setOpen(false);
                    setFieldValue("startDate", startDate, true);
                    setFieldValue("endDate", endDate, true);
                    setFieldTouched("startDate", true);
                    setFieldTouched("endDate", true);
                  }}
                >
                  Apply
                </Button>
              </DatePicker>
              <div
                data-for="shift-contract"
                data-tip="The shifts will be enabled based on the contract"
                data-iscapture="true"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: "-50px",
                  top: "35px",
                }}
              >
                <Icon name="info" size={20} fill={theme.colors.opacity["black-38"]} />
              </div>
              <ReactTooltip
                id="shift-contract"
                place="right"
                effect="solid"
                type="dark"
                multiline
                arrowColor={"transparent"}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Select
                name="direction"
                options={directionOptions}
                handleChange={(option: { label: string; value: ShiftDirection }) => {
                  setFieldValue("direction", option.value, true);
                  setFieldTouched("direction", true);
                }}
                placeholder="Select Direction"
                label="Shift Direction"
                handleBlur={handleBlur}
                value={directionOptions.find((option) => option.value === values.direction)}
                error={errors.direction}
                touched={touched.direction}
                required
              />
            </FieldWrapper>
            <FieldWrapper>
              <TimePicker
                onInputChange={(value) => {
                  if (!isNil(value)) {
                    setFieldValue("time", convertAmPmTimeToMinutes(value), true);
                    setFieldTouched("time", true);
                  }
                }}
                id="time_picker"
                label="Shift Time"
                placeholder="Select Time"
                required
                variant="bordered"
                height="default"
                error={!!errors.time}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label htmlFor="weekdays">
                Weekdays{" "}
                <span
                  sx={{
                    ml: 1,
                    color: "crimson",
                  }}
                >
                  *
                </span>
              </Label>
              <WeekdaysCheckbox
                // TODO filter based on selected range
                days={WEEK_DAYS}
                selectedDays={values.weekdays}
                setSelectedDays={(days) => {
                  setFieldValue("weekdays", days, true);
                  setFieldTouched("weekdays", true);
                }}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Select
                name="office_location"
                options={officeLocationsOptions}
                isLoading={isOfficeLocationsLoading}
                handleChange={(option: { label: string; value: ShiftDirection }) => {
                  setFieldValue("officeLocation", option.value, true);
                  setFieldTouched("officeLocation", true);
                }}
                placeholder="Select Location"
                label="Office Location"
                handleBlur={handleBlur}
                isDisabled={!officeLocationsOptions}
                value={officeLocationsOptions?.find(
                  (option) => option.value === values.officeLocation
                )}
                required
                error={errors.officeLocation}
                touched={touched.officeLocation}
              />
            </FieldWrapper>
            <div sx={{ mb: "20px" }}>
              <Checkbox
                id="add-another-shift"
                isChecked={addAnotherShiftChecked}
                onChange={(e) => setAddAnotherShiftChecked(e.target.checked)}
                scale={1.2}
              >
                Add Another Shift
              </Checkbox>
            </div>
            {isCreateShiftLoading ? (
              <Spinner
                color="primary"
                sx={{
                  width: "35px",
                }}
              />
            ) : (
              <Button
                icon={<Icon name="add" size={15} fill="currentcolor" />}
                variant="primary"
                type="submit"
                disabled={!isValid || !dirty}
              >
                Add Shift
              </Button>
            )}
          </form>
        </FormikProvider>
      </FormContainer>
    </Page>
  );
};

export default memo(ShiftsCreate);
