/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import Table from "@swvl/table";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import Page from "components/PageWrapper";
import isNil from "lodash/fp/isNil";
import omit from "lodash/fp/omit";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { addToQuery, checkQuery, downloadCSV, getFromQuery } from "utils/";
import NoResults from "components/NoListResults";
import ActivationPopup, { ActivationPopupData } from "pages/employees/ActivateEmployeePopup";
import BulkUploadPopup from "pages/employees/BulkUploadEmployeesPopup";
import DeleteEmployeePopup from "pages/employees/DeleteEmployeePopup";
import Profile from "pages/employees/EmployeeProfile";
import { EmployeeModel, useEmployees } from "resources/useEmployees";
import {
  useBulkUploadEmployees,
  useBulkUploadEmployeesTemplate,
} from "resources/useBulkUploadEmployees";
import { useActivateEmployee } from "resources/useActivateEmployee";
import { useDeleteEmployees } from "resources/useDeleteEmployees";
import { useDeactivateEmployee } from "resources/useDeactivateEmployee";
import { EMPLOYEES_LIST_PAGE_SIZE } from "constants/employees";
import { useFilters } from "utils/useFilters";
import { useAllEmployeesCount } from "resources/useAllEmployeesCount";
import { useAdminDetails } from "resources/useAdminDetails";

import columns from "./ListColumns";
import Filters from "./ListFilters";
import Header from "./ListHeader";
import SelectionBanner from "./SelectionBanner";
import SelectedEmployeesPopup from "./SelectedEmployeesPopup";
import AssignEmployeesPopup from "./AssignEmployeesPopup";
import DeleteEmployeesPopup from "./DeleteEmployeesPopup";

const EmployeesList: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currPage = getFromQuery("page", location.search);

  const [popupData, setPopupData] = useState<ActivationPopupData>(null);
  const [isDeleteEmployeePopupVisible, setDeleteEmployeePopupVisible] = useState(false);
  const [isDeleteEmployeesPopupVisible, setDeleteEmployeesPopupVisible] = useState(false);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState("");
  const [isBulkUploadPopupVisible, setBulkUploadPopupVisible] = useState(false);
  const [isSelectedEmployeesPopupVisible, setSelectedEmployeesPopupVisible] = useState(false);
  const [isAssignEmployeesPopupVisible, setAssignEmployeesPopupVisible] = useState(false);
  const [page, setPage] = useState<number>(currPage ? +currPage : 1);
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});
  const [selectedEmployees, setSelectedEmployees] = useState<Record<string, EmployeeModel>>({});
  const [deleteAllEmployees, setDeleteAllEmployees] = useState(false);

  const filters = useFilters(["active", "shift_assigned", "search_query"], true);

  const { data: employeesList, status: employeesStatus, isFetching: isListLoading } = useEmployees({
    page,
    filters: filters.current,
  });

  const { data: allEmployeesCount } = useAllEmployeesCount();

  const { data: bulkUploadTemplateColumns } = useBulkUploadEmployeesTemplate();
  const { mutate: bulkUploadEmployees, isLoading: isBulkUploadLoading } = useBulkUploadEmployees();
  const {
    mutate: deleteEmployees,
    isLoading: isDeleteEmployeesLoading,
    isSuccess: deleteEmployeesSuccess,
  } = useDeleteEmployees();
  const {
    mutateAsync: activateEmployee,
    isLoading: isActivateEmployeeLoading,
  } = useActivateEmployee();
  const {
    mutateAsync: deactivateEmployee,
    isLoading: isDeactivateEmployeeLoading,
  } = useDeactivateEmployee();

  const { data: loggedInAdminDetails } = useAdminDetails();
  const allowDeleteAllBusinessProfiles =
    loggedInAdminDetails?.corporate?.allow_delete_all_business_profiles;

  const showDeleteAllButton = allEmployeesCount > 0 && allowDeleteAllBusinessProfiles;

  const isActionProcessing =
    isListLoading ||
    isBulkUploadLoading ||
    isDeleteEmployeesLoading ||
    isDeactivateEmployeeLoading ||
    isActivateEmployeeLoading;

  const profileId = useMemo(() => {
    if (checkQuery("employee-id", location.search)) {
      return getFromQuery("employee-id", location.search);
    } else {
      return null;
    }
  }, [location.search]);

  const getTrProps = useCallback((props, original) => {
    const { id, active } = original;
    return {
      ...props,
      onClick: (e) => {
        e.preventDefault();
        addToQuery("employee-id", id, history);
      },
      sx: {
        "&[role][class]": {
          cursor: "pointer",
          backgroundColor: !active ? "snow" : "",
          transition: "background-color .15s",
          "&:hover": {
            backgroundColor: "gainsboro",
            cursor: "pointer",
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    addToQuery("page", page.toString(), history);
  }, [page]);

  // Default filter to active
  useEffect(() => {
    filters.apply({ active: "true" });
  }, []);

  useEffect(() => {
    const newSelectedRows = Object.keys(selectedIds)
      .filter((id) => selectedIds[id])
      .reduce((acc, id) => {
        const selectedRow = employeesList.employees.find((row) => `${row.id}` === id);
        if (selectedRow) {
          return {
            ...acc,
            [id]: selectedRow,
          };
        }
        const selectedPrevRowId = Object.keys(selectedEmployees).find((rowId) => rowId === id);
        if (selectedPrevRowId) {
          return {
            ...acc,
            [id]: selectedEmployees[selectedPrevRowId],
          };
        }
        return acc;
      }, {});
    setSelectedEmployees(newSelectedRows);
  }, [selectedIds]);

  const activeSelectedEmployees = useMemo(
    () => Object.values(selectedEmployees).filter((employee) => employee.active),
    [selectedEmployees]
  );
  const inActiveSelectedEmployees = useMemo(
    () => Object.values(selectedEmployees).filter((employee) => !employee.active),
    [selectedEmployees]
  );

  const handleEditEmployee = useCallback((e, id) => {
    e.stopPropagation();
    history.push(`/employees/edit?employee-id=${id}`);
  }, []);

  const handleDeleteEmployee = useCallback((e, id) => {
    e.stopPropagation();
    setDeleteEmployeePopupVisible(true);
    setUserIdToBeDeleted(id);
  }, []);

  const handleDeleteEmployees = useCallback(() => {
    setDeleteEmployeesPopupVisible(true);
  }, [selectedEmployees]);

  const handleAssignEmployees = useCallback(() => {
    history.push({
      pathname: "employees/shift-assignment",
      state: {
        selectedEmployeesIds: activeSelectedEmployees.map((employee) => employee.id),
      },
    });
  }, [activeSelectedEmployees]);

  const removeSelectedEmployee = (employeeId: string) => {
    if (selectedEmployees[employeeId]) {
      const newSelectedEmployees = omit(employeeId)(selectedEmployees);
      setSelectedEmployees(newSelectedEmployees);
    }
  };

  const submitBulkUpload = useCallback((file: File) => {
    const formData = new FormData();
    formData.append("document", file);
    bulkUploadEmployees(formData);
    TagManager.dataLayer({
      dataLayer: {
        event: "bulk_upload_click",
        bulkUploadAction: "submit-employee-bulk-upload",
      },
    });
  }, []);

  const downloadBulkUploadTemplate = useCallback(() => {
    if (!isNil(bulkUploadTemplateColumns)) {
      downloadCSV(
        "bulk-upload-employees-template",
        bulkUploadTemplateColumns.map((column) => column.name),
        true
      );
      TagManager.dataLayer({
        dataLayer: {
          event: "bulk_upload_click",
          bulkUploadAction: "template-employee-bulk-upload",
        },
      });
    }
  }, [bulkUploadTemplateColumns]);

  const handleFilterChange = useCallback(
    (key: string) => (filter) => {
      if (key === "search_query" && !isNil(filter.value)) {
        filters.apply({ active: null, shift_assigned: null });
      }

      if ((key === "active" || key === "shift_assigned") && !isNil(filter.value)) {
        filters.apply({ search_query: null });
      }

      filters.apply({ [key]: filter.value });

      setPage(1);
    },
    []
  );

  const useControlledState = (state) => {
    return useMemo(
      () => ({
        ...state,
        selectedRowIds: selectedIds,
      }),
      [state, selectedIds]
    );
  };

  return (
    <Page
      tagName="employees-page"
      title="Employees | List"
      trackName="Employees.List.Page"
      header={
        <Header
          openBulkUploadPopup={() => {
            setBulkUploadPopupVisible(true);
            TagManager.dataLayer({
              dataLayer: {
                event: "bulk_upload_click",
                bulkUploadAction: "click-employee-bulk-upload",
              },
            });
          }}
          isLoading={isActionProcessing}
          showDeleteAllButton={showDeleteAllButton}
          onDeleteAllEmployees={() => {
            setSelectedEmployees({});
            setSelectedIds({});
            setDeleteAllEmployees(true);
            setDeleteEmployeesPopupVisible(true);
          }}
        />
      }
    >
      {!isNil(profileId) ? <Profile id={profileId} /> : null}
      <Filters onFilterChange={handleFilterChange} initialFilters={filters.current} />
      {Object.keys(selectedEmployees).length > 0 ? (
        <div>
          <SelectionBanner
            selectedList={selectedEmployees}
            total={allEmployeesCount}
            onDeselectEmployee={(employeeId) => setSelectedIds(omit(employeeId)(selectedIds))}
            onExpandSelectedEmployee={() => {
              setSelectedEmployeesPopupVisible(true);
            }}
          />
          <Flex>
            <div
              sx={{
                m: 3,
                width: "150px",
                border: "1px solid",
                borderColor: "primary",
                borderRadius: "10px",
              }}
            >
              <Button
                variant="default"
                icon={
                  <Icon
                    name="assign_employees"
                    size={20}
                    fill="currentcolor"
                    stroke="currentcolor"
                  />
                }
                iconPosition="left"
                sx={{
                  ml: 2,
                  color: "primary",
                }}
                onClick={() => {
                  if (
                    activeSelectedEmployees.length === 0 ||
                    inActiveSelectedEmployees.length > 0
                  ) {
                    setAssignEmployeesPopupVisible(true);
                  } else {
                    handleAssignEmployees();
                  }
                }}
              >
                Assign To Shifts
              </Button>
            </div>
            <div
              sx={{
                m: 3,
                border: "1px solid",
                borderColor: "primary",
                borderRadius: "10px",
              }}
            >
              <Button
                variant="default"
                icon={<Icon name="delete" size={20} fill="currentcolor" stroke="currentcolor" />}
                iconPosition="left"
                sx={{
                  ml: 2,
                  color: "primary",
                }}
                onClick={(e) => handleDeleteEmployees()}
              >
                Delete Employees
              </Button>
            </div>
          </Flex>
        </div>
      ) : null}
      <Table
        getTrProps={getTrProps}
        pageSize={EMPLOYEES_LIST_PAGE_SIZE}
        totalCount={employeesList?.count}
        data={employeesList?.employees || []}
        useRowSelection
        useTablePagination
        useControlledPagination
        useControlledState={useControlledState}
        onPageChange={(pageIndex) => setPage(pageIndex + 1)}
        pageIndex={page - 1}
        isLoading={employeesStatus === "loading"}
        noDataComponent={
          <NoResults
            css={{ width: 600, margin: "0 auto" }}
            title="No Results Found"
            body="There are no employees yet."
          />
        }
        useZebraStyles
        useFullWidth
        columns={columns({
          setPopupData,
          handleEditEmployee,
          handleDeleteEmployee,
          isDisabled: isActionProcessing,
        })}
        getThCheckboxProps={({ rowsById }) => {
          return {
            title: "Select all employees",
            scale: 1.8,
            style: {
              marginLeft: "16px",
              cursor: "pointer",
            },
            disabled: isActionProcessing,
            onChange: () => {
              const atLeastOneSelected = Object.values(rowsById).some((row) => selectedIds[row.id]);
              const newSelectedIds = Object.keys(rowsById).reduce(
                (acc, id) => ({
                  ...acc,
                  [id]: !atLeastOneSelected,
                }),
                {}
              );
              setSelectedIds({
                ...selectedIds,
                ...newSelectedIds,
              });
            },
          };
        }}
        getTrCheckboxProps={(row) => {
          return {
            title: "Select employee",
            scale: 1.8,
            style: {
              marginLeft: "16px",
              cursor: "pointer",
            },
            disabled: isActionProcessing,
            onChange: () => {
              setSelectedIds({
                ...selectedIds,
                [row.id]: !selectedIds[row.id],
              });
            },
            onClick: (e) => e.stopPropagation(),
          };
        }}
        getRowId={(row) => row.id.toString()}
      />
      <ActivationPopup
        data={popupData}
        onApprove={async () => {
          const { id, value } = popupData;
          if (value) {
            await deactivateEmployee({ id });
          } else {
            await activateEmployee({ id });
          }
          if (selectedEmployees[id]) {
            setSelectedEmployees({
              ...selectedEmployees,
              [id]: {
                ...selectedEmployees[id],
                active: !value,
              } as EmployeeModel,
            });
          }
          setPopupData(null);
        }}
        onDismiss={() => {
          setPopupData(null);
        }}
        isSubmitting={isActivateEmployeeLoading || isDeactivateEmployeeLoading}
      />
      {isBulkUploadPopupVisible && (
        <BulkUploadPopup
          isOpen={isBulkUploadPopupVisible}
          columns={bulkUploadTemplateColumns}
          onDownloadTemplate={downloadBulkUploadTemplate}
          onUpload={(files) => {
            submitBulkUpload(files[0]);
            setBulkUploadPopupVisible(false);
          }}
          onDismiss={() => {
            setBulkUploadPopupVisible(false);
          }}
          isDownloadAnotherFile={false}
        />
      )}

      {isDeleteEmployeePopupVisible ? (
        <DeleteEmployeePopup
          onApprove={() => {
            deleteEmployees({ ids: [userIdToBeDeleted] });
            if (deleteEmployeesSuccess) {
              removeSelectedEmployee(userIdToBeDeleted);
            }
            setDeleteEmployeePopupVisible(false);
          }}
          employeeId={userIdToBeDeleted}
          onDismiss={() => setDeleteEmployeePopupVisible(false)}
          isOpen={isDeleteEmployeePopupVisible}
        />
      ) : null}

      {isDeleteEmployeesPopupVisible ? (
        <DeleteEmployeesPopup
          onApprove={() => {
            deleteEmployees({ ids: Object.keys(selectedEmployees), deleteAll: deleteAllEmployees });
            setSelectedEmployees({});
            setSelectedIds({});
            setDeleteEmployeesPopupVisible(false);
            setDeleteAllEmployees(false);
          }}
          employeesCount={
            deleteAllEmployees ? allEmployeesCount : Object.keys(selectedEmployees).length
          }
          onDismiss={() => {
            setDeleteEmployeesPopupVisible(false);
            setDeleteAllEmployees(false);
          }}
          isOpen={isDeleteEmployeesPopupVisible}
        />
      ) : null}
      {isSelectedEmployeesPopupVisible ? (
        <SelectedEmployeesPopup
          onDismiss={() => setSelectedEmployeesPopupVisible(false)}
          onDeselectAll={() => {
            setSelectedIds({});
            setSelectedEmployeesPopupVisible(false);
          }}
          onDeselectEmployee={(employeeId) => {
            setSelectedIds(omit(employeeId)(selectedIds));
            if (Object.keys(selectedIds).length === 1) {
              setSelectedEmployeesPopupVisible(false);
            }
          }}
          isOpen={isSelectedEmployeesPopupVisible}
          selectedEmployees={selectedEmployees}
          withClose
        />
      ) : null}
      {isAssignEmployeesPopupVisible ? (
        <AssignEmployeesPopup
          onApprove={() => {
            handleAssignEmployees();
            setAssignEmployeesPopupVisible(false);
          }}
          activeEmployees={activeSelectedEmployees}
          inActiveEmployees={inActiveSelectedEmployees}
          onDismiss={() => setAssignEmployeesPopupVisible(false)}
        />
      ) : null}
    </Page>
  );
};

export default memo(EmployeesList);
