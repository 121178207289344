import { useUnAuthorizedResponseInterceptor } from "api";
import { FC } from "react";
import { Redirect } from "react-router-dom";
import useAuthState from "utils/useAuthState";

export const AuthControlWrapper: FC = ({ children }) => {
  const {
    authData: { token },
  } = useAuthState();

  // Setup the unauthorized response interceptor, to refresh and retry
  useUnAuthorizedResponseInterceptor();

  return token ? <>{children}</> : <Redirect to="/auth" />;
};

export default AuthControlWrapper;
