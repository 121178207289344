/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const Header = (isUpdateMode) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: 700 }}>
        {isUpdateMode ? "Edit Employee" : "Add Employee"}
      </Text>
    </div>
  );
};

export default Header;
