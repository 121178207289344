export const EMPLOYEES_LIST_PAGE_SIZE = 10;

export const SEARCH_DEBOUNCE_TIME = 1000;

export const ACTIVE_STATUS_OPTIONS = [
  { label: "All", value: null },
  {
    label: "Active Employees",
    value: "true",
  },
  {
    label: "Inactive Employees",
    value: "false",
  },
];

export const ASSIGN_STATUS_OPTIONS = [
  { label: "All", value: null },
  {
    label: "Assigned Employees",
    value: "true",
  },
  {
    label: "Unassigned Employees",
    value: "false",
  },
];
