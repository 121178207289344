import { FC } from "react";
import { setApiAuthHeader } from "api";
import { AuthenticationModel, AuthStateContext, initialAuthData } from "utils/useAuthState";
import useLocalStorage from "utils/useLocalStorage";

export const AuthProvider: FC = ({ children }) => {
  const { storedValue: authData, setValue: setAuthData } = useLocalStorage<AuthenticationModel>(
    "auth_data",
    initialAuthData
  );

  // Set Authorization api header
  setApiAuthHeader(authData.token);

  return (
    <AuthStateContext.Provider
      value={{
        authData,
        setAuthData,
        resetAuthData: () => setAuthData(initialAuthData),
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export default AuthProvider;
