/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState } from "react";
import Icon from "@swvl/icon";
import { IconButton } from "@swvl/button";
import Input from "@swvl/input";
import TagManager from "react-gtm-module";
import { useDebounce } from "utils/useDebounce";

type SearchInputProps = {
  handleChange: (value: string) => void;
  placeholder?: string;
};

const SEARCH_DEBOUNCE_TIME = 1000;
const SearchInput: FC<SearchInputProps> = ({ handleChange, placeholder }) => {
  const [searchText, setSearchText] = useState("");

  const handleDebouncedChange = () => {
    const text = searchText.length >= 3 ? searchText : undefined;
    handleChange(text);
    TagManager.dataLayer({
      dataLayer: {
        searchValue: text,
      },
    });
  };

  useDebounce(handleDebouncedChange, SEARCH_DEBOUNCE_TIME, [searchText]);

  return (
    <div sx={{ mt: 3, mb: 2 }}>
      <Input
        css={{ fontSize: "12px" }}
        startEnhancer={<Icon name="search" size={20} fill={"rgba(0, 0, 0, 0.5)"} />}
        endEnhancer={
          searchText.length > 0 ? (
            <IconButton
              variant="default"
              onClick={() => setSearchText("")}
              css={{ transform: "rotate(45deg)" }}
              icon={<Icon name="add" size={20} fill={"rgba(0, 0, 0, 0.4)"} />}
            />
          ) : null
        }
        placeholder={placeholder}
        variant="plain"
        height="small"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
    </div>
  );
};
export default SearchInput;
