import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import pick from "lodash/fp/pick";
import { dropNulls, toSnakeCase } from "utils";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

// Types

export type Gender = "male" | "female" | "other";

export interface UpdateEmployeeFormData {
  id: string;
  name: string;
  phone: string;
  phoneRegionCode?: string;
  email?: string;
  employeeId?: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  gender?: Gender;
}

export interface UpdateEmployeeRequest {
  name: string;
  email?: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  employee_id?: string;
  gender?: string;
}

export interface UpdateEmployeeResponse {
  _id: string;
  name: string;
  email: string;
  corporate: string;
  employee_id: string;
  gender: string;
  pickup: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt?: Date;
    updated_by?: string;
  };
  active: boolean;
  createdAt: string;
  deleted: boolean;
  phone: string;
  phone_region_code: string;
  is_app_registered: boolean;
  updatedAt: string;
}

const transformRequest = (form: UpdateEmployeeFormData): UpdateEmployeeRequest => {
  return pick(
    ["name", "email", "employee_id", "gender", "pickup"],
    toSnakeCase(dropNulls(form))
  ) as UpdateEmployeeRequest;
};

// Initial State

export const initialState = {
  name: "",
  email: undefined,
  employeeId: undefined,
  phone: "",
  phoneRegionCode: "",
  gender: undefined,
  pickup: undefined,
};

const updateEmployee = async (employee: UpdateEmployeeFormData) => {
  const data = transformRequest(employee);
  const response = await apiInstance.patch<UpdateEmployeeRequest, UpdateEmployeeResponse>(
    `profiles/${employee.id}`,
    data
  );
  return response;
};

const useUpdateEmployee = () => {
  const history = useHistory();
  const mutation = useMutation(updateEmployee, {
    mutationKey: "employee/update",
    onSuccess: (data) => {
      history.push(`/employees?employee-id=${data._id}`);
      showAlert({
        message: "You have updated employee details successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useUpdateEmployee, updateEmployee };
