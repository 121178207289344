/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState, useEffect, useMemo } from "react";
import Input from "@swvl/input";
import { IconButton } from "@swvl/button";
import { useLocation } from "react-router-dom";
import Icon from "@swvl/icon";
import { Select } from "@swvl/select";
import isNil from "lodash/fp/isNil";
import { checkQuery, getFromQuery } from "utils/";
import Profile from "pages/employees/EmployeeProfile";
import {
  ACTIVE_STATUS_OPTIONS,
  ASSIGN_STATUS_OPTIONS,
  SEARCH_DEBOUNCE_TIME,
} from "constants/employees";
import { FilterMap } from "utils/useFilters";
import { useDebounce } from "utils/useDebounce";

type FiltersProp = {
  onFilterChange: (key: string) => (filter: { [key: string]: string }) => void;
  initialFilters: FilterMap;
};

const Filters: FC<FiltersProp> = ({ onFilterChange, initialFilters }) => {
  const location = useLocation();
  const [profileId, setProfileId] = useState<string>(null);

  const [searchText, setSearchText] = useState("");

  const handleDebouncedChange = () => {
    const text = searchText.length >= 3 ? searchText : undefined;
    if (text) {
      onFilterChange("search_query")({ value: text });
    } else {
      onFilterChange("search_query")({ value: null });
    }
  };

  useDebounce(handleDebouncedChange, SEARCH_DEBOUNCE_TIME, [searchText]);

  useEffect(() => {
    if (checkQuery("employee-id", location.search)) {
      setProfileId(getFromQuery("employee-id", location.search));
    } else {
      setProfileId(null);
    }
  }, [location.search]);

  const initialActiveFilter = useMemo(() => {
    const selectedOption = ACTIVE_STATUS_OPTIONS.find(
      (option) => option.value === initialFilters["active"]
    );
    if (!isNil(selectedOption.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters]);

  const initialShiftAssignedFilter = useMemo(() => {
    const selectedOption = ASSIGN_STATUS_OPTIONS.find(
      (option) => option.value === initialFilters["shift_assigned"]
    );
    if (!isNil(selectedOption.value)) {
      return selectedOption;
    }
    return null;
  }, [initialFilters]);

  return (
    <div
      sx={{
        height: "64px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {profileId && <Profile id={profileId} />}
      <div
        sx={{
          pl: "20px",
          width: "30%",
        }}
      >
        <Input
          css={{ fontSize: "14px" }}
          role="searchbox"
          startEnhancer={<Icon name="search" size={15} fill={"rgba(0, 0, 0, 0.5)"} />}
          endEnhancer={
            searchText.length > 0 ? (
              <IconButton
                variant="default"
                onClick={() => {
                  setSearchText("");
                  onFilterChange("shift_assigned")({ value: null });
                }}
                css={{ transform: "rotate(45deg)" }}
                title="close"
                icon={<Icon name="add" size={15} fill={"rgba(0, 0, 0, 0.4)"} />}
              />
            ) : null
          }
          placeholder="Search employee using name, mobile number, or employee ID"
          variant="plain"
          height="small"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 6}%`,
        }}
      >
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={ACTIVE_STATUS_OPTIONS}
          label={""}
          value={initialActiveFilter}
          placeholder={"Employee Status"}
          name="active_status"
          handleChange={(value) => {
            setSearchText("");
            onFilterChange("active")(value);
          }}
          startComponent={
            <div sx={{ mr: 2 }}>
              <Icon name="assign_employees" stroke="rgba(0, 0, 0, 0.5)" fill="rgba(0, 0, 0, 0.5)" />
            </div>
          }
        />
      </div>

      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 6}%`,
        }}
      >
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={ASSIGN_STATUS_OPTIONS}
          label={""}
          value={initialShiftAssignedFilter}
          placeholder={"Assignment Status"}
          name="from_office"
          handleChange={(value) => {
            setSearchText("");
            onFilterChange("shift_assigned")(value);
          }}
          startComponent={
            <div sx={{ mr: 2 }}>
              <Icon
                name="assignment_status"
                stroke="rgba(0, 0, 0, 0.5)"
                fill="rgba(0, 0, 0, 0.5)"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Filters;
