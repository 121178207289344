import { useMutation } from "react-query";
import apiInstance from "api";

const deleteShift = async (data: { id: string }) => {
  return await apiInstance.delete(`shifts/${data.id}`);
};

const useDeleteShift = () => {
  const mutation = useMutation(deleteShift, {
    mutationKey: "shift/delete",
  });
  return mutation;
};

export { useDeleteShift, deleteShift };
