/** @jsxRuntime classic */
/** @jsx jsx */
import { Text, Flex, jsx } from "theme-ui";
import { FC, Fragment, useMemo } from "react";
import isNil from "lodash/fp/isNil";
import Avatar from "@swvl/avatar";
import { Avatar as CustomAvatar } from "components/Avatar";
import Icon from "@swvl/icon";
import Tag from "@swvl/tag";
import { BusModel, BusTypeModel, CaptainModel } from "resources/useRideDetails";
import rideCover from "assets/ride-cover.svg";
import busImage from "assets/bus.png";
import busDimmedImage from "assets/bus-dimmed.png";
import { RIDE_COVER_HEIGHT } from "constants/rideMonitoring";

type CaptainCardProps = { captain: CaptainModel } & { busType: BusTypeModel } & { bus: BusModel };

type NoWrapTextProps = {
  variant: string;
  bold?: boolean;
};

type CaptainCoverProps = {
  hasCaptain: boolean;
};

export const Header: FC = ({ children }) => {
  return (
    <div
      sx={{
        position: "relative",
        backgroundColor: "whitesmoke",
        top: 0,
        width: "100%",
        zIndex: 1,
        paddingBottom: 38,
      }}
    >
      <div
        sx={{
          height: "136px",
          background: `url(${rideCover})`,
          backgroundSize: "cover",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const CaptainCover: FC<CaptainCoverProps> = ({ children, hasCaptain }) => (
  <div
    sx={{
      backgroundImage: hasCaptain ? `url(${busDimmedImage})` : `url(${busImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "90px 50px",
      width: "100%",
      position: "absolute",
      height: RIDE_COVER_HEIGHT,
      top: "19.02%",
    }}
  >
    {children}
  </div>
);
export const CaptainDetailsWrapper: FC = ({ children }) => (
  <div
    sx={{
      position: "sticky",
      zIndex: 2,
      padding: "24px",
      backgroundColor: "whitesmoke",
      display: "grid",
      rowGap: "12px",
      justifyItems: "center",
    }}
  >
    {children}
  </div>
);

export const NoCaptainDetails = () => (
  <Flex sx={{ flexDirection: "column", rowGap: "8px", textAlign: "center" }}>
    <Text variant="headline" sx={{ fontWeight: "bold" }}>
      No Captain Details
    </Text>
    <Text variant="body-large">Captain details are not available at the moment</Text>
  </Flex>
);

export const NoWrapText: FC<NoWrapTextProps> = ({ children, variant, bold = true }) => (
  <Text
    variant={variant}
    sx={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: bold ? "bold" : "normal",
    }}
  >
    {children}
  </Text>
);

const CaptainCard: FC<CaptainCardProps> = ({ children, ...props }) => {
  const { captain, busType, bus } = props;

  const captainRating = useMemo(() => {
    const rating = captain?.ratingData.sum / captain?.ratingData.count;
    return rating.toFixed(2);
  }, [captain?.ratingData?.count, captain?.ratingData?.sum]);

  return (
    <Flex css={{ alignContent: "center", backgroundColor: "whitesmoke", flexDirection: "column" }}>
      <Header>
        <CaptainCover hasCaptain={isNil(captain)}>
          <div
            sx={{
              position: "absolute",
              width: 72,
              height: 72,
              bottom: 0,
              left: 50,
              fontSize: 7,
            }}
          >
            {isNil(captain) ? (
              // TODO: revisit avatars
              <CustomAvatar name="?" bg="slategray" />
            ) : (
              <Avatar src={captain?.picture} isBordered name={captain?.name} size="medium" />
            )}
          </div>
        </CaptainCover>
      </Header>

      <CaptainDetailsWrapper>
        {isNil(captain) ? (
          <NoCaptainDetails />
        ) : (
          <Fragment>
            <Flex
              sx={{
                flexDirection: "row",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Icon name="captain" />
              <Text
                variant="body-large"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  width: "170px",
                  wordWrap: "break-word",
                }}
              >
                {captain?.name}
              </Text>
              <Flex>
                <Icon name="favorites" />
                <Text variant="body-small" sx={{ ml: 1, color: "opacity.black-54" }}>
                  {captainRating}
                </Text>
              </Flex>
            </Flex>

            <Flex sx={{ flexDirection: "row", alignItems: "center", columnGap: 2 }}>
              <Icon name="mobile" />
              <Text variant="body-large" sx={{ mr: 2 }}>
                ({captain?.phoneRegionCode}) {captain?.phone}
              </Text>
            </Flex>

            {isNil(busType) ? (
              <Text
                variant="body-large"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Bus details are not available at the moment
              </Text>
            ) : (
              <Tag
                variant="paleturquoise"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Icon name="bus_alt" size={16} />
                <NoWrapText variant="body-small" bold={false}>
                  {busType?.make} {busType?.model}
                </NoWrapText>
                <Text variant="body-small">-</Text>
                <NoWrapText variant="body-small">{bus?.plates}</NoWrapText>
              </Tag>
            )}
          </Fragment>
        )}
      </CaptainDetailsWrapper>
    </Flex>
  );
};

export default CaptainCard;
