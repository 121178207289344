/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type PopupData = { data: unknown; name?: string };

type CreatePrivateRideProps = {
  data: PopupData;
  onApprove: () => void;
  onDismiss: () => void;
  testId: string;
};

const CreatePrivateRidePopup: FC<CreatePrivateRideProps> = (props) => {
  const { data, onApprove, onDismiss, testId } = props;

  return (
    <ConfirmationPopup
      isOpen={!isNil(data)}
      onApprove={onApprove}
      testId={testId}
      onDismiss={onDismiss}
      title="Create Private Ride Request"
      message={
        <Text>
          You will proceed to confirm a private ride request for{" "}
          <span sx={{ fontWeight: "bold" }}>{data?.name}</span>{" "}
        </Text>
      }
      confirmButtonText="Confirm Private Ride Request"
    />
  );
};

export default CreatePrivateRidePopup;
