/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import { useAuthState } from "utils/useAuthState";
import Sidebar from "components/Sidebar";
import sidebarTabs from "constants/sidebarTabs";
import { Header } from "components/Header";
import Employees from "./employees";
import PrivateRide from "./private-ride";
import RideMonitoring from "./ride-monitoring";
import Shifts from "./shifts";
import AlertContainer from "@swvl/alert";
import { useAdminDetails } from "resources/useAdminDetails";
import { COLLAPSED_SIDEBAR_WIDTH } from "constants/styles";

/** Specify main modules */
export default function Main(props: unknown) {
  const { authData } = useAuthState();

  const { data: loggedInAdminDetails, isLoading: isAdminDetailsLoading } = useAdminDetails();
  const [hiddenTabs, setHiddenTabs] = useState<string[]>([]);
  useEffect(() => {
    setHiddenTabs([]);
    if (loggedInAdminDetails && !loggedInAdminDetails?.corporate?.private_ride_enabled) {
      setHiddenTabs(["private-ride"]);
    }
  }, [loggedInAdminDetails]);
  useEffect(() => {
    // TODO Remove this after migrating from to GTM
    // eslint-disable-next-line
    // @ts-ignore
    ReactGA.initialize(window.__env__.GA_TRACKING_ID, {
      testMode: window.__env__.NODE_ENV === "test",
      gaOptions: {
        userId: authData.user?._id,
      },
    });

    const isProd = window.__env__.NODE_ENV === "production";
    const tagManagerArgs = {
      gtmId: window.__env__.GTM_ID,
      auth: isProd ? window.__env__.GTM_LIVE_ENV_AUTH : window.__env__.GTM_DEV_ENV_AUTH,
      preview: isProd ? window.__env__.GTM_LIVE_ENV_NAME : window.__env__.GTM_DEV_ENV_NAME,
      dataLayer: {
        userId: authData.user?._id,
        corporateId: authData.user?.corporate?._id,
      },
    };

    TagManager.initialize(tagManagerArgs);
  }, [authData.user?._id, authData.user?.corporate?._id]);

  return (
    <div
      sx={{
        position: "relative",
      }}
    >
      <div
        sx={{
          display: "grid",
          gridTemplateAreas: `"header header" "sidebar main"`,
          gridTemplateColumns: `${COLLAPSED_SIDEBAR_WIDTH}px 1fr`,
          gridTemplateRows: "64px 1fr",
        }}
      >
        <Sidebar hiddenTabs={hiddenTabs} tabs={sidebarTabs} />
        <Header css={{ gridArea: "header" }} />
        <div css={{ gridArea: "main" }}>
          <Route exact path="/">
            <Redirect to="/employees" />
          </Route>
          <Route key="Employees" path={"/employees"} component={Employees} />
          {loggedInAdminDetails && loggedInAdminDetails?.corporate?.private_ride_enabled && (
            <Route key="PrivateRide" path={"/private-ride"} component={PrivateRide} />
          )}

          <Route key="Shifts" path={"/shifts"} component={Shifts} />
          <Route key="RideMonitoring" path={"/ride-monitoring"} component={RideMonitoring} />
        </div>
      </div>

      <AlertContainer position="bottom-right" />
    </div>
  );
}
