// Idea from https://github.com/OscarGalindo/react-query-filtering-example/blob/master/src/hooks/filters.hook.tsx
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { parse } from "query-string";
import toPairs from "lodash/fp/toPairs";
import fromPairs from "lodash/fp/fromPairs";
import merge from "lodash/fp/merge";
import flatten from "lodash/fp/flatten";
import isNil from "lodash/fp/isNil";
import isEmpty from "lodash/fp/isEmpty";
import pick from "lodash/fp/pick";
import { addToQuery, removeFromQuery } from "utils/";

export interface FilterMap {
  [key: string]: string | string[];
}

/**
 * @typedef {Object} FilterObject
 * @property {(newFilters: any) => void} apply  to reapply filters
 * @property {FilterMap} current  current applied filters
 * @property {boolean} isEmpty  check filters is empty
 */
/**
 * useFilter hook for constructing filter objects
 * @param {string[]} keys filters keys
 * @param {boolean} isSingleValue if filter can have single value. for multiple values set it to be false
 * @return {FilterObject} filterObject
 */
export const useFilters = (keys: string[], isSingleValue: boolean = true) => {
  const history = useHistory();
  const location = useLocation();
  const initialFilter = keys.reduce(
    (old, curr) => ({
      ...old,
      [curr]: isSingleValue ? null : [],
    }),
    {}
  );
  const [filters, setFilters] = useState<FilterMap>(merge(initialFilter, {}));

  useEffect(() => {
    const routeFilters = fromPairs(
      toPairs(parse(location.search)).map(([key, value]) => [
        key,
        isSingleValue ? value : flatten([value]),
      ])
    );
    if (!isEmpty(routeFilters)) updateFilters(routeFilters);
  }, [history.location.search]);

  const updateFilters = useMemo(
    () => (newFilters: FilterMap): FilterMap => {
      const focusFields = pick(keys, newFilters) as FilterMap;
      const sanitizedFilters = fromPairs(
        toPairs(focusFields).filter(([_, value]) => !(isNil(value) || isEmpty(value)))
      );
      const appliedFilters = merge(filters, sanitizedFilters);
      setFilters(appliedFilters);
      return sanitizedFilters;
    },
    []
  );

  const allFieldsAreEmpty = (filters: FilterMap): boolean =>
    toPairs(filters).every((filter) => isEmpty(filter));

  return {
    apply: (newFilters) => {
      Object.entries(newFilters).forEach(([key, value]) => {
        if (isNil(value)) {
          removeFromQuery(key, history);
        } else {
          addToQuery(key, value as string, history);
        }
      });
    },
    current: filters,
    isEmpty: allFieldsAreEmpty(filters),
  };
};
