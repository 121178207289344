/** @jsxRuntime classic */
/** @jsx jsx */
import { Flex, jsx, Text } from "theme-ui";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import startCase from "lodash/fp/startCase";
import isNil from "lodash/fp/isNil";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import Spinner from "@swvl/spinner";
import { showAlert } from "@swvl/alert";
import Page from "components/PageWrapper";
import { useHistory, useLocation } from "react-router";
import { pluralize, removeFromQuery, toCamelCase } from "utils";
import { usePaginatedShifts } from "resources/usePaginatedShifts";
import { useAssignEmployeesToShift } from "resources/useAssignEmployeesToShift";
import { useUnassignEmployeesFromShift } from "resources/useUnassignEmployeesFromShift";
import NoListResults from "components/NoListResults";
import { useFilters } from "utils/useFilters";
import Filters from "./ShiftAssignmentFilters";
import ShiftAssignmentCard from "./ShiftAssignmentCard";
import ShiftAssignmentConflictPopup from "./ShiftAssignmentConflictPopup";
import { ShiftConflicts } from "./useShiftConflicts";

const ShiftAssignment: FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation<{
    selectedEmployeesIds: string[];
  }>();
  const employeesIds = useMemo(() => location?.state?.selectedEmployeesIds, [location]);
  const filters = useFilters(
    ["start_date", "end_date", "date", "time", "status", "office_location", "direction"],
    true
  );
  const {
    data: shiftsData,
    isFetching: isShiftsLoading,
    hasNextPage,
    fetchNextPage: handleGetMoreShifts,
  } = usePaginatedShifts({
    filters: filters.current,
    enabled: employeesIds?.length && employeesIds?.length > 0,
  });

  const initialShiftsMap: [string, boolean][] = shiftsData.shifts.map((shift) => [shift.id, true]);
  const [shiftsMap, setShiftsMap] = useState(
    new Map<string, boolean>([...initialShiftsMap])
  );
  const [isConflictShiftsPopupVisible, setConflictShiftsPopupVisible] = useState(false);
  const [shiftConflicts, setShiftConflicts] = useState<ShiftConflicts>(null);
  const [referenceShift, setReferenceShift] = useState(null);

  const { mutateAsync: assignToShift, isLoading: isAssigning } = useAssignEmployeesToShift();
  const {
    mutateAsync: unassignFromShift,
    isLoading: isUnassigning,
  } = useUnassignEmployeesFromShift();

  const shouldShowLoadMoreButton = !isShiftsLoading && hasNextPage;

  const handleFilterChange = useCallback(
    (key: string) => (filter) => {
      if (key === "date" && !isNil(filter.value)) {
        filters.apply({
          start_date: null,
          end_date: null,
        });
      }

      if ((key === "start_date" || key === "end_date") && !isNil(filter.value)) {
        filters.apply({ date: null });
      }

      filters.apply({ [key]: filter.value });
    },
    []
  );

  // only active shifts that should be shown
  useEffect(() => {
    if (!employeesIds?.length) {
      history.replace("/employees");
      showAlert({
        message: "No selected employees for shift assignment.",
        type: "warning",
      });
    } else {
      filters.apply({ status: "active" });
    }
  }, []);

  const handleAssignToShift = async (id: string) => {
    const referenceShift = shiftsData.shifts?.find((shift) => shift.id === id);
    if (referenceShift) {
      setReferenceShift(referenceShift);
    }
    try {
      await assignToShift({
        shift_id: id,
        business_profile_ids: employeesIds,
      });
      setShiftsMap((prev) => new Map([...prev, [id, false]]));
      showAlert({
        message: `You have replaced ${pluralize(
          employeesIds?.length,
          "Employee"
        )} old shift with the new shift successfully`,
        type: "success",
      });
    } catch (error) {
      // Disclaimer: this is not typed yet
      if (error?.data?.statusCode === 400 && error?.data?.message === "Conflicting Shifts") {
        const conflicts: ShiftConflicts = error?.data?.conflicts.map((conflict) => ({
          employeeName: conflict?.name,
          employeeId: conflict?._id,
          existingShifts: conflict?.shifts.map((shift) => toCamelCase(shift)),
        }));
        setShiftConflicts(conflicts);
        setConflictShiftsPopupVisible(true);
      } else {
        showAlert({
          message: error?.data?.message || "Something went wrong. Please, try again later",
          type: "error",
        });
      }
    }
  };

  const handleUnAssignToShift = async (id: string) => {
    try {
      await unassignFromShift({
        shift_ids: [id],
        business_profile_ids: employeesIds,
      });
      setShiftsMap((prev) => new Map([...prev, [id, true]]));
      showAlert({
        message: `You have unassigned ${pluralize(
          employeesIds?.length,
          "Employee"
        )} from shift successfully`,
        type: "success",
      });
    } catch (error) {
      showAlert({
        message: "Something went wrong. Please, try again later",
        type: "error",
      });
    }
  };

  useEffect(() => {
    shiftsData.shifts.forEach((shift) => {
      if (!shiftsMap.has(shift.id)) {
        setShiftsMap((prev) => new Map([...prev, [shift.id, true]]));
      }
    });
  }, [shiftsData]);

  return (
    <Page
      tagName="employees-shift-assignment-page"
      title="Employees | Shift Assignment"
      header={
        <header
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text variant="subhead" sx={{ fontWeight: "bold" }}>
            {pluralize(employeesIds?.length ? employeesIds?.length : 0, "Employee")} Selected
          </Text>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isAssigning || isUnassigning ? (
              <Spinner size="20px" color="lightcoral" sx={{ mr: 2 }} />
            ) : null}
            <Button
              variant="primary"
              icon={<Icon name={"completed"} size={15} fill={"white"} />}
              iconPosition={"left"}
              onClick={() => {
                history.replace("/employees");
              }}
            >
              Done
            </Button>
          </div>
        </header>
      }
    >
      <Text variant="body-large" sx={{ fontWeight: "bold", mx: "26px", mt: 3 }}>
        All Shifts
      </Text>
      <Filters onFilterChange={handleFilterChange} initialFilters={filters.current} />
      <div sx={{ mx: 3 }}>
        {shiftsData?.shifts.map(
          ({ id, officeLocation, startDate, endDate, direction, weekdays, time, isActive }) => (
            <ShiftAssignmentCard
              id={id}
              direction={startCase(direction)}
              endDate={endDate}
              officeLocation={officeLocation}
              startDate={startDate}
              weekdays={weekdays}
              canAssign={shiftsMap.get(id)}
              disableAssign={isAssigning || isUnassigning}
              time={time}
              key={id}
              handleAssignToShift={handleAssignToShift}
              handleUnAssignToShift={handleUnAssignToShift}
            />
          )
        )}
        {isShiftsLoading ? (
          <Spinner
            color="primary"
            css={{
              display: "block",
              margin: "16px auto",
            }}
          />
        ) : shiftsData.shifts.length == 0 ? (
          <Flex
            css={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <NoListResults
              title="No Active Shifts"
              sx={{
                width: "800px",
              }}
              body="There are no existing active shifts at the moment."
              hasBackground
            />
          </Flex>
        ) : null}
        {shouldShowLoadMoreButton && (
          <Button
            variant="secondary"
            icon={<Icon name="updated" size={20} fill={"currentcolor"} />}
            iconPosition="left"
            onClick={() => handleGetMoreShifts()}
            sx={{ margin: "16px auto" }}
          >
            Load More
          </Button>
        )}
      </div>
      {isConflictShiftsPopupVisible ? (
        <ShiftAssignmentConflictPopup
          isOpen={isConflictShiftsPopupVisible}
          newShift={referenceShift}
          shiftConflicts={shiftConflicts}
          onDismiss={() => {
            setConflictShiftsPopupVisible(false);
          }}
          postSubmit={(shouldDisplayAssignButton = true) => {
            setConflictShiftsPopupVisible(false);
            setShiftsMap(
              (prev) => new Map([...prev, [referenceShift.id, shouldDisplayAssignButton]])
            );
          }}
        />
      ) : null}
    </Page>
  );
};

export default ShiftAssignment;
