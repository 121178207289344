import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { getFromQuery } from "utils";
import { showAlert } from "@swvl/alert";
import { useLocation } from "react-router-dom";

interface ActivateEmployeeRequest {
  id: string;
}

const activateEmployee = async ({ id }: ActivateEmployeeRequest): Promise<string> => {
  const response = await apiInstance.put<undefined, string>(`business-profile/${id}/activate`);

  return response;
};

const useActivateEmployee = () => {
  const successMessage = "User has been successfully activated";
  const queryClient = useQueryClient();
  const location = useLocation();
  const mutation = useMutation(activateEmployee, {
    mutationKey: "employee/deactivate",
    onSuccess: (_data, { id }) => {
      const page = getFromQuery("page", location.search);
      queryClient.invalidateQueries(["employees", +page]);
      queryClient.invalidateQueries(["employee/profile", id]);
      showAlert({
        message: successMessage,
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useActivateEmployee };
