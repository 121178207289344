import { useEffect } from "react";
import { Route, useRouteMatch, useHistory, Switch } from "react-router-dom";
import isNil from "lodash/fp/isNil";
import Login from "./Login";
import AuthResetPassword from "./ResetPassword";
import useAuthState from "utils/useAuthState";

export default function Auth() {
  const { path } = useRouteMatch();
  const {
    authData: { token },
  } = useAuthState();
  const history = useHistory();

  useEffect(() => {
    if (isNil(token) && history.location.pathname !== `${path}/reset-password`) {
      history.replace(`${path}/login`);
    }
  }, [token]);

  return (
    <Switch>
      <Route key="Login" exact path={`${path}/login`} component={Login} />
      <Route
        key="ResetPassword"
        exact
        path={`${path}/reset-password`}
        component={AuthResetPassword}
      />
    </Switch>
  );
}
