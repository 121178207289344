/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Radio, Label, Divider, Flex, Grid, Box } from "theme-ui";
import { FC, Fragment, useState } from "react";
import { format } from "date-fns";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { showAlert } from "@swvl/alert";
import Spinner from "@swvl/spinner";
import Button from "@swvl/button";
import { convertMinutesAmPmTime, getWeekDaysShort, pluralize } from "utils";
import { NameAvatar } from "components/Avatar";
import { useAssignEmployeesToShift } from "resources/useAssignEmployeesToShift";
import { useUnassignEmployeesFromShift } from "resources/useUnassignEmployeesFromShift";
import useShiftConflicts, { Shift, ShiftConflicts } from "./useShiftConflicts";

type ShiftAssignmentConflictPopupProps = {
  newShift: Shift;
  shiftConflicts: ShiftConflicts;
  withClose?: boolean;
  onDismiss?: () => void;
  postSubmit?: (shouldDisplayAssignButton?: boolean) => void;
  isOpen: boolean;
};

const ShiftAssignmentConflictPopup: FC<ShiftAssignmentConflictPopupProps> = ({
  newShift,
  shiftConflicts,
  isOpen,
  withClose,
  onDismiss,
  postSubmit,
}) => {
  const ShiftDetails: FC<Shift> = ({
    startDate,
    endDate,
    time,
    weekdays,
    direction,
    officeLocation,
  }) => (
    <div
      sx={{
        display: "column",
        alignItems: "center",
        fontSize: "13px",
      }}
    >
      <Flex sx={{ alignItems: "center", mb: 2 }}>
        <Icon name="scheduling" size={14} fill="opacity.black-54" />
        <Text sx={{ ml: 1 }}>
          {format(new Date(startDate), "dd/MM/yyyy")} - {format(new Date(endDate), "dd/MM/yyyy")}
        </Text>
      </Flex>
      <Flex sx={{ alignItems: "center", my: 2 }}>
        <Icon name="clock_filled" size={14} fill="opacity.black-54" />
        <Text sx={{ ml: 1 }}>{convertMinutesAmPmTime(time).timeString}</Text>
      </Flex>
      <Flex sx={{ alignItems: "center", my: 2 }}>
        <Icon name="scheduling" size={14} fill="opacity.black-54" />
        <Text sx={{ ml: 1 }}>{getWeekDaysShort(weekdays).join(", ")}</Text>
      </Flex>
      <Flex sx={{ alignItems: "center", my: 2 }}>
        <Icon name="shift_direction" size={14} fill="opacity.black-54" />
        <Text sx={{ ml: 1 }}>{direction === "to_office" ? "To Office" : "From Office"}</Text>
      </Flex>
      <Flex sx={{ alignItems: "center", mt: 2 }}>
        <Icon name="office_address" size={14} fill="opacity.black-54" />
        <Text sx={{ ml: 1 }}>{officeLocation.name}</Text>
      </Flex>
    </div>
  );

  const Content = () => {
    const { mutateAsync: assignToShift } = useAssignEmployeesToShift();
    const { mutateAsync: unassignFromShift } = useUnassignEmployeesFromShift();
    const [isResolveLoading, setResolveLoading] = useState(false);
    const {
      assignAllEmployees,
      assignEmployee,
      isAllEmployeesAssigned,
      isAllEmployeesUnAssigned,
      resolvedEmployees,
      unAssignAllEmployees,
      unAssignEmployee,
    } = useShiftConflicts(shiftConflicts);

    // Handle submit
    const formSubmit = async (event) => {
      event.preventDefault();
      setResolveLoading(true);
      const resolutionData = Object.entries(resolvedEmployees)
        .filter((resolvedEmployee) => resolvedEmployee[1])
        .map((toBeAssignedEmployee) => {
          const employeeId = toBeAssignedEmployee[0];
          const existingConflict = shiftConflicts.find(
            (conflict) => conflict.employeeId === employeeId
          );
          return {
            employeeId,
            toBeUnassignedShifts: existingConflict.existingShifts.map((shift) => shift.id),
          };
        });

      const assignData = {
        business_profile_ids: resolutionData.map((resolved) => resolved.employeeId),
        shift_id: newShift.id,
      };

      const unassignData = {
        business_profile_ids: resolutionData.map((resolved) => resolved.employeeId),
        shift_ids: [...new Set(...resolutionData.map((resolved) => resolved.toBeUnassignedShifts))],
      };

      try {
        let shouldDisplayAssign = true;
        if (unassignData.business_profile_ids.length > 0) {
          await unassignFromShift(unassignData);
        }
        if (assignData?.business_profile_ids.length > 0) {
          await assignToShift(assignData);
          showAlert({
            message: `${pluralize(
              assignData?.business_profile_ids.length,
              "Employee"
            )} resolved and assigned to the new shift successfully`,
            type: "success",
            options: {
              duration: 5000,
            },
          });
          shouldDisplayAssign = false;
        }
        setResolveLoading(false);
        postSubmit(shouldDisplayAssign);
      } catch (error) {
        showAlert({
          message:
            error?.data?.message || "Something went wrong try to resolve these conflicts later!",
          type: "error",
        });
        setResolveLoading(false);
      }
    };

    return (
      <div sx={{ mt: 3 }} data-testid="confirmation-popup">
        <div sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
          <Icon name="warning" size={50} fill="#FFC659" />
        </div>
        <Text sx={{ mb: 3, fontWeight: "bold" }} variant={"headline"}>
          Resolve Remaining Conflicts
        </Text>
        <Text sx={{ mx: 5, mb: 3, color: "opacity.black-54" }} variant={"body-small"}>
          There is already an existing shift assigned to {shiftConflicts.length} employees on some
          of the entered dates. Do you want to replace old shift with the new one to all employees?
        </Text>
        <form onSubmit={formSubmit}>
          <Grid
            gap={2}
            columns={[3, "2fr 3fr 3fr"]}
            sx={{
              alignItems: "flex-start",
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "opacity.black-08",
              px: 3,
            }}
          >
            <Flex sx={{ alignItems: "center", py: 2 }}>
              <Text variant="body-small" sx={{ fontWeight: "bold", my: 1 }}>
                Employee Name
              </Text>
            </Flex>
            <Label sx={{ alignItems: "center", py: 2 }}>
              <Radio
                name="resolve_by_all"
                value="existing"
                checked={isAllEmployeesUnAssigned}
                onChange={unAssignAllEmployees}
              />
              <Text variant="body-small" sx={{ fontWeight: "bold" }}>
                Existing Shift
              </Text>
            </Label>
            <Label sx={{ alignItems: "center", py: 2, px: "12px" }}>
              <Radio
                name="resolve_by_all"
                value="new"
                checked={isAllEmployeesAssigned}
                onChange={assignAllEmployees}
              />
              <Text variant="body-small" sx={{ fontWeight: "bold" }}>
                New Shift
              </Text>
            </Label>
          </Grid>
          <Grid
            gap={2}
            columns={[3, "2fr 3fr 3fr"]}
            sx={{
              alignItems: "flex-start",
              overflowY: "auto",
              maxHeight: "400px",
              rowGap: 2,
              gridTemplateColumns: "repeat(5, 1fr)",
            }}
          >
            {shiftConflicts.map(({ employeeId, employeeName, existingShifts }, conflictIndex) => {
              return (
                <Fragment key={`${conflictIndex}-${employeeId}`}>
                  <Box
                    sx={{ height: "100%", mr: "-8px", py: 3, px: 2 }}
                    bg={conflictIndex % 2 === 0 ? "whitesmoke" : "transparent"}
                  >
                    <NameAvatar name={employeeName}>
                      <Text variant="body-small">{employeeName}</Text>
                    </NameAvatar>
                  </Box>
                  <Flex
                    sx={{
                      flexDirection: "row",
                      rowGap: 2,
                      mr: "-8px",
                      py: 3,
                      px: 2,
                      bg: conflictIndex % 2 === 0 ? "whitesmoke" : "transparent",
                    }}
                  >
                    <Label sx={{ flexBasis: 35 }}>
                      <Radio
                        name={employeeId}
                        value={existingShifts.map((shift) => shift.id).join(",")}
                        checked={!resolvedEmployees[employeeId]}
                        onChange={() => unAssignEmployee(employeeId)}
                      />
                    </Label>
                    <Flex sx={{ flexDirection: "column" }}>
                      {existingShifts.map((shift, index) => (
                        <Fragment key={shift.id}>
                          <ShiftDetails {...shift} />
                          {index < existingShifts.length - 1 ? (
                            <Divider color="opacity.black-08" sx={{ mx: "-40px" }} />
                          ) : null}
                        </Fragment>
                      ))}
                    </Flex>
                  </Flex>
                  <Label
                    sx={{
                      height: "100%",
                      py: 3,
                      px: 2,
                      bg: conflictIndex % 2 === 0 ? "whitesmoke" : "transparent",
                    }}
                  >
                    <Radio
                      name={`${employeeId}`}
                      value={newShift.id}
                      key={employeeId}
                      checked={resolvedEmployees[employeeId]}
                      onChange={() => assignEmployee(employeeId)}
                    />
                    <ShiftDetails {...newShift} />
                  </Label>
                </Fragment>
              );
            })}
          </Grid>
          <div
            sx={{
              display: "flex",
              mt: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button onClick={onDismiss} variant={"gray"} type="button">
              Dismiss
            </Button>
            <Button
              sx={{
                "> *": {
                  pointerEvents: "none",
                },
                ml: 4,
                width: "100px",
              }}
              type="submit"
              disabled={isResolveLoading}
            >
              <Flex sx={{ justifyContent: "space-around" }}>
                <Text>Submit</Text>
              </Flex>
            </Button>
          </div>
          {isResolveLoading ? (
            <Spinner
              color="primary"
              sx={{
                width: 25,
                position: "fixed",
                bottom: "0px",
                right: "10px",
              }}
            />
          ) : null}
        </form>
      </div>
    );
  };

  return (
    <Popup
      withClose={withClose}
      closePopup={onDismiss}
      isOpen={isOpen}
      width={800}
      contentStyle={{
        top: "54%",
        pb: 3,
      }}
    >
      <Content />
    </Popup>
  );
};

export default ShiftAssignmentConflictPopup;
