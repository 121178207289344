import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import omit from "lodash/fp/omit";
import { dropNulls, toSnakeCase } from "utils";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

export type Gender = "male" | "female" | "other";

export interface CreateEmployeeRequest {
  name: string;
  email?: string;
  phone: string;
  phone_region_code: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  employee_id?: string;
  gender?: string;
}

export interface CreateEmployeeResponse {
  _id: string;
  name: string;
  email: string;
  corporate: string;
  employee_id: string;
  gender: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt?: Date;
    updated_by?: string;
  };
  active: boolean;
  createdAt: string;
  deleted: boolean;
  phone: string;
  phone_region_code: string;
  is_app_registered: boolean;
  updatedAt: string;
}
export interface CreateEmployeeFormData {
  name: string;
  phone: string;
  phoneRegionCode?: string;
  email?: string;
  employeeId?: string;
  pickup?: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  gender?: Gender;
}

// Transforms
const transformRequest = (form: CreateEmployeeFormData): CreateEmployeeRequest => {
  return toSnakeCase(dropNulls(form)) as CreateEmployeeRequest;
};

// Resources
const createEmployee = async (form: CreateEmployeeFormData) => {
  const data = transformRequest(form);
  const response = await apiInstance.post<CreateEmployeeRequest, CreateEmployeeResponse>(
    "user/whitelist",
    data
  );
  return response;
};

const useCreateEmployee = () => {
  const history = useHistory();
  const mutation = useMutation(createEmployee, {
    mutationKey: "employee/create",
    onSuccess: (data) => {
      history.push(`/employees?employee-id=${data._id}`);
      showAlert({
        message: "You have added a new employee successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useCreateEmployee, createEmployee };
