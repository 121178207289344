/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import theme from "@swvl/theme";
import Icon from "@swvl/icon";
import Spinner from "@swvl/spinner";
import Button from "@swvl/button";

type HeaderProps = {
  openBulkUploadPopup: () => void;
  isLoading: boolean;
  onDeleteAllEmployees: () => void;
  showDeleteAllButton: boolean;
};
export const Header: FC<HeaderProps> = ({
  openBulkUploadPopup,
  isLoading,
  onDeleteAllEmployees,
  showDeleteAllButton,
}) => {
  const history = useHistory();
  return (
    <header
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: "bold" }}>
        {" "}
        Employees Dashboard
      </Text>
      <div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {isLoading ? (
          <Spinner
            sx={{
              mx: 2,
            }}
            size={20}
            color="lightcoral"
          />
        ) : null}
        <Button
          variant="primary"
          icon={<Icon name="add" size={20} fill={"white"} />}
          iconPosition="left"
          onClick={() => {
            history.push("/employees/create");
          }}
        >
          Add Employee
        </Button>
        {showDeleteAllButton ? (
          <Button
            variant="primary"
            icon={<Icon name="delete" size={20} fill={"white"} />}
            iconPosition="left"
            onClick={onDeleteAllEmployees}
            sx={{ ml: 2 }}
          >
            Delete All Employees
          </Button>
        ) : null}
        <Button
          id="click-employee-bulk-upload"
          variant="secondary"
          icon={<Icon name="upload" size={20} fill={"currentcolor"} />}
          iconPosition="left"
          onClick={openBulkUploadPopup}
          sx={{ ml: 2 }}
        >
          Bulk Upload
        </Button>
        <div
          data-for="bulk-upload-info"
          data-tip="Add and merge to existing list.<br/>Please upload the correctly filled template"
          data-iscapture="true"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <Icon name="info" size={20} fill={theme.colors.opacity["black-38"]} />
        </div>
      </div>
      <ReactTooltip
        id="bulk-upload-info"
        place="bottom"
        effect="solid"
        type="dark"
        multiline
        offset={{
          bottom: 50,
          right: 10,
        }}
        arrowColor={"transparent"}
      />
    </header>
  );
};

export default Header;
