/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";
import { EmployeeModel } from "resources/useEmployees";

export type ActivationPopupData = Partial<EmployeeModel> & { value: unknown };

type ActivationPopupProps = {
  data: ActivationPopupData;
  onApprove: () => void;
  onDismiss: () => void;
  isSubmitting?: boolean;
};

const ActivationPopup: FC<ActivationPopupProps> = (props) => {
  const { data, onApprove, onDismiss, isSubmitting = false } = props;

  const ActivationMessage = () => (
    <Text data-testid="activate-deactivate">
      You will proceed to activate <span sx={{ fontWeight: "bold" }}>{data?.name}</span>'s profile.
    </Text>
  );
  const DeactivationMessage = () => (
    <Text data-testid="activate-deactivate">
      You will proceed to deactivate <span sx={{ fontWeight: "bold" }}>{data?.name}</span>'s
      profile.{" "}
      {data?.shiftAssigned && (
        <span>
          The employee will be <span sx={{ fontWeight: "bold" }}>untagged from all shifts</span>. Do
          you wish to proceed with the deactivation?
        </span>
      )}
    </Text>
  );
  return (
    <ConfirmationPopup
      isOpen={!isNil(data)}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title="Change Status"
      message={data?.value ? <DeactivationMessage /> : <ActivationMessage />}
      confirmButtonText={data?.value ? "Deactivate" : "Activate"}
      isSubmitting={isSubmitting}
    />
  );
};

export default ActivationPopup;
