import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { FilterMap } from "utils/useFilters";

interface editShiftActivationStatusRequest {
  id: string;
  isActive: boolean;
}

const editShiftActivationStatus = async ({
  id,
  isActive,
}: editShiftActivationStatusRequest): Promise<string> => {
  const response = await apiInstance.put<undefined, string>(`shifts/${id}/active`, {
    is_active: isActive,
  });

  return response;
};

const useEditShiftActivationStatus = ({ page, filters }: { page: number; filters?: FilterMap }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(editShiftActivationStatus, {
    mutationKey: "shifts/active",
    onSuccess: (_data, { isActive, id }) => {
      queryClient.invalidateQueries(["shifts", page, filters]);
      queryClient.invalidateQueries(["shift/details", id]);
      showAlert({
        message: `Shift has been successfully ${isActive ? "activated" : "deactivated"}`,
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useEditShiftActivationStatus };
