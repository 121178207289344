/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui";
import React, { FC, ReactElement, useState } from "react";
import Popup from "@swvl/popup";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import Spinner from "@swvl/spinner";
import ReactDOM from "react-dom";
import Modal from "react-modal";

export type TrackingParams = {
  actionType?: string;
  resourceType?: string;
  resourceId?: string;
};

const customStyles = {
  content: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    bottom: "none",
    width: "480px",
    background: "white",
    borderRadius: "8px",
    padding: "15px",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.54)",
    zIndex: 1040,
  },
};
export type ContentProps = {
  title?: string;
  key?: string;
  testId?: string;
  message?: ReactElement;
  trackingParams?: TrackingParams;
  confirmButtonText?: string;
  confirmDisabled?: boolean;
  confirmVisible?: boolean;
  withClose?: boolean;
  onApprove?: () => void;
  onDismiss: () => void;
  isSubmitting?: boolean;
};
export interface ConfirmationModalProps extends ContentProps {
  isOpen: boolean;
}

const Content: FC<ContentProps> = (props) => {
  const {
    title,
    message,
    confirmButtonText,
    testId,
    trackingParams = {},
    confirmDisabled = false,
    confirmVisible = true,
    isSubmitting = false,
    onApprove,
    onDismiss,
    children,
  } = props;
  const { actionType, resourceType, resourceId } = trackingParams;
  return (
    <div sx={{ my: 4 }} data-testid="confirmation-popup">
      <div sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
        <Icon name="warning" size={50} />
      </div>
      <Text sx={{ mb: 3, fontWeight: "bold" }} variant={"headline"}>
        {title}
      </Text>
      <div sx={{ mb: 4 }}>{message}</div>
      {children}
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={onDismiss} variant={"gray"} type="button">
          Dismiss
        </Button>
        {confirmVisible && (
          <Button
            sx={{
              ml: 4,
              "> *": {
                pointerEvents: "none",
              },
            }}
            onClick={onApprove}
            type="button"
            disabled={confirmDisabled}
            data-action-type={actionType}
            data-resource-type={resourceType}
            data-resource-id={resourceId}
            data-test-id={testId}
          >
            <Flex sx={{ justifyContent: "space-around" }}>
              <Text>{confirmButtonText}</Text>
              {isSubmitting ? (
                <div sx={{ ml: 3 }}>
                  <Spinner size={15} color="currentcolor" />{" "}
                </div>
              ) : null}
            </Flex>
          </Button>
        )}
      </div>
    </div>
  );
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ children, ...props }) => {
  const { isOpen, withClose, onDismiss, key } = props;

  if (!isOpen) return null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      style={customStyles}
      contentLabel="Rejection Popup"
    >
      <Content {...props}>{children}</Content>
    </Modal>
  );
};

export default ConfirmationModal;
