import { VARIANT } from "@swvl/tag";
// TODO: move GOOGLE_MAPS_API_KEY to env, when we make sure it's not accessible in window
export const GOOGLE_MAPS_API_KEY = "AIzaSyCjw36AhdkfnXwHE5JO_B3BRMBlf1GvXsU";

export const RIDE_STATUS_TAB = {
  ongoing: "ongoing",
  upcoming: "upcoming",
  past: "past",
};

export const RIDE_COMPLETED_STATUS = "completed";

export const RIDE_IN_PROGRESS = {
  started: "started",
  stopped: "stopped",
};

export const RIDE_STATUS_OPTIONS = [
  { value: "scheduled", label: "Scheduled" },
  { value: "started,stopped", label: "In Progress" },
  { value: "completed", label: "Completed" },
  { value: "cancelled", label: "Cancelled" },
  { value: "corporate_revised", label: "Corporate Revised" },
];

export const RIDE_DELAY_OPTIONS = [
  { value: "5", label: "> 5 mins" },
  { value: "10", label: "> 10 mins" },
  { value: "15", label: "> 15 mins" },
  { value: "20", label: "> 20 mins" },
  { value: "25", label: "> 25 mins" },
];

export const RIDE_STATUS_TAG_COLOR = {
  started: VARIANT.goldenrod,
  completed: VARIANT.mediumseagreen,
  scheduled: VARIANT.slategray,
  cancelled: VARIANT.crimson,
  stopped: VARIANT.goldenrod,
  corporate_revised: VARIANT.deepskyblue,
} as Record<string, keyof typeof VARIANT>;

export const RIDE_STATUS_TAG_NAME = {
  started: "In Progress",
  completed: "Completed",
  scheduled: "Scheduled",
  cancelled: "Cancelled",
  stopped: "In Progress",
  corporate_revised: "Revised",
};

export const BOOKING_STATUS_TAG_COLOR = {
  booked: VARIANT.goldenrod,
  checked_in: VARIANT.mediumslateblue,
  missed: VARIANT.skyblue,
  cancelled: VARIANT.crimson,
  completed: VARIANT.mediumseagreen,
  transferred: VARIANT.deepskyblue,
};

export const BOOKING_STATUS_TAG_NAME = {
  booked: "Booked",
  checked_in: "Checked-In",
  missed: "Missed",
  completed: "Completed",
  cancelled: "Cancelled",
  transferred: "Transferred",
};

export const RIDES_LIST_LIMIT = 10;
export const RIDE_COVER_HEIGHT = 136;

export const GOOGLE_MAP_STYLE = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

export const DEFAULT_STATION_DELAY_THRESHOLD = 5;
export const DELAY_INTERVAL = 60 * 1000;
export const DELAYED_BUS_COLOR = "#EF4349";
