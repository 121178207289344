/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import { FC, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import Spinner from "@swvl/spinner";
import { getFromQuery } from "utils/";
import errorBackground from "assets/error.svg";
import { useValidateResetPassword } from "resources/useValidateResetPassword";
import resetPasswordBackground from "assets/login@1x.png";
import { BackgroundImage, FormWrapper } from "./styled";
import Form from "./ResetPasswordForm";

const ResetPassword: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const token = getFromQuery("token", location.search);

  const appInsights = useAppInsightsContext();
  const trackResetPassword = useTrackMetric(appInsights, "ResetPassword.Page");

  const {
    isError,
    status,
    validationErrorMessage,
    refetch: validateResetPassord,
  } = useValidateResetPassword(token);

  const isLoading = status === "loading";

  useEffect(() => {
    trackResetPassword();
    validateResetPassord();
  }, []);

  const handleReturnToLogin = useCallback(() => {
    history.push("/auth/login");
  }, []);

  return isLoading ? (
    <div sx={{ position: "relative" }}>
      <Spinner
        color="primary"
        sx={{
          width: 50,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  ) : (
    <BackgroundImage img={isError ? errorBackground : resetPasswordBackground} gradient={!isError}>
      <FormWrapper>
        {isError ? (
          <Flex sx={{ flexDirection: "column", marginLeft: 3 }}>
            <Text variant="title-large" sx={{ fontWeight: "bold", mb: "1rem" }}>
              {validationErrorMessage}
            </Text>
            <Button
              sx={{
                width: "150px",
                height: "38px",
              }}
              variant="primary"
              icon={<Icon name="right_arrow" size={15} fill="currentcolor" />}
              iconPosition="right"
              onClick={handleReturnToLogin}
            >
              <span sx={{ fontWeight: "bold", fontSize: "15px" }}>Return to login</span>
            </Button>
          </Flex>
        ) : (
          <Form token={token} />
        )}
      </FormWrapper>
    </BackgroundImage>
  );
};

export default ResetPassword;
