export const PRIVATE_RIDES_LIST_PAGE_SIZE = 10;
export const PRIVATE_REQUEST_STATUS_OPTIONS = [
  { label: "All", value: null },
  {
    label: "Pending For Approval",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];
