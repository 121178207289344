import { useMutation } from "react-query";
import apiInstance from "api";

interface AssignEmployeesToShiftRequest {
  business_profile_ids: string[];
  shift_id: string;
}

const assignEmployeesToShift = async ({
  business_profile_ids,
  shift_id,
}: AssignEmployeesToShiftRequest): Promise<string> => {
  const response = await apiInstance.put<AssignEmployeesToShiftRequest, string>("shifts/assign", {
    business_profile_ids,
    shift_id,
  });

  return response;
};

const useAssignEmployeesToShift = () => {
  const mutation = useMutation(assignEmployeesToShift, {
    mutationKey: "employee/shift/assign",
  });
  return mutation;
};

export { useAssignEmployeesToShift };
