/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Divider } from "theme-ui";
import { FC, Fragment } from "react";
import Icon from "@swvl/icon";
import theme from "@swvl/theme";
import { StationModel } from "resources/useRideDetails";
import { getStationDelayData, minsToHours } from "utils";

export type StationPopoverProps = {
  station: StationModel;
  isLastStation: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
  isCurrentStop?: boolean;
};

const StationTime: FC<{
  title: string;
  time?: string;
  status?: string;
  statusColor?: string;
}> = ({ title, time, status, statusColor }) => {
  return (
    <div
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        whiteSpace: "nowrap",
      }}
    >
      <Text variant="caption-large" sx={{ color: "gray.500", mb: 1 }}>
        {title}
      </Text>
      <Text variant="body-small" sx={{ fontWeight: "bold", mb: 1 }}>
        {time || "-"}
      </Text>
      <Text
        variant="caption-large"
        sx={{
          color: statusColor,
        }}
      >
        {status}
      </Text>
    </div>
  );
};

const WaitingTime: FC<{ time: string }> = ({ time }) => {
  return (
    <div
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        whiteSpace: "nowrap",
        my: 1,
        mx: 4,
        color: "red",
      }}
    >
      <div sx={{ opacity: 0.5 }}>
        <Icon name="clock" size={16} />
      </div>
      <Text variant="caption-small" sx={{ mt: 1, color: "gray.500", fontWeight: "bold" }}>
        {time || "-"}
      </Text>
    </div>
  );
};

const SkippedMessage: FC<{ text: string }> = ({ text }) => {
  return (
    <Fragment>
      <Text variant="caption-large" sx={{ color: "primary" }}>
        {text}
      </Text>
      <Divider sx={{ color: theme.colors.opacity["black-08"], mt: 2 }} />
    </Fragment>
  );
};

export const StationPopover: FC<StationPopoverProps> = ({
  station,
  isLastStation,
  isSelected,
  isCurrentStop,
}) => {
  const { name, estimatedAnalytics, analytics, status } = station;

  /** Arrival dates*/
  const arrival = getStationDelayData(analytics.arrivalTime, estimatedAnalytics.arrivalTime);
  /** Departure */
  const departure = getStationDelayData(analytics.departureTime, estimatedAnalytics.departureTime);
  /** Formatted Waiting Time */
  const formattedWaitingTime = minsToHours(analytics.waitingTime);

  return (
    <div
      sx={{
        textAlign: "left",
        fontSize: "12px",
        maxWidth: 270,
      }}
    >
      <div
        sx={{
          mb: "2px",
          fontWeight: "bold",
          wordBreak: "break-word",
        }}
      >
        {name}
      </div>
      {/* Show only title if hovered but not selected */}
      {isSelected && (
        <Fragment>
          <Divider sx={{ color: theme.colors.opacity["black-08"], mt: 2 }} />
          {status === "skipped" && <SkippedMessage text="Captain skipped this station" />}
          <div sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
            <StationTime
              title="Arrival"
              time={arrival.estimatedTime}
              status={
                arrival.delayInMinutes &&
                `${arrival.formattedDelay} ${arrival.delayInMinutes > 0 ? "delay" : "early"}`
              }
              statusColor={
                arrival.delayInMinutes && arrival.delayInMinutes > 0 ? "primary" : "seagreen"
              }
            />
            {/* Show only arrival in last station, else show waiting and departure */}
            {!isLastStation && (
              <Fragment>
                {/* Waiting Time */}
                <WaitingTime time={formattedWaitingTime} />
                {/* Departure */}
                <StationTime
                  title="Departure"
                  time={departure.estimatedTime}
                  status={
                    //   Check If bus is currently stopped at this station
                    isCurrentStop
                      ? "waiting in station"
                      : //   Else show the delay
                        departure.delayInMinutes &&
                        `${departure.formattedDelay} ${
                          departure.delayInMinutes > 0 ? "delay" : "early"
                        }`
                  }
                  statusColor={
                    departure.delayInMinutes && departure.delayInMinutes > 0
                      ? "primary"
                      : "seagreen"
                  }
                />
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};
