/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text, Badge } from "theme-ui";
import { useState, FC } from "react";
import Icon from "@swvl/icon";
import theme from "@swvl/theme";
import { NameAvatar } from "components/Avatar";
import MenuList from "components/MenuList";
import { NAV_HEIGHT } from "constants/styles";
import { useLogout } from "resources/useLogout";
import useAuthState from "utils/useAuthState";

export const Header: FC = () => {
  const [accountMenuEl, setAccountMenuEl] = useState<null | HTMLElement>(null);
  const {
    authData: { token, user },
  } = useAuthState();
  const { mutate: logoutUser } = useLogout();

  const handleLogout = () => {
    logoutUser({ token });
  };

  const options = [
    // TODO: Add more options when it's available
    // { label: "Profile", key: "profile", onClick: handleProfileNavigation },
    { label: "Logout", key: "logout", onClick: handleLogout },
  ];
  const renderAccountsMenu = () => {
    return accountMenuEl ? (
      <div
        sx={{
          width: "10rem",
          position: "absolute",
          willChange: "transform",
          top: "66px",
          right: 0,
          boxShadow: theme.shadows[1],
        }}
      >
        <MenuList
          options={options}
          getOptionKey={(option) => option.key as string}
          renderOption={(option) => <div>{option.label}</div>}
          handleSelect={(option) => [setAccountMenuEl(null), (option.onClick as () => void)()]}
        />
      </div>
    ) : null;
  };

  return (
    <div sx={{ gridArea: "header", position: "fixed", width: "100%", top: 0, zIndex: 9999 }}>
      <Flex
        as="nav"
        sx={{
          width: "100%",
          height: NAV_HEIGHT,
          background: "white",
          color: theme.colors.white,
          boxShadow: "0px 0px 8px rgba(124, 134, 156, 0.24)",
        }}
      >
        <Flex
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            pl: "10px",
            pr: "10px",
          }}
        >
          <Flex
            sx={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Badge
              sx={{
                borderRadius: 4,
                p: 2,
              }}
            >
              <Text variant="subhead" sx={{ color: "white", fontWeight: "bold" }}>
                {user?.corporate?.name}
              </Text>
            </Badge>
          </Flex>
          {user && (
            <Flex
              id="right-section"
              sx={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Flex
                aria-label="account of current admin"
                aria-controls={"controls"}
                aria-haspopup="true"
                sx={{
                  cursor: "pointer",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={(event) => {
                  if (accountMenuEl) {
                    setAccountMenuEl(null);
                  } else {
                    setAccountMenuEl(event.currentTarget);
                  }
                }}
              >
                <div sx={{ m: "4px", width: "36px", height: "36px" }}>
                  <NameAvatar name={user?.name}></NameAvatar>
                </div>
                <Text variant="body-small" color="black">
                  {user?.name}
                </Text>
                {accountMenuEl ? (
                  <div
                    sx={{
                      alignItems: "center",
                      width: "18px",
                      height: "18px",
                      transform: "rotate(180deg)",
                      transition: "0.3s all ease-in-out",
                    }}
                  >
                    <Icon
                      name="down_arrow"
                      fill="black"
                      sx={{ transform: "rotate(180deg)", transition: "0.1s all ease-in-out" }}
                    />
                  </div>
                ) : (
                  <div
                    sx={{
                      width: "18px",
                      height: "18px",
                      alignItems: "center",
                      transition: "0.3s all ease-in-out",
                    }}
                  >
                    <Icon name="down_arrow" fill="black" />
                  </div>
                )}
              </Flex>
              {renderAccountsMenu()}
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default Header;
