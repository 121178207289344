/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Autocomplete from "@swvl/autocomplete";
import { NameAvatar } from "components/Avatar";
import { addToQuery } from "utils/";
import { useSearchEmployees, SearchEmployeesModel } from "resources/useSearchEmployees";

type ProfileSearchProps = {
  postSearchChange?: (text: string, hasResults: boolean) => void;
  onSelect?: (data: SearchEmployeesModel) => void;
  privateRideParam?: boolean;
};

const ProfileSearch: FC<ProfileSearchProps> = ({
  postSearchChange,
  onSelect,
  privateRideParam,
}) => {
  const history = useHistory();

  const [searchText, setSearchText] = useState<string>();
  const { data: suggestions, isFetching } = useSearchEmployees(searchText, privateRideParam);

  const renderSearchOption = useCallback(
    (option: SearchEmployeesModel) => {
      return (
        <div sx={{ cursor: "pointer" }}>
          <NameAvatar name={option.name} nameIndex={suggestions.indexOf(option)}>
            <Text>{option.name}</Text>
            <Text>{option.phone}</Text>
          </NameAvatar>
        </div>
      );
    },
    [suggestions]
  );

  const handleSearchSelect = useCallback((option) => {
    addToQuery("employee-id", option.id as string, history);
  }, []);

  return (
    <Autocomplete
      suggestions={suggestions}
      placeholder="Search employee using name"
      isLoading={isFetching}
      onChangeQuery={(input) => setSearchText(input)}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => renderSearchOption(option)}
      getOptionKey={(option) => option.id}
      onSelect={onSelect ? onSelect : handleSearchSelect}
      css={{ width: "100%" }}
    />
  );
};

export default ProfileSearch;
