/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, Box, Image, Text } from "theme-ui";
import { FC } from "react";
import ErrorImage from "assets/something-wrong.svg";

const Error: FC = () => {
  return (
    <Box
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Image
        src={ErrorImage}
        alt="Something went wrong"
        sx={{ mt: 5, width: ["300px", "500px", "700px"] }}
      />
      <Text sx={{ my: 3, fontWeight: 500, fontSize: "23px", lineHeight: "27px" }}>
        Uh oh! Something went wrong. We’re working on fixing this.{" "}
      </Text>
      <Text>
        In the meantime, reach out to <a href="mailto: support@swvl.com">support@swvl.com</a> for
        any queries.
      </Text>
    </Box>
  );
};

export default Error;
