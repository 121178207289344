/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, Fragment } from "react";
import isNil from "lodash/fp/isNil";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import { NAV_HEIGHT, HEADER_HEIGHT } from "constants/styles";
import CaptainCard from "./CaptainCard";
import BookingsCard from "./BookingsList";
import RouteDetails from "./RouteDetails";
import { useRideBookings } from "resources/useRideBookings";
import { useRideDetails } from "resources/useRideDetails";

type DetailsProp = {
  id: string;
  closeDetails: () => void;
};

const Details: FC<DetailsProp> = ({ id, closeDetails }) => {
  const {
    data: { bus, busType, captain, stations },
    isFetching: isRideLoading,
  } = useRideDetails({ id });
  const { data: bookings } = useRideBookings({ id });

  return (
    <div sx={{ height: `calc(100vh - ${NAV_HEIGHT + HEADER_HEIGHT}px)`, position: "relative" }}>
      <button
        sx={{
          position: "absolute",
          zIndex: "9",
          top: "10px",
          left: "10px",
          backgroundColor: "primary",
          color: "white",
          outline: "none",
          border: "none",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          textTransform: "capitalize",
          cursor: "pointer",
          boxShadow: "0px 8px 16px rgba(239, 67, 73, 0.32)",
        }}
        type="button"
        onClick={closeDetails}
      >
        <Icon name="left_arrow" size={20} fill="currentcolor" />
        <span sx={{ ml: 1 }}>back to rides</span>
      </button>
      {isRideLoading ? (
        <Spinner
          color="primary"
          css={{
            display: "block",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <Fragment>
          <CaptainCard captain={captain} busType={busType} bus={bus} />
          {!isNil(stations) && stations.length > 0 ? <RouteDetails stations={stations} /> : null}
          <BookingsCard bookingsList={bookings} />
        </Fragment>
      )}
    </div>
  );
};

export default Details;
