// TODO: Revisit this component
import styled from "@emotion/styled";
import { ReactNode } from "react";

type BackgroundImageProps = {
  img: string;
  gradient?: boolean;
  children: ReactNode;
};

export const BackgroundImage = styled("div")<BackgroundImageProps>`
  height: 100vh;
  width: 100%;
  background-image: ${(props) =>
      props.gradient ? "linear-gradient(0deg, rgba(17, 12, 81, 0.4), rgba(17, 12, 81, 0.4))," : ""}
    url(${(props) => props.img});
  @media (max-width: 900px) {
    background-image: none;
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: 60% auto;
`;

export const FormWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 60%;
  display: grid;
  grid-row-gap: 40px;
  @media (max-width: 900px) {
    width: 70%;
  }
`;

export const ErrorBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #fbd2d4;
  border: 1px solid #ef4349;
  border-radius: 8px;
  flex: none;
  width: 100%;
  order: 0;
  align-self: flex-start;
`;
