import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { dropNulls, toSnakeCase, toCamelCase } from "utils";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { AxiosResponse } from "axios";

export interface CreatePrivateRideRequest {
  employee_id: string;
  pickup: { lat: number; lng: number };
  dropoff: { lat: number; lng: number };
  depart_date: string;
  bus_type: string;
  additional_details: string;
}

export type VehicleTypesModel = {
  id: string;
  name: string;
  max_seats: number;
  seats: number;
};

// Transforms
const transformRequest = (form: CreatePrivateRideRequest): CreatePrivateRideRequest => {
  return toSnakeCase(dropNulls(form)) as CreatePrivateRideRequest;
};

// Resources
const createPrivateRide = async (form: CreatePrivateRideRequest) => {
  const data = transformRequest(form);
  const response = await apiInstance.post<CreatePrivateRideRequest, undefined>(
    "private_rides",
    data
  );
  return response;
};

const useCreatePrivateRide = () => {
  const history = useHistory();
  const mutation = useMutation(createPrivateRide, {
    mutationKey: "private-ride/create",
    onSuccess: () => {
      history.push(`/private-ride`);
      showAlert({
        message: "You have created a new private ride request successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};
const transformResponse = (response: VehicleTypesModel[]): VehicleTypesModel[] => {
  return response.map((item) => toCamelCase(item) as VehicleTypesModel);
};

const fetchVehicleTypes = async (): Promise<VehicleTypesModel[]> => {
  const response = await apiInstance.get<AxiosResponse, VehicleTypesModel[]>(
    "corporate_contract/bus_types"
  );

  return transformResponse(response);
};

const useFetchVehicleTypes = () => {
  const query = useQuery(["vehicleTypes"], () => fetchVehicleTypes(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (response) => {
      showAlert({
        message: (response as AxiosResponse)?.data?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return query;
};

export { useCreatePrivateRide, createPrivateRide, useFetchVehicleTypes };
