/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC } from "react";
import isNil from "lodash/fp/isNil";
import { ConfirmationPopup } from "components/ConfirmationPopup";

type PopupData = { id: string; value: unknown; name?: string };

type ActivationPopupProps = {
  data: PopupData;
  onApprove: () => void;
  onDismiss: () => void;
};

const ActivationPopup: FC<ActivationPopupProps> = (props) => {
  const { data, onApprove, onDismiss } = props;

  return (
    <ConfirmationPopup
      isOpen={!isNil(data)}
      onApprove={onApprove}
      onDismiss={onDismiss}
      title={`${data?.value ? "Deactivate" : "Activate"} Shift`}
      message={
        <div data-testid="activate-deactivate-shift">
          <Text variant="body-small" sx={{ color: "opacity.black-54" }}>
            You will proceed to {data?.value ? "deactivate" : "activate"} shift
          </Text>
          <Text variant="body-small" sx={{ fontWeight: "bold", color: "opacity.black-54" }}>
            {data?.name}
          </Text>
        </div>
      }
      confirmButtonText={data?.value ? "Deactivate shift" : "Activate shift"}
    />
  );
};

export default ActivationPopup;
