/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import Spinner from "@swvl/spinner";
import ReactTooltip from "react-tooltip";

export const Header = ({ isLoading, delayComponent }) => {
  return (
    <header
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text variant="subhead" sx={{ fontWeight: "bold" }}>
        Ride Monitoring Dashboard
      </Text>
      <div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {isLoading ? (
          <Spinner
            sx={{
              mx: 2,
            }}
            size={20}
            color="lightcoral"
          />
        ) : null}
        {delayComponent()}
      </div>
      <ReactTooltip
        id="delay-threshold"
        place="bottom"
        effect="solid"
        type="dark"
        multiline
        arrowColor={"transparent"}
        offset={{
          bottom: 50,
          right: 45,
        }}
      />
    </header>
  );
};

export default Header;
