/** @jsxRuntime classic */
/** @jsx jsx */
import { SyntheticEvent } from "react";
import { IconButton } from "@swvl/button";
import Icon from "@swvl/icon";
import Toggle from "@swvl/toggle";
import { jsx, Flex, Text } from "theme-ui";
import { NameAvatar } from "components/Avatar";
import { format } from "date-fns";
import { Column } from "react-table";
import { EmployeeModel } from "resources/useEmployees";

interface ColumnArgs {
  setPopupData;
  handleEditEmployee: (e: SyntheticEvent, id: string) => void;
  handleDeleteEmployee: (e: SyntheticEvent, id: string) => void;
  isDisabled: boolean;
}

export const columns = ({
  setPopupData,
  handleEditEmployee,
  handleDeleteEmployee,
  isDisabled,
}: ColumnArgs): Column<EmployeeModel>[] => [
  {
    Header: "Employee Name",
    accessor: "name",
    Cell: ({ cell }) => {
      const name = cell.value;
      return (
        <NameAvatar name={name} nameIndex={cell.row.id}>
          <Text>{name}</Text>
        </NameAvatar>
      );
    },
  },
  {
    Header: "Mobile No",
    accessor: "phone",
    Cell: ({ value }) => `+${value}`,
  },
  {
    Header: "Employee ID",
    accessor: "employeeId",
  },
  {
    Header: "Email Address",
    accessor: "email",
  },
  {
    Header: "Status",
    accessor: "active",
    Cell: ({
      cell,
      value,
      row: {
        original: { id, name, shiftAssigned },
      },
    }) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Toggle
            id={cell.getCellProps().key}
            data-testid={cell.getCellProps().key}
            checked={value}
            isControlled={true}
            labelOn={"Active"}
            labelOff={"Inactive"}
            onChange={(e) => {
              setPopupData({
                id,
                value,
                name,
                shiftAssigned,
              });
            }}
            onOffLabelPosition={"start"}
          />
        </div>
      );
    },
  },
  {
    Header: "Registration",
    accessor: "isAppRegistered",
    Cell: ({ value }) => {
      return <Text>{value ? "Completed" : "Not Completed"}</Text>;
    },
  },
  {
    Header: "Assignment Status",
    accessor: "shiftAssigned",
    Cell: ({ value }) => {
      return <Text>{value ? "Assigned" : "Unassigned"}</Text>;
    },
  },
  {
    Header: "Last Created",
    accessor: "createdAt",
    Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ cell, value, row }) => (
      <Flex>
        <IconButton
          data-testid="edit-employee"
          icon={<Icon name="edit" fill="currentcolor" size={15} />}
          variant={"ghost"}
          onClick={(e) => handleEditEmployee(e, row.original.id)}
          disabled={isDisabled}
        />
        <IconButton
          sx={{ marginLeft: 3 }}
          data-testid="delete-employee"
          icon={<Icon name="delete" fill="currentcolor" size={15} />}
          variant={"ghost"}
          onClick={(e) => handleDeleteEmployee(e, row.original.id)}
          disabled={isDisabled}
        />
      </Flex>
    ),
  },
];

export default columns;
