import axios from "axios";
import { showAlert } from "@swvl/alert";
import useAuthState, { AuthenticationModel } from "utils/useAuthState";
import { useCallback, useEffect } from "react";
import { useRefreshToken } from "resources/useRefreshToken";

export const apiClient = axios.create({
  // eslint-disable-next-line
  // @ts-ignore
  baseURL: window.__env__.API_BASE_URL,
  timeout: 20000,
});

// Default Response Interceptor
apiClient.interceptors.response.use((response) => response.data);

// Variables to control the state of refreshing on unauthorized requests fail, can't be used in the hook state
let isRefreshing = false;
let failedRequestsCallBacks: ((newAuthData: AuthenticationModel) => void)[] = [];

export const useUnAuthorizedResponseInterceptor = () => {
  const { authData, setAuthData, resetAuthData } = useAuthState();
  const { mutate: refreshToken } = useRefreshToken();
  const UNAUTHORIZED = 401;

  const retryRequests = useCallback((newAuthData) => {
    isRefreshing = false;
    // Retry all failed requests
    failedRequestsCallBacks.forEach((retryRequest) => retryRequest(newAuthData));
    // Empty the failed requests
    failedRequestsCallBacks = [];
    // Set the new auth data
    setAuthData({ ...newAuthData, user: authData.user });
  }, []);

  const handleSessionExpired = useCallback(() => {
    isRefreshing = false;
    showAlert({ message: "Session has been expired", type: "error", id: "alert-error" });
    // Reset auth data in local storage, which automatically redirects to login
    resetAuthData();
  }, []);

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(undefined, (error) => {
      if (error.response.status === UNAUTHORIZED) {
        const originalRequest = error.config;

        // // If already retried before, navigate back to login
        if (originalRequest._retry) {
          return handleSessionExpired();
        }

        // Retry refreshing token for 1 time for any request other than the refresh token
        if (!isRefreshing) {
          isRefreshing = true;
          refreshToken(
            { refresh_token: authData.refreshToken },
            {
              onSuccess: retryRequests,
              onError: handleSessionExpired,
            }
          );
        }
        // Add request to failed requests
        return new Promise((resolve) => {
          failedRequestsCallBacks.push((newAuthData) => {
            // Mark it as retried before
            originalRequest._retry = true;
            // Set the new header token
            originalRequest.headers.Authorization = `Bearer ${newAuthData.token}`;
            // Retry request
            resolve(apiClient.request(originalRequest));
          });
        });
      }

      return Promise.reject(error.response);
    });

    return () => {
      apiClient.interceptors.response.eject(interceptor);
    };
  }, [authData]);
};

export const setApiAuthHeader = (token: string) => {
  if (token) apiClient.defaults.headers["Authorization"] = `Bearer ${token}`;
  else delete apiClient.defaults.headers["Authorization"];
};
/** Response interceptor without store for nock testing **/

export default apiClient;
