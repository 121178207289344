/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState, useRef } from "react";
import Icon from "@swvl/icon";
import Button from "@swvl/button";
import { Select } from "@swvl/select";
import DatePicker from "@swvl/date-picker";
import { SHIFT_DIRECTION_OPTIONS } from "constants/shifts";
import { FilterMap } from "utils/useFilters";
import { addDays } from "date-fns";
import { useOfficeLocations } from "resources/useOfficeLocations";
import { useShiftsTimes } from "resources/useShiftsTimes";
import { getIsoDate } from "utils";

type FiltersProp = {
  onFilterChange: (key: string) => (filter: { [key: string]: string }) => void;
  initialFilters: FilterMap;
};

const Filters: FC<FiltersProp> = ({ onFilterChange, initialFilters }) => {
  const dateRef = useRef(null);
  const [appliedDates, setAppliedDates] = useState<[Date, Date]>([null, null]);
  const [dates, setDates] = useState<[Date, Date]>([null, null]);

  const { data: officeLocationsData, isLoading: isOfficeLocationsLoading } = useOfficeLocations();
  const { data: shiftsTimesData, isLoading: isShiftsTimesLoading } = useShiftsTimes();

  const officeLocationsOptions = [
    {
      label: "All",
      value: null,
    },
  ].concat(
    officeLocationsData?.officeLocations.map((officeLocation) => ({
      value: officeLocation.id,
      label: officeLocation.name,
    }))
  );

  const shiftsTimesOptions = [
    {
      label: "All",
      value: null,
    },
  ].concat(
    shiftsTimesData?.shiftsTimes.map((shiftTime) => ({
      value: shiftTime.value,
      label: shiftTime.format,
    }))
  );

  const applyDateFilter = () => {
    dateRef.current.setOpen(false);
    setAppliedDates(dates);
    if (dates.every((date) => date !== null)) {
      onFilterChange("start_date")({ value: getIsoDate(dates[0]).isoString });
      onFilterChange("end_date")({ value: getIsoDate(dates[1]).isoString });
    } else {
      onFilterChange("date")({ value: getIsoDate(dates[0]).isoString });
    }
  };

  return (
    <div
      sx={{
        height: "64px",
        display: "flex",
        alignItems: "center",
        mx: "25px",
      }}
    >
      <div css={{ flexGrow: 1, maxWidth: `${100 / 5}%` }}>
        <DatePicker
          ref={dateRef}
          selected={dates[0]}
          onChange={(newDates) => {
            const [newStartDate, newEndDate] = newDates as [Date, Date];
            setDates([newStartDate, newEndDate]);
          }}
          startDate={dates[0]}
          endDate={dates[1]}
          placeholder={dates.every((date) => date === null) && "Date"}
          minDate={addDays(new Date(), 1)}
          inputHeight="small"
          withIconAtStart
          onClickOutside={() => {
            /**  Discard changes if not applied and clicked outside */
            const [appliedStartDate, appliedEndDate] = appliedDates;
            setDates([appliedStartDate, appliedEndDate]);
          }}
        >
          <div css={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={applyDateFilter} variant="primary">
              Apply
            </Button>
          </div>
        </DatePicker>
      </div>
      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 7}%`,
        }}
      >
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={shiftsTimesOptions}
          label={""}
          placeholder={"Time"}
          name="time"
          isLoading={isShiftsTimesLoading}
          handleChange={onFilterChange("time")}
          startComponent={
            <div
              sx={{
                mr: 2,
                width: "16px",
                height: "16px",
                p: "2px",
                borderRadius: "50%",
                border: "solid 1px rgba(0, 0, 0, 0.38)",
              }}
            >
              <Icon name="clock_filled" fill="rgba(0, 0, 0, 0.38)" />
            </div>
          }
        />
      </div>
      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 7}%`,
        }}
      >
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={SHIFT_DIRECTION_OPTIONS}
          label={""}
          placeholder={"Shift Direction"}
          name="shift_direction"
          handleChange={onFilterChange("direction")}
          startComponent={
            <div sx={{ mr: 2 }}>
              <Icon name="shift_direction" fill="rgba(0, 0, 0, 0.38)" />
            </div>
          }
        />
      </div>
      <div
        css={{
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          maxWidth: `${100 / 5}%`,
        }}
      >
        <Select
          css={{ flexGrow: 1, minWidth: 0 }}
          height="small"
          options={officeLocationsOptions}
          label={""}
          placeholder={"Office Location"}
          name="office_location"
          isLoading={isOfficeLocationsLoading}
          handleChange={onFilterChange("office_location")}
          startComponent={
            <div sx={{ mr: 2 }}>
              <Icon name="office_address" fill="rgba(0, 0, 0, 0.38)" />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Filters;
