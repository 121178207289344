import { useMutation } from "react-query";
import apiClient from "api";
import { showAlert } from "@swvl/alert";
import { useHistory } from "react-router-dom";
import useAuthState, { User, AuthenticationModel } from "utils/useAuthState";

// Types

interface LoginRequest {
  email: string;
  password: string;
}

interface LoginResponse {
  __v: number;
  updatedAt: string;
  createdAt: string;
  owner: string;
  type: string;
  _id: string;
  expiry_date: string;
  refresh_token: string;
  token: string;
  is_expired: boolean;
  id: string;
  jwt: string;
  role: string;
  user: User;
}

type LoginResponseTransform = Omit<AuthenticationModel, "error">;

// Transforms
const transformResponse = (response: LoginResponse): LoginResponseTransform => {
  const { token, expiry_date: expiryDate, jwt, refresh_token: refreshToken, role, user } = response;
  return { token, expiryDate, jwt, refreshToken, role, user };
};

// Resources
const loginUser = async (data: LoginRequest) => {
  const response = await apiClient.post<LoginRequest, LoginResponse>("sign_in", data);
  return transformResponse(response);
};

const useLogin = () => {
  const { setAuthData } = useAuthState();
  const history = useHistory();

  const mutation = useMutation(loginUser, {
    mutationKey: "user/login",
    onSuccess: (data) => {
      setAuthData(data);
      history.replace("/");
      showAlert({
        message: "Login successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return {
    ...mutation,
    validationErrorMessage:
      "Invalid email address or password. You can reach your SWVL account manager to help reset your password.",
  };
};

export { useLogin };
