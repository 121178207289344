import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { getFromQuery } from "utils";
import { useLocation } from "react-router-dom";

interface DeactivateEmployeeRequest {
  id: string;
}

const deactivateEmployee = async ({ id }: DeactivateEmployeeRequest): Promise<string> => {
  const response = await apiInstance.put<undefined, string>(`business-profile/${id}/deactivate`);

  return response;
};

const useDeactivateEmployee = () => {
  const successMessage = "User has been successfully deactivated";
  const queryClient = useQueryClient();
  const location = useLocation();
  const mutation = useMutation(deactivateEmployee, {
    mutationKey: "employee/deactivate",
    onSuccess: (_data, { id }) => {
      const page = getFromQuery("page", location.search);
      queryClient.invalidateQueries(["employees", +page]);
      queryClient.invalidateQueries(["employee/profile", id]);
      showAlert({
        message: successMessage,
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error["data"]?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { useDeactivateEmployee };
