/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import { BulkUploadPopup, BulkUploadPopupProps } from "components/BulkUploadPopup";

const BulkUploadPrivateRidesPopup: FC<BulkUploadPopupProps> = (props) => {
  return <BulkUploadPopup name="private-rides" {...props} />;
};

export default BulkUploadPrivateRidesPopup;
