/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useEffect, useRef, useMemo, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { format, fromUnixTime, getUnixTime, differenceInMinutes } from "date-fns";
import isEmpty from "lodash/fp/isEmpty";
import isNil from "lodash/fp/isNil";
import isEqual from "lodash/fp/isEqual";
import reduce from "lodash/fp/reduce";
import Map, { CustomMapPanelControl } from "@swvl/map";
import theme from "@swvl/theme";
import DatePicker from "@swvl/date-picker";
import { Select, OptionWrapper } from "@swvl/select";
import Icon from "@swvl/icon";
import Button from "@swvl/button";
import Tag from "@swvl/tag";
import Page from "components/PageWrapper";
import { HEADER_HEIGHT, NAV_HEIGHT } from "constants/styles";
import {
  GOOGLE_MAPS_API_KEY,
  RIDE_IN_PROGRESS,
  RIDE_COMPLETED_STATUS,
  GOOGLE_MAP_STYLE,
  RIDE_STATUS_TAG_COLOR,
  RIDE_STATUS_TAG_NAME,
  RIDE_DELAY_OPTIONS,
  DEFAULT_STATION_DELAY_THRESHOLD,
  DELAY_INTERVAL,
  DELAYED_BUS_COLOR,
} from "constants/rideMonitoring";
import { addToQuery, getFromQuery, pluralize } from "utils";
import { useRideDetails } from "resources/useRideDetails";
import useAuthState from "utils/useAuthState";
import { RideModel, RideStatus, useRides } from "resources/useRides";
import SearchInput from "components/SearchInput";
import { RIDE_STATUS_OPTIONS } from "constants/rideMonitoring";
import RidesList from "./RidesList";
import RideMetricsCard from "./RideMetricsCard";
import Header from "./Header";
import { StationPopover } from "./StationPopover";

const Rides: FC = () => {
  const history = useHistory();
  const location = history.location;
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const selectedRideId = useMemo(() => getFromQuery("ride-id", location.search), [location.search]);
  const startDate = new Date();
  const endDate = null;

  /** `dates` used in date picker component is for not firing the change event for the component itself,
   and instead use `Apply` to set the user selected dates `appliedDates`
   */
  const [appliedDates, setAppliedDates] = useState<[Date, Date]>([startDate, endDate]);
  const [dates, setDates] = useState<[Date, Date]>([startDate, endDate]);
  const [lastUpdatedLocationTime, setLastUpdatedLocationTime] = useState<string>();
  const [rideStatus, setRideStatus] = useState<RideStatus[]>(["started", "stopped"]);
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [delayThreshold, setDelayThreshold] = useState(DEFAULT_STATION_DELAY_THRESHOLD);
  const [delayedRides, setDelayedRides] = useState<{
    [rideId: string]: boolean;
  }>({});

  const [selectedStationId, setSelectedStationId] = useState<string>(undefined);
  const [hoveredStationId, setHoveredStationId] = useState<string>(undefined);

  const dateRef = useRef(null);

  const {
    data: ridesListData,
    isFetching: isRidesLoading,
    hasNextPage,
    fetchNextPage: handleGetMoreRides,
    refetch: refetchRidesList,
  } = useRides({
    dates: appliedDates,
    searchQuery,
    status: rideStatus,
  });

  const shouldShowLoadMoreButton = !isRidesLoading && hasNextPage;

  const applyDateFilter = () => {
    dateRef.current.setOpen(false);
    setAppliedDates(dates);
  };

  const getRideStatusValue = (rideStatusOption: {
    value: RideStatus;
    label: string;
  }): RideStatus[] => {
    const rideStatusValue = [...rideStatusOption?.value.split(",")];
    return rideStatusValue as RideStatus[];
  };

  const { data: selectedRideData, refetch: refetchRideDetails } = useRideDetails({
    id: selectedRideId,
  });

  const {
    authData: { jwt, user },
  } = useAuthState();

  const centerPoint = useMemo(() => {
    if (!isNil(selectedRideData.stations))
      return selectedRideData.stations[Math.floor(selectedRideData.stations.length / 2)];

    const cityCoordinates = user?.city?.center?.loc?.coordinates;
    if (!cityCoordinates) return { lng: 0, lat: 0 };
    return {
      lng: cityCoordinates[0],
      lat: cityCoordinates[1],
    };
  }, [user, selectedRideData.stations]);

  // == instrumentation code == //
  // refs are used here to keep track of data outside the render

  const socketsDataRef = useRef(null);
  const lastLocationUpdateTimeRef = useRef(null);
  const durationRef = useRef(null);

  useEffect(() => {
    socketsDataRef.current = {
      captain: selectedRideData.captain,
      rideStatus,
      selectedRideId,
    };
  }, [selectedRideData.captain, rideStatus, selectedRideId]);

  /** Reset last updated location time on returning back from details */
  useEffect(() => {
    if (!selectedRideId) {
      setLastUpdatedLocationTime(undefined);
    }
  }, [selectedRideId]);

  const onSubscribe = ({ rideId, rideStatus }) => {
    lastLocationUpdateTimeRef.current = performance.now();
    ReactGA.event({
      category: `corporate: ${user.corporate._id}`,
      action: `ride: ${rideId}`,
      label: `status: ${rideStatus}`,
    });
  };

  const onRideUpdate = ({ _id, selectedRideId }) => {
    const isRideOpened = !isNil(selectedRideId);
    /** Handle update ride in listing */
    if (!isRideOpened) {
      refetchRidesList();
    } else if (selectedRideId === _id) {
      /** Handle update ride in details */
      refetchRideDetails();
    }

    /** TODO: Add google analytics */
  };

  const onLocationUpdate = ({ rideId, location, captain }) => {
    setLastUpdatedLocationTime(format(fromUnixTime(getUnixTime(location.time_utc)), "hh:mm aa"));
    // accumulating the duration every time the location update
    // to send it when unsubscribing
    const now = performance.now();
    const newDuration = now - lastLocationUpdateTimeRef.current;
    lastLocationUpdateTimeRef.current = now;
    durationRef.current = durationRef.current + newDuration;

    // sending captain info for a ride whenever the location update
    // what's important here is the count of the requests
    const captainId = captain?.id;
    ReactGA.event({
      category: `corporate: ${user.corporate._id}`,
      action: `ride: ${rideId}`,
      label: `captain: ${captainId}`,
    });
  };

  const onUnsubscribe = ({ rideId, duration }) => {
    ReactGA.event({
      category: `corporate: ${user.corporate._id}`,
      action: `ride: ${rideId}`,
      label: `duration: ${Math.floor(duration / 1000)}`,
    });
    durationRef.current = 0; // reset duration to 0
  };

  const onLastUpdatedPopupTriggered = ({ rideId }) => {
    ReactGA.event({
      category: `corporate: ${user.corporate._id}`,
      action: `ride: ${rideId}`,
      label: `last seen label triggered`,
    });
  };
  // Calling onUnsubscribe even if the user close the tab
  useEffect(() => {
    const onUnsubscribeCallback = () => {
      onUnsubscribe({
        rideId: selectedRideId,
        duration: durationRef.current,
      });
    };
    window.addEventListener("beforeunload", onUnsubscribeCallback);
    return () => window.removeEventListener("beforeunload", onUnsubscribeCallback);
  }, [selectedRideId, durationRef]);

  const socketOptions = useMemo(() => {
    const isRideOpened = !isNil(selectedRideId);
    const isRideInProgress =
      selectedRideData.status === RIDE_IN_PROGRESS.started ||
      selectedRideData.status === RIDE_IN_PROGRESS.stopped;

    // eslint-disable-next-line
    // @ts-ignore
    const socketUrl = window.__env__.SOCKETS_URL;

    const hasInProgressRides =
      (isRideOpened && isRideInProgress) ||
      ridesListData.rides.some(
        (ride) =>
          ride.status === RIDE_IN_PROGRESS.started || ride.status === RIDE_IN_PROGRESS.stopped
      );

    if (hasInProgressRides)
      return {
        query: { token: jwt },
        url: socketUrl,
        onSubscribe: (props) => {
          // using closure here so when function (onSubscribe)
          // execute (in the map component) it have access
          // to reference of rideStatus
          const { rideStatus } = socketsDataRef.current;
          return onSubscribe({ ...props, rideStatus });
        },
        onRideUpdate: (props) => {
          const { selectedRideId } = socketsDataRef.current;
          return onRideUpdate({ ...props, selectedRideId });
        },
        onLocationUpdate: (props) => {
          const { captain } = socketsDataRef.current;
          return onLocationUpdate({ ...props, captain });
        },
        onUnsubscribe: (props) => {
          const duration = durationRef.current;
          return onUnsubscribe({ ...props, duration });
        },
        onLastUpdatedPopupTriggered,
      };

    return undefined;
  }, [selectedRideId, selectedRideData, ridesListData]);

  const displayedRoutes = useMemo(() => {
    const isRideOpened = !isNil(selectedRideId);

    if (isRideOpened) {
      let actualeRoute = {};
      if (selectedRideData.status === "completed" && selectedRideData.analytics?.polyline) {
        actualeRoute = {
          [`${selectedRideId}:actual`]: {
            encodedPolyline: selectedRideData.analytics?.polyline,
            strokeColor: theme.colors.black,
            startColor: theme.colors.black,
            endColor: theme.colors.black,
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: "M 0,-1 0,1",
                  strokeOpacity: 1,
                  scale: 1,
                  strokeColor: theme.colors.black,
                  strokeWeight: 3,
                },
                offset: "0",
                repeat: "10px",
              },
            ],
          },
        };
      }
      return {
        ...actualeRoute,
        [selectedRideId]: {
          encodedPolyline: selectedRideData.route?.polyline || "",
          stations: selectedRideData.stations,
          startColor: theme.colors.primary,
          endColor: theme.colors.secondary,
          strokeWeight: 3,
        },
      };
    }
    return {};
  }, [selectedRideData?.route?.id, selectedRideData.analytics?.polyline]);

  const bannerText = useMemo(() => {
    // Only show alert in ongoing rides
    if (isEqual(rideStatus, ["started", "stopped"])) {
      // Show total in progress rides count in the rides listing
      if (isNil(selectedRideId)) {
        if (ridesListData.count > 0) {
          return `${ridesListData.rides.length} out of ${pluralize(
            ridesListData.count,
            "In Progress Ride"
          )} displayed on the map. Late buses are highlighted in red`;
        }
      }
      // Show captain location last updated at time in the ride details view
      else if (lastUpdatedLocationTime) return `Last Tracked Time ${lastUpdatedLocationTime}`;
    }
    return "";
  }, [selectedRideId, ridesListData, lastUpdatedLocationTime]);

  const isRideDelayed = useCallback(
    (ride: RideModel) => {
      const firstUpcomingRide = ride.stations.find((station) => station.status === "coming");
      if (firstUpcomingRide) {
        const estimatedArrival = new Date(firstUpcomingRide.estimatedAnalytics?.arrivalTime);
        const difference = differenceInMinutes(new Date(), estimatedArrival);
        return difference >= delayThreshold;
      }
      return false;
    },
    [delayThreshold]
  );

  // A callback to calcuate delayed rides object
  const getDelayedRides = useCallback(
    () =>
      reduce<RideModel, { [rideId: string]: boolean }>(
        (acc, ride) => ({ ...acc, [ride.id]: isRideDelayed(ride) }),
        {}
      )(ridesListData.rides),
    [JSON.stringify(ridesListData.rides), isRideDelayed]
  );

  // Interval to check the delay on every `DELAY_INTERVAL` amount
  useEffect(() => {
    let updateInterval;

    if (isEqual(rideStatus, ["started", "stopped"]) && ridesListData.rides.length > 0) {
      // for the first mount
      const newDelayedRides = getDelayedRides();
      setDelayedRides(newDelayedRides);

      updateInterval = setInterval(() => {
        const newDelayedRides = getDelayedRides();
        setDelayedRides(newDelayedRides);
      }, DELAY_INTERVAL);
    }

    return () => clearInterval(updateInterval);
  }, [rideStatus, getDelayedRides]);

  const getBusIconOptions = useCallback(
    (rideId) => {
      return {
        onClick: (rideId) => {
          addToQuery("ride-id", rideId, history);
        },
        upperPathColor: delayedRides[rideId] ? DELAYED_BUS_COLOR : undefined,
      };
    },
    [delayedRides]
  );

  const renderStationPopover = useCallback(
    (id) => {
      if (!selectedRideData) return null;
      const station = selectedRideData.stations.find((station) => station.id === id);
      const isLastStation =
        selectedRideData.stations[selectedRideData.stations.length - 1].id === id;
      const firstUpcomingStationIndex = selectedRideData.stations.findIndex(
        (station) => station.status === "coming"
      );
      const isCurrentStop =
        selectedRideData.status === "stopped" &&
        firstUpcomingStationIndex > 0 &&
        selectedRideData.stations[firstUpcomingStationIndex - 1].id === id;

      const isSelected = selectedStationId === id;
      const isHovered = hoveredStationId === id;

      if (!station || (!isSelected && !isHovered)) return null;
      return (
        <StationPopover
          station={station}
          isLastStation={isLastStation}
          isCurrentStop={isCurrentStop}
          isSelected={isSelected}
          isHovered={isHovered}
        />
      );
    },
    [selectedRideData, selectedStationId, hoveredStationId]
  );
  return (
    <Page
      title="Ride Monitoring | Rides"
      tagName="rides-page"
      trackName="Rides.Page"
      header={
        <Header
          isLoading={isRidesLoading}
          delayComponent={() => {
            return (
              <div sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Text variant="body-large" color="opacity.black-38" sx={{ mr: 2 }}>
                  Delay Threshold
                </Text>
                <Select
                  name="Delay Threshold"
                  options={RIDE_DELAY_OPTIONS}
                  variant="plain"
                  height="small"
                  placeholder="Ride Delay"
                  handleChange={(newDelay) => {
                    setDelayThreshold(+newDelay.value);
                  }}
                  defaultValue={RIDE_DELAY_OPTIONS[0]}
                  label=""
                  sx={{
                    width: "140px",
                  }}
                />
                <div
                  data-for="delay-threshold"
                  data-tip="Highlights all 'In-Progress' rides that are delayed beyond the configured threshold."
                  data-iscapture="true"
                  sx={{
                    p: 2,
                  }}
                >
                  <Icon name="info" size={20} fill={theme.colors.opacity["black-38"]} />
                </div>
              </div>
            );
          }}
        />
      }
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: `calc(100vh - ${NAV_HEIGHT + HEADER_HEIGHT}px)`,
        }}
      >
        {/* Sidebar */}
        <div
          sx={{
            width: "288px",
            background: "#fff",
            position: "relative",
            boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.08)",
            height: `calc(100vh - ${NAV_HEIGHT + HEADER_HEIGHT}px)`,
          }}
        >
          <div
            sx={{
              overflowY: "scroll",
              width: "288px",
              background: "#fff",
              boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.08)",
              height: `calc(100vh - ${NAV_HEIGHT + HEADER_HEIGHT}px)`,
            }}
          >
            <div sx={{ p: 2 }}>
              <SearchInput
                handleChange={(query) => setSearchQuery(query)}
                placeholder="Search by route, station or employee name / no."
              />
              <div sx={{ mt: 2 }}>
                <Select
                  name="Ride Status"
                  options={RIDE_STATUS_OPTIONS}
                  variant="plain"
                  height="small"
                  placeholder="Ride Status"
                  handleChange={(newStatus) => {
                    if (newStatus.value === "started,stopped") {
                      setAppliedDates([startDate, endDate]);
                      setDates([startDate, endDate]);
                    }
                    setRideStatus(getRideStatusValue(newStatus));
                  }}
                  customComponents={{
                    Option: (props) => {
                      const { innerRef, innerProps, data } = props;
                      return (
                        <OptionWrapper ref={innerRef} {...innerProps}>
                          <div id={data.label}>
                            <Tag
                              size="small"
                              variant={RIDE_STATUS_TAG_COLOR[data.value.split(",")[0]]}
                            >
                              <span sx={{ fontWeight: "bold" }}>
                                {RIDE_STATUS_TAG_NAME[data.value.split(",")[0]]}
                              </span>
                            </Tag>
                          </div>
                        </OptionWrapper>
                      );
                    },
                  }}
                  defaultValue={[{ value: "started,stopped", label: "In Progress" }]}
                  label={""}
                  startComponent={<Icon name={"bus_alt"} size={20} fill="hsl(0,0%,90%)" />}
                />
              </div>
              <div sx={{ mt: 2 }}>
                <DatePicker
                  ref={dateRef}
                  selected={dates[0]}
                  disabled={isEqual(rideStatus, ["started", "stopped"])}
                  onChange={(newDates) => {
                    const [newStartDate, newEndDate] = newDates as [Date, Date];
                    setDates([newStartDate, newEndDate]);
                  }}
                  startDate={dates[0]}
                  endDate={dates[1]}
                  inputHeight="small"
                  // this is because we have a defualt minDate in the component
                  minDate={undefined}
                  withIconAtStart
                  onClickOutside={() => {
                    /**  Discard changes if not applied and clicked outside */
                    const [appliedStartDate, appliedEndDate] = appliedDates;
                    setDates([appliedStartDate, appliedEndDate]);
                  }}
                >
                  <div css={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={applyDateFilter} variant="primary">
                      Apply
                    </Button>
                  </div>
                </DatePicker>
              </div>
            </div>
            <RidesList
              rides={ridesListData.rides}
              isRidesLoading={isRidesLoading}
              shouldShowLoadMoreButton={shouldShowLoadMoreButton}
              handleGetMoreRides={handleGetMoreRides}
              headingSchema="${startDate}, ${startTime} - ${endTime}"
            />
          </div>
        </div>

        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          {/* Banner */}
          {bannerText && (
            <div
              sx={{
                backgroundColor: "ghostwhite",
                color: "black",
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
                padding: 16,
              }}
            >
              <Text variant={"body-small"}>{bannerText}</Text>
            </div>
          )}
          {/* Map */}
          <Map
            onMapInit={(map) => {
              setMap(map);
            }}
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            routes={displayedRoutes}
            mapContainerStyle={{
              flex: 3,
              height: "100%",
              boxShadow: "0px 16px 40px rgba(124, 134, 156, 0.24)",
            }}
            center={centerPoint}
            disableFitBounds={true}
            // @ts-ignore
            options={{ styles: GOOGLE_MAP_STYLE }}
            socketConnectionOptions={socketOptions}
            renderStationPopover={renderStationPopover}
            trackedRideIds={
              selectedRideId ? [selectedRideId] : ridesListData.rides.map((ride) => ride.id)
            }
            getBusIconOptions={getBusIconOptions}
            setSelectedStation={(id) => {
              // Toggle station open/close on click
              if (id !== selectedStationId) setSelectedStationId(id);
              else setSelectedStationId(undefined);
            }}
            setHoveredStation={setHoveredStationId}
          >
            {selectedRideData.status === RIDE_COMPLETED_STATUS &&
            !isEmpty(selectedRideData.analytics) ? (
              <CustomMapPanelControl map={map} containerStyles={{ margin: "24px 16px" }}>
                <RideMetricsCard
                  analytics={selectedRideData.analytics}
                  estimatedAnalytics={selectedRideData.estimatedAnalytics}
                />
              </CustomMapPanelControl>
            ) : null}
          </Map>
        </div>
      </div>
    </Page>
  );
};

export default Rides;
