import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { toCamelCase } from "utils";
import { showAlert } from "@swvl/alert";
import { SHIFTS_LIST_PAGE_SIZE } from "constants/shifts";
import { FilterMap } from "utils/useFilters";

export type ShiftModel = {
  id: string;
  businessProfiles: string[];
  corporate: string;
  endDate: string;
  startDate: string;
  isActive: boolean;
  officeLocation: {
    id: string;
    name: string;
  };
  time: number;
  weekdays: number[];
  direction: "to_office" | "from_office";
  completed: boolean;
  assignedEmployees: number;
  name: string;
};

export type ShiftsListModel = {
  count: number;
  shifts: ShiftModel[];
};

interface ShiftsList {
  _id: string;
  business_profiles: string[];
  corporate: string;
  end_date: string;
  start_date: string;
  is_active: boolean;
  office_location: {
    id: string;
    name: string;
  };
  time: number;
  weekdays: number[];
  direction: "to_office" | "from_office";
  completed: boolean;
  assigned_employees: number;
  name: string;
}

type ShiftsResponse = {
  type?: string;
  total: number;
  hits: ShiftsList[];
};

const transformResponse = (response: ShiftsResponse): ShiftsListModel => {
  const data = {
    count: response.total,
    shifts: response.hits.map((item) => toCamelCase(item) as ShiftModel),
  };
  return data;
};

const fetchShifts = async (params: {
  page: number;
  limit: number;
  sort: string;
  filters?: FilterMap;
}): Promise<ShiftsListModel> => {
  const response = await apiInstance.get<AxiosResponse, ShiftsResponse>("shifts", {
    params,
  });

  return transformResponse(response);
};

const useShifts = ({ page, filters }: { page: number; filters?: FilterMap }) => {
  const query = useQuery(
    ["shifts", page, filters],
    () => fetchShifts({ page, limit: SHIFTS_LIST_PAGE_SIZE, ...filters, sort: "-start_date" }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (response) => {
        showAlert({
          message: (response as AxiosResponse)?.data?.message,
          type: "error",
          id: "alert-error",
        });
      },
    }
  );
  return query;
};

export { useShifts, fetchShifts, transformResponse };
