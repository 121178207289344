/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useEffect } from "react";
import forEach from "lodash/fp/forEach";
import { useFormik, FormikProvider } from "formik";
import { string, object, InferType } from "yup";
import Spinner from "@swvl/spinner";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import swvlBusinessLogo from "assets/swvl-business-logo.svg";
import Field from "components/FormField";
import { useLogin } from "resources/useLogin";
import { Form, ErrorBanner } from "./styled";

export const loginSchema = object()
  .shape({
    email: string().required("Email is required").email("Please enter a valid email").defined(),
    password: string().required("Password is required"),
  })
  .defined();

type LoginSchema = InferType<typeof loginSchema>;

const LoginForm: FC = () => {
  const { mutate: loginUser, isError, status, validationErrorMessage } = useLogin();
  const isLoading = status === "loading";

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const email = values.email.trim();
      const password = values.password.trim();
      loginUser({ email, password });
    },
  });

  useEffect(() => {
    if (isError) {
      forEach<string>((key) => {
        formik.setFieldError(key, " ");
      })(Object.keys(formik.values));
    }
  }, [isError]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <img src={swvlBusinessLogo} />
        <div>
          <Text
            variant="title-large"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Sign Into Your Account
          </Text>
          <Text
            variant="body-small"
            sx={{
              color: "opacity.black-54",
            }}
          >
            Welcome! Please fill in email address and password to sign into your account
          </Text>
        </div>
        {isError ? (
          <ErrorBanner>
            <div>
              <Icon name="error" size={24} />
            </div>
            <Text
              variant="body-small"
              sx={{
                color: "crimson",
                marginLeft: "1rem",
                lineHeight: "15px",
              }}
            >
              {validationErrorMessage}
            </Text>
          </ErrorBanner>
        ) : null}
        <div id="fields">
          <Field
            label="Email Address"
            id="user-email"
            name="email"
            placeholder="Add Email Address"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            required
          />
          <Field
            label="Password"
            id="password"
            name="password"
            placeholder="Add Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            required
          />
          {/*
              TODO: Add when available
              <a
                sx={{
                  color: "secondary",
                  textDecoration: "none",
                  marginBottom: "1rem",
                }}
                href="/"
              >
                Forgot password?
              </a>
          */}
        </div>
        {isLoading ? (
          <Spinner color="primary" />
        ) : (
          <Button
            sx={{
              width: "110px",
              height: "38px",
            }}
            variant="primary"
            icon={<Icon name="right_arrow" size={15} fill="currentcolor" />}
            iconPosition="right"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
          >
            <span sx={{ fontWeight: "bold", fontSize: "15px" }}>Sign In</span>
          </Button>
        )}
        <a
          sx={{
            color: "#999A9A",
            textDecoration: "none",
            borderTop: "1px solid",
            mt: 3,
            borderColor: "#E3E4E5",
            pt: 3,
          }}
          target="_blank"
          rel="noreferrer"
          href="https://swvl.com/privacy-policy/"
        >
          Privacy Policy
        </a>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
